import React, { Component } from 'react'
import { FormControl } from "react-bootstrap";
import _ from 'lodash';


class ListTableRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditModeVisible: false,
            saved: {},
        }
    };

    openEditMode = (item) => {
        this.setState({
            isEditModeVisible: true,
            saved: _.clone(item),
        });
    };

    closeEditMode = () => {
        this.setState({
            isEditModeVisible: false,
            saved: {},
        });
    };

    handleChange = (keyName, keyValue) => {
        let item = this.state.saved;
        item[keyName] = keyValue;
        this.setState({
            saved: item,
        });
    };

    saveData = () => {
        let item = this.state.saved;
        console.log("edit___________", item);
        this.props.updateItem(item._id, item);
        this.closeEditMode();
    };

    deleteHandler = (item) => {
        this.props.deleteItem(item._id);
    }

    render() {
        if (this.state.isEditModeVisible) {
            return (
                <tr role="row" className="odd">
                    <td>{this.props.index + 1 + (this.props.page - 1) * this.props.size}</td>
                    <td>
                        <FormControl
                            onChange={(e) => this.handleChange('name', e.target.value)}
                            value={this.state.saved ? this.state.saved.name : ''}
                        />
                    </td>
                    <td>
                        <FormControl
                            as="select"
                            onChange={(e) => this.handleChange('apartmentId', e.target.value)}>

                            {this.props.apartments.map((apartment) => (
                                this.state.saved.apartmentId === apartment._id ?
                                    <option selected="true" value={apartment._id}>{apartment.name}</option> :
                                    <option value={apartment._id}>{apartment.name}</option>
                            ))}
                        </FormControl>
                    </td>
                    <td>
                        <button
                            disabled={this.props.item.isUpdating}
                            onClick={() => this.saveData()}
                            className="btn btn-light btn-rounded">
                            {this.props.item.isUpdating ?
                                "Saving" : "Save"}
                        </button>
                        <button
                            disabled={this.props.item.isUpdating}
                            onClick={() => this.closeEditMode()}
                            className="btn btn-light btn-rounded ml-3">
                            {'  '}
                            Close
                            {'  '}
                        </button>
                    </td>
                </tr>
            );
        }
        else {
            return (
                <tr role="row" className="odd">
                    <td>{this.props.index + 1 + (this.props.page - 1) * this.props.size}</td>
                    <td>{this.props.item.name}</td>
                    <td>{this.props.item.apartment_name}</td>
                    <td>
                        <button
                            onClick={() => this.openEditMode(this.props.item)}
                            className="btn btn-light btn-rounded"
                        >
                            Edit
                        </button>
                        <button
                            disabled={this.props.item.isDeletingItem}
                            onClick={() => this.deleteHandler(this.props.item)}
                            className="btn btn-danger btn-rounded ml-3"
                        >
                            Delete
                        </button>
                    </td>
                </tr>
            );
        }
    }
}

export default ListTableRow;
