import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
class ModalTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEditable: false,
			item: {},
		};
	}

	componentDidMount() {
		if (this.props.item) {
			this.setState({
				item: this.props.item,
			});
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!_.isEqual(prevProps.item, this.props.item)) {
			this.setState({
				item: this.props.item,
			});
		}
	}

	toggleEdit = () => {
		this.setState({
			isEditable: !this.state.isEditable,
		});
	};

	handleChange = (keyName, keyValue) => {
		let item = this.state.item;
		item[keyName] = keyValue;
		this.setState({
			item: item,
		});
	};

	saveDetails = () => {
		let item = this.state.item;
		this.props.updateBikeDetails(this.props.item._id, item);
		this.toggleEdit();
	};

	render() {
		if (this.state.isEditable) {
			return (
				<Card>
					<Card.Header>
						<Row>
							<Col xs={6}> {this.props.heading}</Col>
							<Col xs={6}>
								<div className={'text-right'}>
									<Button disabled={this.state.isUpdating} onClick={() => this.saveDetails()}>
										{this.state.isUpdating ? 'saving' : 'save'}
									</Button>
									&nbsp;
									&nbsp;
									<Button onClick={() => this.toggleEdit()}>close</Button>
								</div>
							</Col>
						</Row>
					</Card.Header>
					<ListGroup className="list-group-flush">
						<ListGroupItem>
							<Row>
								<Col xs={5}>Block Name</Col>
								<Col xs={7}>
									<FormControl
										// onChange={(e) => this.handleChange('block_name', e.target.value)}
										value={this.state.item.block_name}
									/>
								</Col>
							</Row>
						</ListGroupItem>
						{/* <ListGroupItem>
							<Row>
								<Col xs={5}>Block Id</Col>
								<Col xs={7}>
									<FormControl
										onChange={(e) => this.handleChange('block_id', e.target.value)}
										value={this.state.item.block_id}
									/>
								</Col>
							</Row>
						</ListGroupItem> */}
						<ListGroupItem>
							<Row>
								<Col xs={5}>Parking Block</Col>
								<Col xs={7}>
									<FormControl
										onChange={(e) => this.handleChange('parking_block', e.target.value)}
										value={this.state.item.parking_block}
									/>
								</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Time Slot</Col>
								<Col xs={7}>
									<FormControl
										onChange={(e) => this.handleChange('time_slot_name', e.target.value)}
										value={this.state.item.time_slot_name}
									/>
								</Col>
							</Row>
						</ListGroupItem>
					</ListGroup>
				</Card>
			);
		} else {
			return (
				<Card>
					<Card.Header>
						<Row>
							<Col xs={6}>{this.props.heading}</Col>
							{/* <Col xs={6}>
								<div className="text-right">
									<Button onClick={() => this.toggleEdit()}>edit</Button>
								</div>
							</Col> */}
						</Row>
					</Card.Header>
					<ListGroup className="list-group-flush">
						<ListGroupItem>
							<Row>
								<Col xs={5}>Vehicle type</Col>
								<Col xs={7}>bike</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Vehicle model</Col>
								<Col xs={7}>{this.state.item && this.state.item.model_id && this.state.item.model_id.name ? this.state.item.model_id.name : ''}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Vehicle Reg. NO</Col>
								<Col xs={7}>{this.state.item && this.state.item.license_number ? this.state.item.license_number : ''}</Col>
							</Row>
						</ListGroupItem>
					</ListGroup>
				</Card>
			);
		}
	}
}

export default ModalTable;
