import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSidebarEnabled: true,
		};
	}

	toggleSidebar = () => {
		this.setState({
			isSidebarEnabled: !this.state.isSidebarEnabled,
		});
		if (this.state.isSidebarEnabled) {
			document.body.className = 'vertical-collpsed';
		} else {
			document.body.className = 'sidebar-enable';
		}
	};

	render() {
		return (
			<header id="page-topbar">
				<div className="navbar-header">
					<div className="d-flex">
						{/* LOGO */}
						<div className="navbar-brand-box">
							<Link to="/" className="logo logo-dark">
								<span className="logo-sm">{this.state.isSidebarEnabled ? 'Honc Admin' : ''}</span>
								<span className="logo-lg">{this.state.isSidebarEnabled ? 'Honc Admin' : ''}</span>
							</Link>
							<Link to="/" className="logo logo-light">
								<span className="logo-sm">{this.state.isSidebarEnabled ? 'Honc Admin' : ''}</span>
								<span className="logo-lg">{this.state.isSidebarEnabled ? 'Honc Admin' : ''}</span>
							</Link>
						</div>
						{this.props.isAuthenticated?
					<button
					onClick={() => this.toggleSidebar()}
					type="button"
					className="btn btn-sm px-3 font-size-24 header-item waves-effect"
					id="vertical-menu-btn"
				>
					<i className="ri-menu-2-line align-middle" />
				</button>
				:
				null	
					}
					</div>
					<div className="d-flex">
						{/*    <div className="dropdown d-inline-block">*/}
						{/*        <button type="button" className="btn header-item noti-icon waves-effect"*/}
						{/*                id="page-header-notifications-dropdown" data-toggle="dropdown" aria-haspopup="true"*/}
						{/*                aria-expanded="false">*/}
						{/*            <i className="ri-notification-3-line"/>*/}
						{/*            <span className="noti-dot"/>*/}
						{/*        </button>*/}
						{/*        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"*/}
						{/*             aria-labelledby="page-header-notifications-dropdown">*/}
						{/*            <div className="p-3">*/}
						{/*                <div className="row align-items-center">*/}
						{/*                    <div className="col">*/}
						{/*                        <h6 className="m-0"> Notifications </h6>*/}
						{/*                    </div>*/}
						{/*                    <div className="col-auto">*/}
						{/*                        <a href="#!" className="small"> View All</a>*/}
						{/*                    </div>*/}
						{/*                </div>*/}
						{/*            </div>*/}
						{/*            <div data-simplebar style={{maxHeight: '230px'}}>*/}
						{/*                <a href className="text-reset notification-item">*/}
						{/*                    <div className="media">*/}
						{/*                        <div className="avatar-xs mr-3">*/}
						{/*<span className="avatar-title bg-primary rounded-circle font-size-16">*/}
						{/*  <i className="ri-shopping-cart-line"/>*/}
						{/*</span>*/}
						{/*                        </div>*/}
						{/*                        <div className="media-body">*/}
						{/*                            <h6 className="mt-0 mb-1">Your order is placed</h6>*/}
						{/*                            <div className="font-size-12 text-muted">*/}
						{/*                                <p className="mb-1">If several languages coalesce the grammar</p>*/}
						{/*                                <p className="mb-0"><i className="mdi mdi-clock-outline"/> 3 min ago</p>*/}
						{/*                            </div>*/}
						{/*                        </div>*/}
						{/*                    </div>*/}
						{/*                </a>*/}
						{/*                <a href className="text-reset notification-item">*/}
						{/*                    <div className="media">*/}
						{/*                        <img src="assets/images/users/avatar-3.jpg"*/}
						{/*                             className="mr-3 rounded-circle avatar-xs" alt="user-pic"/>*/}
						{/*                        <div className="media-body">*/}
						{/*                            <h6 className="mt-0 mb-1">James Lemire</h6>*/}
						{/*                            <div className="font-size-12 text-muted">*/}
						{/*                                <p className="mb-1">It will seem like simplified English.</p>*/}
						{/*                                <p className="mb-0"><i className="mdi mdi-clock-outline"/> 1 hours ago*/}
						{/*                                </p>*/}
						{/*                            </div>*/}
						{/*                        </div>*/}
						{/*                    </div>*/}
						{/*                </a>*/}
						{/*                <a href className="text-reset notification-item">*/}
						{/*                    <div className="media">*/}
						{/*                        <div className="avatar-xs mr-3">*/}
						{/*<span className="avatar-title bg-success rounded-circle font-size-16">*/}
						{/*  <i className="ri-checkbox-circle-line"/>*/}
						{/*</span>*/}
						{/*                        </div>*/}
						{/*                        <div className="media-body">*/}
						{/*                            <h6 className="mt-0 mb-1">Your item is shipped</h6>*/}
						{/*                            <div className="font-size-12 text-muted">*/}
						{/*                                <p className="mb-1">If several languages coalesce the grammar</p>*/}
						{/*                                <p className="mb-0"><i className="mdi mdi-clock-outline"/> 3 min ago</p>*/}
						{/*                            </div>*/}
						{/*                        </div>*/}
						{/*                    </div>*/}
						{/*                </a>*/}
						{/*                <a href className="text-reset notification-item">*/}
						{/*                    <div className="media">*/}
						{/*                        <img src="assets/images/users/avatar-4.jpg"*/}
						{/*                             className="mr-3 rounded-circle avatar-xs" alt="user-pic"/>*/}
						{/*                        <div className="media-body">*/}
						{/*                            <h6 className="mt-0 mb-1">Salena Layfield</h6>*/}
						{/*                            <div className="font-size-12 text-muted">*/}
						{/*                                <p className="mb-1">As a skeptical Cambridge friend of mine*/}
						{/*                                    occidental.</p>*/}
						{/*                                <p className="mb-0"><i className="mdi mdi-clock-outline"/> 1 hours ago*/}
						{/*                                </p>*/}
						{/*                            </div>*/}
						{/*                        </div>*/}
						{/*                    </div>*/}
						{/*                </a>*/}
						{/*            </div>*/}
						{/*            <div className="p-2 border-top">*/}
						{/*                <a className="btn btn-sm btn-link font-size-14 btn-block text-center"*/}
						{/*                   href="javascript:void(0)">*/}
						{/*                    <i className="mdi mdi-arrow-right-circle mr-1"/> View More..*/}
						{/*                </a>*/}
						{/*            </div>*/}
						{/*        </div>*/}
						{/*    </div>*/}
						<div className="dropdown d-inline-block user-dropdown">
							<button
								type="button"
								className="btn header-item waves-effect"
								id="page-header-user-dropdown"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								{/*<img*/}
								{/*	className="rounded-circle header-profile-user"*/}
								{/*	src="assets/images/users/avatar-2.jpg"*/}
								{/*	alt="Header Avatar"*/}
								{/*/>*/}
								<span className="d-none d-xl-inline-block ml-1">{this.props.user && this.props.user.name ? this.props.user.name : ''}</span>
								<i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
							</button>
							<div className="dropdown-menu dropdown-menu-right">
								<a
									onClick={() => this.props.logoutUser()}
									className="dropdown-item text-danger">
									<i className="ri-shut-down-line align-middle mr-1 text-danger" /> Logout
								</a>
							</div>
						</div>
					</div>
				</div>
			</header>
		);
	}
}

export default Header;
