import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Modal, Row } from 'react-bootstrap';
import moment, { months } from 'moment';
import callApi from '../../../../util/apiCaller';
import DateRange from '../../../../components/DateRangePicker';
import Loading from '../../../../components/Loading';
import ListTableRow from './ListTableRow';
import ListTablePagination from '../../../../components/ListTablePagination';

class ListBills extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().startOf('month'),
            endDate: moment().endOf('month'),
            pageNum: 1,
            pageSize: 25,
            transactions: [],
            transactionsCount: 0,
            addBalanceModal: false,
            pocket: 0,
            deductBalanceModal: false,
            isDeductingBalanceToPocket: false,
        };
    }

    componentDidMount() {
        this.fetchPocketHistory();
        this.getPocketbalance();
    }

    fetchPocketHistory = () => {
        this.setState({
            isFetchingTransactions: true,
        });
        callApi(
            `v2/pocket_transactions/customer/${this?.props?.userId}/get-transactions`,
            'post',
            {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                pageNum: this.state.pageNum,
                pageSize: this.state.pageSize,
            },
        )
            .then((res) => {
                if (res.status === 'Success') {
                    this.setState(
                        {
                            transactions: res.data?.transactions,
                            transactionsCount: res.data?.transactionsCount
                        }
                    );
                }
                this.setState({
                    isFetchingTransactions: false,
                });
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    isFetchingTransactions: false,
                });
            });
    };

    getPocketbalance = () => {
        this.setState({
            isFetchingPocketbalance: true,
        });
        callApi(
            `v2/pocket/customerId/${this?.props?.userId}/get-balance`,
            'get',
        )
            .then((res) => {
                if (res.status == 'Success') {
                    this.setState({
                        pocket: res.data.pocket,
                    });
                }

                this.setState({
                    isFetchingPocketbalance: false,
                });
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    isFetchingPocketbalance: false,
                });
            });
    };

    handleDateRange = (item) => {
        this.setState({
            startDate: moment(item[0]),
            endDate: moment(item[1])
        }, () => {
            this.fetchPocketHistory();
        })
    }

    changePageSize = (size) => {
        this.setState(
            {
                pageSize: size,
                pageNum: 1,
            },
            () => {
                this.fetchPocketHistory();
            }
        );
    };

    changePageNum = (num) => {
        this.setState(
            {
                pageNum: num,
            },
            () => {
                this.fetchPocketHistory();
            }
        );
    };

    openAddBalanceModal = () => {
        this.setState({
            addBalanceModal: true
        })
    }

    closeAddBalanceModal = () => {
        this.setState({
            addBalanceModal: false
        })
    }
    openDeductBalanceModal = () => {
        this.setState({
            deductBalanceModal: true
        })
    }

    closeDeductBalanceModal = () => {
        this.setState({
            deductBalanceModal: false
        })
    }


    validateBeforeSubmit = (input, type) => {
        let validation = {
            isValid: true,
            message: ""
        }

        if (!input.tittle || input.tittle == "") {
            validation.isValid = false;
            validation.message = "Please enter title to proceed";
            return validation;
        }
        if (type == "add") {
            if (!input.amount || input.amount == "") {
                validation.isValid = false;
                validation.message = "Please enter amount to proceed";
                return validation;
            }
        }
        else if (type == "deduct") {
            if (!input.balance || input.balance == "") {
                validation.isValid = false;
                validation.message = "Please enter amount to proceed";
                return validation;
            }
        }
        return validation;
    }

    addBalanceToPocket = () => {
        let data = {
            "amount": parseInt(this.state.amountToBeAdded),
            "tittle": this.state.title
        }
        let validation = this.validateBeforeSubmit(data, 'add');
        if (validation.isValid) {
            this.setState({
                isAddingBalanceToPocket: true,
            });
            callApi(
                `admin/customer/${this?.props?.userId}/add-balance`,
                'post', data,
            )
                .then((res) => {
                    if (res.status === 'Success') {
                        this.closeAddBalanceModal();
                        this.getPocketbalance();
                        this.fetchPocketHistory();
                        this.setState({
                            amountToBeAdded: ''
                        })
                        alert("amount added successfully.")
                    }
                    this.setState({
                        isAddingBalanceToPocket: false,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        isAddingBalanceToPocket: false,
                    });
                });
        }
        else {
            alert(validation.message);
        }

    }

    DeductBalanceFromPocket = () => {
        let data = {
            "balance": -(parseInt(this.state.amountToBeAdded)),
            "tittle": this.state.title,
            "customerId": this?.props?.userId
        }
        let validation = this.validateBeforeSubmit(data, 'deduct');
        if (validation.isValid) {
            this.setState({
                isDeductingBalanceToPocket: true,
            });
            callApi(
                `test_v2/load-negative-balance`, 'post', data,
            )
                .then((res) => {
                    if (res.status === 'Success') {
                        this.closeDeductBalanceModal();
                        this.getPocketbalance();
                        this.fetchPocketHistory();
                        this.setState({
                            amountToBeAdded: ''
                        })
                        alert("amount deducted successfully.")
                    }
                    this.setState({
                        isDeductingBalanceToPocket: false,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        isDeductingBalanceToPocket: false,
                    });
                });
        }
        else {
            alert(validation.message);
        }

    }

    render() {
        return (
            <div>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col xs={6}>{this.props.heading}</Col>
                            <Col xs={6}>
                                <div className={'text-right'}>
                                    <h4><b>Pocket Balance:</b>{this.state?.pocket?.balance}</h4>
                                    <Button onClick={() => this.openAddBalanceModal()}>+ Add Balance</Button>
                                    <Button className='ml-3' onClick={() => this.openDeductBalanceModal()}>- Deduct Balance</Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <label>Date Filter</label>
                        <DateRange
                            dateRangeValue={this.handleDateRange}
                        />
                        <div style={{
                            marginTop: '2rem',
                            textAlign: 'center'
                        }}>
                            <h5>Pocket Transactions From {this.state.startDate.format("DD-MM-YYYY")} to {this.state.endDate.format("DD-MM-YYYY")}</h5>
                        </div>

                        <div>
                            <div className="table-responsive">
                                <div id="DataTables_Table_0_wrapper"
                                    className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6">
                                            <div className="dataTables_length" id="DataTables_Table_0_length">
                                                <label>Show &nbsp;
                                                    <select
                                                        value={this.props.pageSize}
                                                        onChange={(e) => this.props.changePageSize(e.target.value)}
                                                        name="DataTables_Table_0_length" aria-controls="DataTables_Table_0"
                                                        className="custom-select custom-select-sm form-control form-control-sm">
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select> &nbsp;entries
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <table
                                                className="table table-centered datatable dt-responsive nowrap dataTable no-footer dtr-inline collapsed"
                                                style={{
                                                    borderCollapse: 'collapse',
                                                    borderSpacing: '0px',
                                                    width: '100%'
                                                }} id="DataTables_Table_0" role="grid"
                                                aria-describedby="DataTables_Table_0_info">
                                                <thead className="thead-light">
                                                    <tr role="row">
                                                        <th style={{ width: '28px' }} className="sorting_disabled" rowSpan={1}
                                                            colSpan={1} aria-label="&nbsp;">
                                                            S.No
                                                        </th>
                                                        <th className="sorting_disabled">
                                                            Amount
                                                        </th>
                                                        <th className="sorting_disabled">
                                                            Type
                                                        </th>
                                                        <th className="sorting_disabled">
                                                            Previous Balance
                                                        </th>
                                                        <th className="sorting_disabled">
                                                            Current Balance
                                                        </th>
                                                        <th className="sorting_disabled">
                                                            Title
                                                        </th>
                                                        <th className="sorting_disabled">
                                                            Transaction date
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {
                                                    this.state.isFetchingTransactions ? <Loading /> : <tbody>
                                                        {this.state.transactions && this.state.transactions.map((item, index) => {
                                                            return (
                                                                <tr role="row" className="odd">
                                                                    <td>
                                                                        {index + 1 + (this.state.pageNum - 1) * this.state.pageSize}
                                                                    </td>
                                                                    <td className="sorting_1"><a href="javascript: void(0);" className="text-dark font-weight-bold">{item.amount}</a> </td>
                                                                    <td className="sorting_1"><a href="javascript: void(0);" className="text-dark font-weight-bold">{item.type}</a> </td>
                                                                    <td className="sorting_1"><a href="javascript: void(0);" className="text-dark font-weight-bold">{item.previous_balance}</a> </td>
                                                                    <td className="sorting_1"><a href="javascript: void(0);" className="text-dark font-weight-bold">{item.new_balance}</a> </td>
                                                                    <td className="sorting_1"><a href="javascript: void(0);" className="text-dark font-weight-bold">{item.tittle}</a> </td>
                                                                    <td className="sorting_1"><a href="javascript: void(0);" className="text-dark font-weight-bold">{moment(item.transaction_date).format('DD MMM, YYYY')}</a> </td>

                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                }
                                            </table>
                                        </div>
                                    </div>
                                    <ListTablePagination
                                        changePageNum={this.changePageNum}
                                        page={this.state.pageNum}
                                        size={this.state.pageSize}
                                        count={this.state.transactionsCount}
                                    />
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                {/* Add Balance Modal*/}
                <Modal
                    dialogClassName={'modal-dialog modal-dialog-scrollable modal-xs'}
                    show={this.state.addBalanceModal}
                    onHide={() => this.closeAddBalanceModal()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add balance to pocket</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>
                            Title <span style={{ fontSize: '10px' }}>(This will be visible to user in my bills)</span>
                        </label>
                        <input type='text' className='form-control' value={this.state.title} onChange={(e) => {
                            this.setState({
                                title: e.target.value
                            })
                        }} />
                        <label>
                            Amount
                        </label>
                        <input type='text' className='form-control' value={this.state.amountToBeAdded} onChange={(e) => {
                            this.setState({
                                amountToBeAdded: e.target.value
                            })
                        }} />
                        <button className='btn btn-primary' type='submit'
                            onClick={() => {
                                this.addBalanceToPocket()
                            }}
                            disabled={this.state.isAddingBalanceToPocket}
                            style={{
                                marginTop: '1rem',
                            }}>{this.state.isAddingBalanceToPocket ? 'Adding...' : '+ Add'}</button>
                    </Modal.Body>
                </Modal>

                {/* Deduct Balance Modal*/}
                <Modal
                    dialogClassName={'modal-dialog modal-dialog-scrollable modal-xs'}
                    show={this.state.deductBalanceModal}
                    onHide={() => this.closeDeductBalanceModal()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Deduct balance to pocket</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>
                            Title <span style={{ fontSize: '10px' }}>(This will be visible to user in my bills)</span>
                        </label>
                        <input type='text' className='form-control' value={this.state.title} onChange={(e) => {
                            this.setState({
                                title: e.target.value
                            })
                        }} />
                        <label>
                            Amount
                        </label>
                        <input type='text' className='form-control' value={this.state.amountToBeAdded} onChange={(e) => {
                            this.setState({
                                amountToBeAdded: e.target.value
                            })
                        }} />
                        <button className='btn btn-primary' type='submit'
                            onClick={() => {
                                this.DeductBalanceFromPocket()
                            }}
                            disabled={this.state.isDeductingBalanceToPocket}
                            style={{
                                marginTop: '1rem',
                            }}>{this.state.isDeductingBalanceToPocket ? 'Deducting...' : '- Deduct'}</button>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ListBills;
