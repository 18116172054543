import React, { Component } from 'react';
import callApi from '../../../util/apiCaller';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import moment from "moment";


class DashboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 'all',
            query: '',
            pageNum: 1,
            pageSize: 25,
            totalCount: 0,
            items: [],
            carbrands: [],
            carbrandsTotal: 0,
            bikebrandsTotal: 0,
            totalCustomerBookingsCount: 0,
            totalVendorsCount: 0,
            totalLocationsCount: 0,
            totalSupervisorsCount: 0,
            totalCleanersCount: 0,
            totalCommunitiesCount: 0,
            totalBlocksCount: 0,
            totalPackagesCount: 0,
            totalAdOnCount: 0,
            isAddBikeModalVisible: false,
            supervisors: [],
            selectedItemId: '',
            isDetailsModalVisible: false,
        };

    }

    componentDidMount() {

        this.fetchCarbrands();
        this.fetchBikeBrands();
        this.fetchCustomerBookings();
        this.fetchVendors();
        this.fetchLocations();
        this.fetchPackages();
        this.fetchSupervisorUsers();
        this.fetchCleanerUsers();
        this.fetchApartments();
        this.fetchBlocks();
    }

    fetchCarbrands = () => {
        callApi('carbrands/list', 'post', {
            pageNum: 1,
            pageSize: 10000,
        }).then((res) => {
            this.setState({
                carbrandsTotal: res.data.carbrands.length
            });
        });
    };
    fetchBikeBrands = () => {
        console.log('Bike Brands');
        callApi('bikebrands/list', 'post', {
            pageNum: 1,
            pageSize: 10000,
        }).then((res) => {
            console.log('BikeBrands Api', res);
            this.setState({
                bikebrandsTotal: res.data.bikebrands.length
            });
        });
    };

    fetchCustomerBookings = () => {

        callApi(`admin/customer/list`, 'post', {
            pageNum: 1,
            pageSize: 10000,
        }

        ).then((res) => {
            console.log(res);
            if (res.status === 'Success') {
                this.setState({
                    totalCustomerBookingsCount: res.data.count,
                });
            }
        });
    };

    fetchVendors = () => {

        callApi(`agencys/list`, 'post', {
            pageNum: 1,
            pageSize: 10000,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    items: res.data["agencys"],
                    totalVendorsCount: res.data.count
                }, () => {
                    console.log("fetched_apartments", this.state.items);
                })
            }
            else {
                console.log("error")
            }
        })
    };

    fetchLocations = () => {
        callApi(`locations/list`, 'post', {
            pageNum: 1,
            pageSize: 10000,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    totalLocationsCount: res.data["locations"].length,
                })
            }
            else {
                console.log("error")
            }
        })
    };

    fetchPackages = () => {

        console.log(this.state.query);
        callApi(`servicepackages/list`, 'post', {
            pageNum: 1,
            pageSize: 10000,

        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    items: res.data["servicepackages"],
                    totalPackagesCount: res.data.count
                }, () => {
                    console.log("fetched_packages", this.state.items);
                })
            }
            else {
                console.log("error")
            }
        })
    };

    fetchCleanerUsers = () => {
        console.log('Test');

        callApi('users/cleanerlist', 'post', {
            pageNum: 1,
            pageSize: 10000,
            qtext: true,

        }).then((res) => {
            console.log('fetchUsers Api', res);
            this.setState({
                items: res.data['users'],
                totalCleanersCount: res.data['users'].length,
            });
        });
    };

    fetchSupervisorUsers = () => {
        console.log('Test');

        callApi('users/supervisorlist', 'post', {
            pageNum: 1,
            pageSize: 10000,
            qtext: true,

        }).then((res) => {
            console.log('fetchUsers Api', res);
            this.setState({
                items: res.data['users'],
                totalSupervisorsCount: res.data['users'].length,
            });
        });
    };

    fetchBlocks = () => {
        callApi('blocks/list', 'post', {
            pageNum: 0,
            pageSize: 10000,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({

                    totalBlocksCount: res.data["blocks"].length
                }, () => {
                    console.log("fetched_blocks", this.state.items);
                })
            }
            else {
                console.log("error")
            }
        })
    };

    fetchApartments = () => {
        callApi('apartments/list', 'post', {
            pageNum: 1,
            pageSize: 10000,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({

                    totalCommunitiesCount: res.data["apartments"].length,
                })
            }
            else {
                console.log("error")
            }
        })
    };

    render() {
        return (

            <div class="page-content">
                <div class="container-fluid">

                    <div class="row">
                        <div class="col-xl-12">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="media">
                                                <div class="media-body overflow-auto">
                                                    <p class="text-truncate font-size-14 mb-2">Available Vendors</p>
                                                    <h4 class="mb-0"><Link to="/vendors">{this.state.totalVendorsCount}</Link>
                                                    </h4>
                                                </div>
                                                <div class="text-primary">
                                                    <i class="ri-stack-line font-size-24"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="media">
                                                <div class="media-body overflow-hidden">
                                                    <p class="text-truncate font-size-14 mb-2">Available Supervisors</p>
                                                    <h4 class="mb-0"><Link to="/vendors/supervisors">{this.state.totalSupervisorsCount}</Link></h4>
                                                </div>
                                                <div class="text-primary">
                                                    <i class="ri-stack-line font-size-24"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="media">
                                                <div class="media-body overflow-hidden">
                                                    <p class="text-truncate font-size-14 mb-2">Available Cleaners</p>
                                                    <h4 class="mb-0"><Link to="/vendors/cleaners">{this.state.totalCleanersCount}</Link></h4>
                                                </div>
                                                <div class="text-primary">
                                                    <i class="ri-stack-line font-size-24"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="media">
                                                <div class="media-body overflow-hidden">
                                                    <p class="text-truncate font-size-14 mb-2">Number of Localities</p>
                                                    <h4 class="mb-0"><Link to="/places/locations">{this.state.totalLocationsCount}</Link></h4>
                                                </div>
                                                <div class="text-primary">
                                                    <i class="ri-stack-line font-size-24"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-12">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="media">
                                                <div class="media-body overflow-hidden">
                                                    <p class="text-truncate font-size-14 mb-2">Number of Communities</p>
                                                    <h4 class="mb-0"><Link to="/places/communities">{this.state.totalCommunitiesCount}</Link></h4>
                                                </div>
                                                <div class="text-primary">
                                                    <i class="ri-stack-line font-size-24"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="media">
                                                <div class="media-body overflow-hidden">
                                                    <p class="text-truncate font-size-14 mb-2">Number of Blocks</p>
                                                    <h4 class="mb-0"><Link to="/places/blocks">{this.state.totalBlocksCount}</Link></h4>
                                                </div>
                                                <div class="text-primary">
                                                    <i class="ri-stack-line font-size-24"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="media">
                                                <div class="media-body overflow-hidden">
                                                    <p class="text-truncate font-size-14 mb-2">Available Service Packages</p>
                                                    <h4 class="mb-0"><Link to="/servicepackages">{this.state.totalPackagesCount}</Link></h4>
                                                </div>
                                                <div class="text-primary">
                                                    <i class="ri-stack-line font-size-24"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="media">
                                                <div class="media-body overflow-hidden">
                                                    <p class="text-truncate font-size-14 mb-2">Available Car Brands</p>
                                                    <h4 class="mb-0"><Link to="/vehicles/cars">{this.state.carbrandsTotal}</Link></h4>
                                                </div>
                                                <div class="text-primary">
                                                    <i class="ri-stack-line font-size-24"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="row">

                                <div class="col-md-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="media">
                                                <div class="media-body overflow-hidden">
                                                    <p class="text-truncate font-size-14 mb-2">Available Bike Brands</p>
                                                    <h4 class="mb-0"><Link to="/vehicles/bikes">{this.state.bikebrandsTotal}</Link></h4>
                                                </div>
                                                <div class="text-primary">
                                                    <i class="ri-stack-line font-size-24"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="media">
                                                <div class="media-body overflow-hidden">
                                                    <p class="text-truncate font-size-14 mb-2">Successful Bookings done so far</p>
                                                    <h4 class="mb-0"><Link to="/customer/bookings">{this.state.totalCustomerBookingsCount}</Link></h4>
                                                </div>
                                                <div class="text-primary">
                                                    <i class="ri-stack-line font-size-24"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>





        );
    }
}

export default DashboardPage;
