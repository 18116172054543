import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import callApi from '../../../../util/apiCaller';

class CommunityDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditable: false,
            item: {},
            isLoading: true,
            isUpdatingCommunity: false,
            updateObject: {}
        };
    }

    componentDidMount() {
        console.log("selected Id", this.props.apartmentId);
        if (this.props.apartmentId) {
            this.fetchItem(this.props.apartmentId);
        }
    }

    fetchItem = (id) => {
        this.setState(
            {
                isLoading: true,
            },
            () => {
                callApi(`admin/community/${id}/details`, 'get').then((res) => {
                    console.log(res);
                    if (res.status === 'Success') {
                        console.log("response", res.data);
                        this.setState({
                            isLoading: false,
                            community: res.data
                        });
                    }
                });
            }
        );
    };

    toggleEdit = () => {
        this.setState({
            isEditable: !this.state.isEditable,
        });
    };

    handleChange = (keyName, keyValue) => {
        let item = this.state.community;
        item.apartment[keyName] = keyValue;
        this.setState({
            updateObject: item,
        });
    };

    saveDetails = () => {
        let item = this.state.community.apartment;
        this.updateCommunity(this.state.community.apartmentId, item);
    };

    updateCommunity = (id, community) => {
        this.setState({
            isUpdatingCommunity: true,
        });
        callApi(`admin/community/${id}/update`, 'post', {
            community,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    isUpdatingCommunity: false,
                });
                this.toggleEdit();
                this.props.fetchApartments();
            } else {
                this.setState({
                    isUpdatingCommunity: false,
                });
                this.toggleEdit();
            }
        });
    };

    render() {
        if (this.state.isEditable) {
            return (
                <Card>
                    <Card.Header>
                        <Row>
                            <Col xs={6}> {this.props.heading}</Col>
                            <Col xs={6}>
                                <div className={'text-right'}>
                                    <Button disabled={this.state.isUpdatingCommunity} onClick={() => this.saveDetails()}>
                                        {this.state.isUpdatingCommunity ? 'saving' : 'save'}
                                    </Button>
        							&nbsp;
        							&nbsp;
        							<Button onClick={() => this.toggleEdit()}>close</Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Community Name</Col>
                                <Col xs={7}>
                                    <FormControl
                                        onChange={(e) => this.handleChange('name', e.target.value)}
                                        value={this.state.community.apartment.name}
                                    />
                                </Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Locality</Col>
                                <Col xs={7}>{this.state.community.location.location_name}</Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Basements</Col>
                                <Col xs={7}>{this.state.community.basements.toString()}</Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Location</Col>
                                <Col xs={7}>{this.state.community.location.city_name}</Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Status</Col>
                                <Col xs={7}>
                                    <FormControl
                                        as="select"
                                        onChange={(e) => this.handleChange("isActive", e.target.value)}
                                    >
                                        {this.state.community.apartment.isActive ? <option selected="true" value="true">Active</option>
                                            : <option value="true">Active</option>
                                        }
                                        {this.state.community.apartment.isActive ? <option value="false">Inactive</option>
                                            : <option selected="true" value="false">Inactive</option>
                                        }
                                    </FormControl>
                                </Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Address</Col>
                                <Col xs={7}>
                                    <FormControl
                                        onChange={(e) => this.handleChange('address', e.target.value)}
                                        value={this.state.community.apartment.address}
                                    />
                                </Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>No of Blocks</Col>
                                <Col xs={7}>{this.state.community.block_count}</Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>No of Flats</Col>
                                <Col xs={7}>
                                    <FormControl
                                        onChange={(e) => this.handleChange('flat_count', e.target.value)}
                                        value={this.state.community.apartment.flat_count}
                                    />
                                </Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>No of Cars</Col>
                                <Col xs={7}>{this.state.community.cars_count}</Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>No of Bikes</Col>
                                <Col xs={7}>{this.state.community.bikes_count}</Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>No of Parking Slots</Col>
                                <Col xs={7}>
                                    <FormControl
                                        onChange={(e) => this.handleChange('parking_slot_count', e.target.value)}
                                        value={this.state.community.apartment.parking_slot_count}
                                    />
                                </Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>No of Wash Areas</Col>
                                <Col xs={7}>
                                    <FormControl
                                        onChange={(e) => this.handleChange('wash_areas_count', e.target.value)}
                                        value={this.state.community.apartment.wash_areas_count}
                                    />
                                </Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Blocks</Col>
                                <Col xs={7}>{(this.state.community.blocks.map(item => item.name)).toString()}</Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            );
        } else {
            if (!this.state.isLoading) {
                return (
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={6}>{this.props.heading}</Col>
                                <Col xs={6}>
                                    <div className="text-right">
                                        <Button onClick={() => this.toggleEdit()}>edit</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        <ListGroup className="list-group-flush">
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>Community Name</Col>
                                    <Col xs={7}>{this.state.community.apartment.name}</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>Locality</Col>
                                    <Col xs={7}>{this.state.community.location.location_name}</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                            <Row>
                                <Col xs={5}>Basements</Col>
                                <Col xs={7}>{this.state.community.basements.toString()}</Col>
                            </Row>
                        </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>Location</Col>
                                    <Col xs={7}>{this.state.community.location.city_name}</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>Status</Col>
                                    <Col xs={7}>{this.state.community.apartment.isActive ? "Active" : "In Active"}</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>Address</Col>
                                    <Col xs={7}>{this.state.community.apartment.address}</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>No of Blocks</Col>
                                    <Col xs={7}>{this.state.community.block_count}</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>No of Flats</Col>
                                    <Col xs={7}>{this.state.community.apartment.flat_count}</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>No of Cars</Col>
                                    <Col xs={7}>{this.state.community.cars_count}</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>No of Bikes</Col>
                                    <Col xs={7}>{this.state.community.bikes_count}</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>No of Parking Slots</Col>
                                    <Col xs={7}>{this.state.community.apartment.parking_slot_count}</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>No of Wash Areas</Col>
                                    <Col xs={7}>{this.state.community.apartment.wash_areas_count}</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>Blocks</Col>
                                    <Col xs={7}>{(this.state.community.blocks.map(item => item.name)).toString()}</Col>
                                </Row>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                );
            }
            else {
                return (
                    <h1>Loading...</h1>
                )
            }
        }
    }
}

export default CommunityDetails;
