import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import callApi from '../../../util/apiCaller';

class VendorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditable: false,
            item: {},
            isLoading: true,
            isUpdatingCommunity: false,
            updateObject: {},
            listItems: {},
            pageNum: 1,
            pageSize: 25,
            totalCount: 0,
            query: '',
            apartmentList: {},
            apartments: {}
            
        };
    }

    componentDidMount() {
        console.log("selected Id", this.props.apartmentId);
        if (this.props.apartmentId) {
            this.fetchPackages(this.props.apartmentId);
        }
        this.fetchApartments();
    }
    fetchApartments = () => {
        let filters = '';
        console.log(filters);
        callApi(`apartments/list`, 'post', {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            selected_filter: filters || {},
            query: this.state.query
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    apartmentList: res.data,
                    totalCount: res.data.count
                }, () => {
                    console.log("fetched_apartments", this.state.apartmentList);
                })
            }
            else {
                console.log("error")
            }
        })
    };

    fetchPackages = (id) => {
        this.setState(
            {
                isLoading: true,
            },
            () => {
                callApi(`servicepackages/${id}`, 'get').then((res) => {
                    console.log(res);
                    if (res.status === 'Success') {
                        console.log("response", res.data);
                        this.setState({
                            isLoading: false,
                            servicepackages: res.data
                        });
                    }
                });
            }
        );
    };

    toggleEdit = () => {
        this.setState({
            isEditable: !this.state.isEditable,
        });
    };

    handleChange = (keyName,keyValue,i) => {
        console.log(keyValue)
        let item = this.state.servicepackages;
        //item.servicepackage['apartments'][i]['apartment_name'] = keyValue;
        let resKey = keyValue.split("@conact@");
        console.log(resKey);
        item.servicepackage['apartments'][i]['apartmentId'] = resKey[0];
        item.servicepackage['apartments'][i][keyName] = resKey[1];
        item.servicepackage['apartmentIds'][i] = resKey[0];
        
        //item.servicepackage['car_type_prices'][i]['cost'] = keyValue;
        this.setState({
            updateObject: item,
        });
        console.log('aprtments');
        console.log(item);
    };

    saveDetails = () => {
        let item = this.state.servicepackages.servicepackage;
        console.log('aprtments');
        console.log(item);
        this.updateCommunity(this.state.servicepackages.servicepackage._id, item);
    };

    updateCommunity = (id, servicepackage) => {
        this.setState({
            isUpdatingCommunity: true,
        });
        console.log(servicepackage);
        callApi(`servicepackages/${id}/update`, 'post', {
            servicepackage,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    isUpdatingCommunity: false,
                });
                this.toggleEdit();
                this.fetchPackages(id);
            } else {
                this.setState({
                    isUpdatingCommunity: false,
                });
                this.toggleEdit();
            }
        });
    };

    handleRemoveApartment = idx => () => {
        let item = this.state.servicepackages;
        console.log(item);
        item.servicepackage['apartments'] = this.state.servicepackages.servicepackage.apartments.filter((s, sidx) => idx !== sidx);
       
        item.servicepackage['apartmentIds'] = this.state.servicepackages.servicepackage.apartmentIds.filter((s, sidx) => idx !== sidx);

        this.setState({
            servicepackages: item
        });
        console.log(this.state.servicepackages.servicepackage.apartments);
      };
      handleAddApartment = () => {
        let item = this.state.servicepackages;
        item.servicepackage['apartments'] = this.state.servicepackages.servicepackage.apartments.concat([{ apartment_name:"" }])
        console.log(item);
        this.setState({
            servicepackages: item
        });
      };
    render() {
        //let locations = this.state.servicepackages.servicepackage.products_used.map(item => ({ value: item._id, label: item.name }));

        if (this.state.isEditable) {
            return (
                <Card>
                    <Card.Header>
                        <Row>
                            <Col xs={6}> {this.props.heading}</Col>
                            <Col xs={6}>
                                <div className={'text-right'}>
                                    <button
                                        type="button"
                                        onClick={this.handleAddApartment}
                                        className="btn btn-primary">
                                        Add New
                                    </button> &nbsp;
        							&nbsp; /  &nbsp;
        							&nbsp;
                                    <Button disabled={this.state.isUpdatingCommunity} onClick={() => this.saveDetails()}>
                                        {this.state.isUpdatingCommunity ? 'saving' : 'save'}
                                    </Button>
        							&nbsp;
        							&nbsp;
        							<Button onClick={() => this.toggleEdit()}>close</Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>
                    <ListGroup className="list-group-flush">
                    { this.state.listItems = this.state.servicepackages.servicepackage.apartments.map((apartment, index) =>
                        <ListGroupItem>
                            <Row>
                                <Col xs={10}>
                                    <FormControl
                         as="select"
                         onChange={(e) => this.handleChange('apartment_name',e.target.value,index)}
                         value={apartment.apartmentId +"@conact@"+ apartment.apartment_name}
                     >
                         <option value=""></option>
                         {this.state.apartmentList.apartments &&
                             this.state.apartmentList.apartments.map((item) => (
                                 <option value={item._id +"@conact@"+ item.name}>{item.name}</option>
                             ))}
                     </FormControl>

                                </Col>
                                <Col xs={2}> &nbsp;
                                     &nbsp;
                                     <button
                                     type="button"
                                     onClick={this.handleRemoveApartment(index)}
                                     className="btn btn-secondary"
                                     style={{height: "90%"}}
                                     >-</button></Col>
                            </Row>
                        </ListGroupItem>
                       )} 
                    </ListGroup> 
                </Card>
            );
        } else {
            if (!this.state.isLoading) {
                return (
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={6}>{this.props.heading}</Col>
                                <Col xs={6}>
                                    <div className="text-right">
                                        <Button onClick={() => this.toggleEdit()}>edit</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        <ListGroup className="list-group-flush">
                        { this.state.listItems = this.state.servicepackages.servicepackage.apartments.map((apartment, index) =>
                    <ListGroupItem>
                            <Row>
                                <Col xs={6}>{apartment.apartment_name}</Col>
                                <Col xs={6}>{apartment.apartmentId}</Col>
                            </Row>
                        </ListGroupItem>
                    )}
                       
                            
                        </ListGroup>
                    </Card>
                );
            }
            else {
                return (
                    <h1>Loading...</h1>
                )
            }
        }
    }
}

export default VendorDetails;
