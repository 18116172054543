import React, { Component } from "react";
import { Button, Card, Modal, Form } from 'react-bootstrap';


class AddBikeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: {
                name: "",
                bikeBrand: ""
            }
        }
    }
    handleChange = (keyName, keyValue) => {
        let item = this.state.item;
        item[keyName] = keyValue;
        this.setState({
            item: item,
        });
    };
    submitHandler = () => {
        let bike_model = this.state.item;
        this.props.addItem(bike_model);
        this.props.close();
    };
    render() {
        return (
            <Modal
                dialogClassName={'modal-dialog modal-dialog-scrollable modal-md'}
                show={this.props.isVisible}
                onHide={this.props.close}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add a New Bike Model</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="d-flex justify-content-center">
                            <Form onSubmit={() => this.submitHandler()}>
                                <Form.Group controlId="bikeModalName">
                                    <Form.Label>Bike Modal Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Modal Name"
                                        value={this.state.item.name}
                                        onChange={(e) => this.handleChange("name", e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="bikeBrand">
                                    <Form.Label>Bike Brand</Form.Label>
                                    <Form.Control
                                        as="select"
                                        onChange={(e) => this.handleChange("bikeBrand", e.target.value)}
                                    >
                                        <option selected="true" disabled>--Select--</option>
                                        {this.props.bikebrands.map((item) => (
                                            <option value={item._id}>{item.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        )
    }
}

export default AddBikeModal;
