import React, { Component } from 'react';
import callApi from '../../util/apiCaller';
import downloadApi from "../../util/apiDownloader";
import moment from "moment";
import _, { filter } from 'lodash';
import { Button, Card, Modal, Form } from 'react-bootstrap';
import Select from 'react-select';

class ServiceAgencyPackageAll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,
            pageSize: 25,
            totalCount: 0,
            agencies: [],
            locations: [],
            isAddVendorModalVisible: false,
            isAddingVendor: false,
            isDetailsModalVisible: false,
            selectedItemId: '',
            isDownloadingData: false,
            filter_locations: [],
            filter_status: '',
            query: ''
        };
        //this.debounceSearch = _.debounce(this.handleSearch, 500);
    }

    componentDidMount() {
        this.fetchAgencies();
        this.fetchLocations();
    }

    fetchAgencies = () => {

        console.log(this.state.query);
        callApi(`agencys/list`, 'post', {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            selected_filter: {},
            qtext: this.state.query
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    agencies: res.data["agencys"],
                    totalCount: res.data.count
                }, () => {
                    console.log("fetched_apartments", this.state.agencies);
                })
            }
            else {
                console.log("error")
            }
        })
    };

    fetchLocations = () => {
        callApi(`locations/list`, 'post', {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    locations: res.data["locations"]
                })
            }
            else {
                console.log("error")
            }
        })
    };

    handleChange = (keyName, keyValue) => {
        let item = {};
        item[keyName] = keyValue;
        this.setState(item);
    };

    render() {
        let agencies = this.state.agencies.map(item => ({ value: item._id, label: item.name }));
        return (
            <div className="container mt-4">
                <div className="row">
                    <div className="col-md-6">
                        <label>Select Vendor</label>
                        <Form.Control
                            as="select"
                            onChange={(e) => this.handleChange("agencyId", e.target.value)}
                        >
                            <option selected="true" disabled>--Select--</option>
                            {this.state.agencies.map((item) => (
                                <option value={item._id}>{item.name}</option>
                            ))}
                        </Form.Control>
                        <label>Select Location</label>       
                        <Form.Control
                            as="select"
                            onChange={(e) => this.handleChange("locationId", e.target.value)}
                        >
                            <option selected="true" disabled>--Select--</option>
                            {this.state.locations.map((item) => (
                                <option value={item._id}>{item.name}</option>
                            ))}
                        </Form.Control>        


                    </div>

                </div>
            </div>

        );
    };
}
export default ServiceAgencyPackageAll;