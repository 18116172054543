import React, { Component } from 'react';
import Dropdown from '../../components/Dropdown';

class AddPlaces extends Component {
    constructor(props) {
        super(props);
        this.state = {
            heading: "Add a Place to Database"
        }
    }
    render() {
        return (
            <>
                <div className="card mfp-hide mfp-popup-form mx-auto" id="test-form">
                    <div className="card-body">
                        <h4 className="mt-0 mb-4">Add a Place</h4>
                        <form>
                            <div className="row">
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <Dropdown
                                            label="Select Place Type" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input type="email" className="form-control" id="email" placeholder="Enter Email" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" className="form-control" id="email" placeholder="Enter Email" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="text-right">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </>
        );
    }
}

export default AddPlaces;