import React, { Component } from 'react';

class ServicePackageAdd extends Component {
    render() {
        return (
            <>
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Select2</h4>
                            <p className="card-title-desc">A mobile and touch friendly input spinner component for Bootstrap</p>
                            <form data-select2-id={13}>
                                <div className="row" data-select2-id={12}>
                                    <div className="col-lg-6" data-select2-id={11}>
                                        <div className="form-group" data-select2-id={10}>
                                            <label className="control-label">Single Select</label>
                                            <select className="form-control select2 select2-hidden-accessible" data-select2-id={1} tabIndex={-1} aria-hidden="true">
                                                <option data-select2-id={3}>Select</option>
                                                <optgroup label="Alaskan/Hawaiian Time Zone" data-select2-id={14}>
                                                    <option value="AK" data-select2-id={15}>Alaska</option>
                                                    <option value="HI" data-select2-id={16}>Hawaii</option>
                                                </optgroup>
                                                <optgroup label="Pacific Time Zone" data-select2-id={17}>
                                                    <option value="CA" data-select2-id={18}>California</option>
                                                    <option value="NV" data-select2-id={19}>Nevada</option>
                                                    <option value="OR" data-select2-id={20}>Oregon</option>
                                                    <option value="WA" data-select2-id={21}>Washington</option>
                                                </optgroup>
                                                <optgroup label="Mountain Time Zone" data-select2-id={22}>
                                                    <option value="AZ" data-select2-id={23}>Arizona</option>
                                                    <option value="CO" data-select2-id={24}>Colorado</option>
                                                    <option value="ID" data-select2-id={25}>Idaho</option>
                                                    <option value="MT" data-select2-id={26}>Montana</option>
                                                    <option value="NE" data-select2-id={27}>Nebraska</option>
                                                    <option value="NM" data-select2-id={28}>New Mexico</option>
                                                    <option value="ND" data-select2-id={29}>North Dakota</option>
                                                    <option value="UT" data-select2-id={30}>Utah</option>
                                                    <option value="WY" data-select2-id={31}>Wyoming</option>
                                                </optgroup>
                                                <optgroup label="Central Time Zone" data-select2-id={32}>
                                                    <option value="AL" data-select2-id={33}>Alabama</option>
                                                    <option value="AR" data-select2-id={34}>Arkansas</option>
                                                    <option value="IL" data-select2-id={35}>Illinois</option>
                                                    <option value="IA" data-select2-id={36}>Iowa</option>
                                                    <option value="KS" data-select2-id={37}>Kansas</option>
                                                    <option value="KY" data-select2-id={38}>Kentucky</option>
                                                    <option value="LA" data-select2-id={39}>Louisiana</option>
                                                    <option value="MN" data-select2-id={40}>Minnesota</option>
                                                    <option value="MS" data-select2-id={41}>Mississippi</option>
                                                    <option value="MO" data-select2-id={42}>Missouri</option>
                                                    <option value="OK" data-select2-id={43}>Oklahoma</option>
                                                    <option value="SD" data-select2-id={44}>South Dakota</option>
                                                    <option value="TX" data-select2-id={45}>Texas</option>
                                                    <option value="TN" data-select2-id={46}>Tennessee</option>
                                                    <option value="WI" data-select2-id={47}>Wisconsin</option>
                                                </optgroup>
                                                <optgroup label="Eastern Time Zone" data-select2-id={48}>
                                                    <option value="CT" data-select2-id={49}>Connecticut</option>
                                                    <option value="DE" data-select2-id={50}>Delaware</option>
                                                    <option value="FL" data-select2-id={51}>Florida</option>
                                                    <option value="GA" data-select2-id={52}>Georgia</option>
                                                    <option value="IN" data-select2-id={53}>Indiana</option>
                                                    <option value="ME" data-select2-id={54}>Maine</option>
                                                    <option value="MD" data-select2-id={55}>Maryland</option>
                                                    <option value="MA" data-select2-id={56}>Massachusetts</option>
                                                    <option value="MI" data-select2-id={57}>Michigan</option>
                                                    <option value="NH" data-select2-id={58}>New Hampshire</option>
                                                    <option value="NJ" data-select2-id={59}>New Jersey</option>
                                                    <option value="NY" data-select2-id={60}>New York</option>
                                                    <option value="NC" data-select2-id={61}>North Carolina</option>
                                                    <option value="OH" data-select2-id={62}>Ohio</option>
                                                    <option value="PA" data-select2-id={63}>Pennsylvania</option>
                                                    <option value="RI" data-select2-id={64}>Rhode Island</option>
                                                    <option value="SC" data-select2-id={65}>South Carolina</option>
                                                    <option value="VT" data-select2-id={66}>Vermont</option>
                                                    <option value="VA" data-select2-id={67}>Virginia</option>
                                                    <option value="WV" data-select2-id={68}>West Virginia</option>
                                                </optgroup>
                                            </select><span className="select2 select2-container select2-container--default select2-container--below" dir="ltr" data-select2-id={2} style={{ width: '501px' }}><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-a7i7-container"><span className="select2-selection__rendered" id="select2-a7i7-container" role="textbox" aria-readonly="true" title="Alaska">Alaska</span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
                                        </div>
                                        <div className="form-group mb-0">
                                            <label className="control-label">Multiple Select</label>
                                            <select className="select2 form-control select2-multiple select2-hidden-accessible" multiple data-placeholder="Choose ..." data-select2-id={4} tabIndex={-1} aria-hidden="true">
                                                <optgroup label="Alaskan/Hawaiian Time Zone" data-select2-id={75}>
                                                    <option value="AK" data-select2-id={76}>Alaska</option>
                                                    <option value="HI" data-select2-id={77}>Hawaii</option>
                                                </optgroup>
                                                <optgroup label="Pacific Time Zone" data-select2-id={78}>
                                                    <option value="CA" data-select2-id={79}>California</option>
                                                    <option value="NV" data-select2-id={80}>Nevada</option>
                                                    <option value="OR" data-select2-id={81}>Oregon</option>
                                                    <option value="WA" data-select2-id={82}>Washington</option>
                                                </optgroup>
                                                <optgroup label="Mountain Time Zone" data-select2-id={83}>
                                                    <option value="AZ" data-select2-id={84}>Arizona</option>
                                                    <option value="CO" data-select2-id={85}>Colorado</option>
                                                    <option value="ID" data-select2-id={86}>Idaho</option>
                                                    <option value="MT" data-select2-id={87}>Montana</option>
                                                    <option value="NE" data-select2-id={88}>Nebraska</option>
                                                    <option value="NM" data-select2-id={89}>New Mexico</option>
                                                    <option value="ND" data-select2-id={90}>North Dakota</option>
                                                    <option value="UT" data-select2-id={91}>Utah</option>
                                                    <option value="WY" data-select2-id={92}>Wyoming</option>
                                                </optgroup>
                                                <optgroup label="Central Time Zone" data-select2-id={93}>
                                                    <option value="AL" data-select2-id={94}>Alabama</option>
                                                    <option value="AR" data-select2-id={95}>Arkansas</option>
                                                    <option value="IL" data-select2-id={96}>Illinois</option>
                                                    <option value="IA" data-select2-id={97}>Iowa</option>
                                                    <option value="KS" data-select2-id={98}>Kansas</option>
                                                    <option value="KY" data-select2-id={99}>Kentucky</option>
                                                    <option value="LA" data-select2-id={100}>Louisiana</option>
                                                    <option value="MN" data-select2-id={101}>Minnesota</option>
                                                    <option value="MS" data-select2-id={102}>Mississippi</option>
                                                    <option value="MO" data-select2-id={103}>Missouri</option>
                                                    <option value="OK" data-select2-id={104}>Oklahoma</option>
                                                    <option value="SD" data-select2-id={105}>South Dakota</option>
                                                    <option value="TX" data-select2-id={106}>Texas</option>
                                                    <option value="TN" data-select2-id={107}>Tennessee</option>
                                                    <option value="WI" data-select2-id={108}>Wisconsin</option>
                                                </optgroup>
                                                <optgroup label="Eastern Time Zone" data-select2-id={109}>
                                                    <option value="CT" data-select2-id={110}>Connecticut</option>
                                                    <option value="DE" data-select2-id={111}>Delaware</option>
                                                    <option value="FL" data-select2-id={112}>Florida</option>
                                                    <option value="GA" data-select2-id={113}>Georgia</option>
                                                    <option value="IN" data-select2-id={114}>Indiana</option>
                                                    <option value="ME" data-select2-id={115}>Maine</option>
                                                    <option value="MD" data-select2-id={116}>Maryland</option>
                                                    <option value="MA" data-select2-id={117}>Massachusetts</option>
                                                    <option value="MI" data-select2-id={118}>Michigan</option>
                                                    <option value="NH" data-select2-id={119}>New Hampshire</option>
                                                    <option value="NJ" data-select2-id={120}>New Jersey</option>
                                                    <option value="NY" data-select2-id={121}>New York</option>
                                                    <option value="NC" data-select2-id={122}>North Carolina</option>
                                                    <option value="OH" data-select2-id={123}>Ohio</option>
                                                    <option value="PA" data-select2-id={124}>Pennsylvania</option>
                                                    <option value="RI" data-select2-id={125}>Rhode Island</option>
                                                    <option value="SC" data-select2-id={126}>South Carolina</option>
                                                    <option value="VT" data-select2-id={127}>Vermont</option>
                                                    <option value="VA" data-select2-id={128}>Virginia</option>
                                                    <option value="WV" data-select2-id={129}>West Virginia</option>
                                                </optgroup>
                                            </select><span className="select2 select2-container select2-container--default select2-container--below" dir="ltr" data-select2-id={5} style={{ width: '501px' }}><span className="selection"><span className="select2-selection select2-selection--multiple" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={-1} aria-disabled="false"><ul className="select2-selection__rendered"><li className="select2-selection__choice" title="Hawaii" data-select2-id={155}><span className="select2-selection__choice__remove" role="presentation">×</span>Hawaii</li><li className="select2-selection__choice" title="California" data-select2-id={156}><span className="select2-selection__choice__remove" role="presentation">×</span>California</li><li className="select2-search select2-search--inline"><input className="select2-search__field" type="search" tabIndex={0} autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" role="searchbox" aria-autocomplete="list" placeholder style={{ width: '0.75em' }} /></li></ul></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mt-3 mt-lg-0">
                                            <label className="control-label">Search Disable</label>
                                            <select className="form-control select2-search-disable select2-hidden-accessible" data-select2-id={6} tabIndex={-1} aria-hidden="true">
                                                <option data-select2-id={8}>Select</option>
                                                <optgroup label="Alaskan/Hawaiian Time Zone">
                                                    <option value="AK">Alaska</option>
                                                    <option value="HI">Hawaii</option>
                                                </optgroup>
                                                <optgroup label="Pacific Time Zone">
                                                    <option value="CA">California</option>
                                                    <option value="NV">Nevada</option>
                                                    <option value="OR">Oregon</option>
                                                    <option value="WA">Washington</option>
                                                </optgroup>
                                            </select><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id={7} style={{ width: '501px' }}><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-fsy7-container"><span className="select2-selection__rendered" id="select2-fsy7-container" role="textbox" aria-readonly="true" title="Select">Select</span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Textual inputs</h4>
                            <p className="card-title-desc">Here are examples of <code>.form-control</code> applied to each
        textual HTML5 <code>&lt;input&gt;</code> <code>type</code>.</p>
                            <div className="form-group row">
                                <label htmlFor="example-text-input" className="col-md-2 col-form-label">Text</label>
                                <div className="col-md-10">
                                    <input className="form-control" type="text" defaultValue="Artisanal kale" id="example-text-input" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-search-input" className="col-md-2 col-form-label">Search</label>
                                <div className="col-md-10">
                                    <input className="form-control" type="search" defaultValue="How do I shoot web" id="example-search-input" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-email-input" className="col-md-2 col-form-label">Email</label>
                                <div className="col-md-10">
                                    <input className="form-control" type="email" defaultValue="bootstrap@example.com" id="example-email-input" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-url-input" className="col-md-2 col-form-label">URL</label>
                                <div className="col-md-10">
                                    <input className="form-control" type="url" defaultValue="https://getbootstrap.com" id="example-url-input" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-tel-input" className="col-md-2 col-form-label">Telephone</label>
                                <div className="col-md-10">
                                    <input className="form-control" type="tel" defaultValue="1-(555)-555-5555" id="example-tel-input" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-password-input" className="col-md-2 col-form-label">Password</label>
                                <div className="col-md-10">
                                    <input className="form-control" type="password" defaultValue="hunter2" id="example-password-input" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-number-input" className="col-md-2 col-form-label">Number</label>
                                <div className="col-md-10">
                                    <input className="form-control" type="number" defaultValue={42} id="example-number-input" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-datetime-local-input" className="col-md-2 col-form-label">Date and time</label>
                                <div className="col-md-10">
                                    <input className="form-control" type="datetime-local" defaultValue="2020-03-14T13:45:00" id="example-datetime-local-input" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-date-input" className="col-md-2 col-form-label">Date</label>
                                <div className="col-md-10">
                                    <input className="form-control" type="date" defaultValue="2020-03-19" id="example-date-input" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-month-input" className="col-md-2 col-form-label">Month</label>
                                <div className="col-md-10">
                                    <input className="form-control" type="month" defaultValue="2020-03" id="example-month-input" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-week-input" className="col-md-2 col-form-label">Week</label>
                                <div className="col-md-10">
                                    <input className="form-control" type="week" defaultValue="2020-W14" id="example-week-input" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-time-input" className="col-md-2 col-form-label">Time</label>
                                <div className="col-md-10">
                                    <input className="form-control" type="time" defaultValue="13:45:00" id="example-time-input" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="example-color-input" className="col-md-2 col-form-label">Color</label>
                                <div className="col-md-10">
                                    <input className="form-control" type="color" defaultValue="#5438dc" id="example-color-input" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-2 col-form-label">Select</label>
                                <div className="col-md-10">
                                    <select className="form-control">
                                        <option>Select</option>
                                        <option>Large select</option>
                                        <option>Small select</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row mb-0">
                                <label className="col-md-2 col-form-label">Custom Select</label>
                                <div className="col-md-10">
                                    <select className="custom-select">
                                        <option selected>Open this select menu</option>
                                        <option value={1}>One</option>
                                        <option value={2}>Two</option>
                                        <option value={3}>Three</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Car Prices</h4>
                            <div className="table-responsive">
                                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer"><div className="row"><div className="col-sm-12 col-md-6" /><div className="col-sm-12 col-md-6"><div id="DataTables_Table_0_filter" className="dataTables_filter"><label>Search:<input type="search" className="form-control form-control-sm" placeholder aria-controls="DataTables_Table_0" /></label></div></div></div><div className="row"><div className="col-sm-12"><table className="table table-editable table-nowrap dataTable no-footer" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" style={{ position: 'relative' }}>
                                    <thead>
                                        <tr role="row"><th className="sorting_asc" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1} style={{ width: '53.8667px' }} aria-sort="ascending" aria-label="ID: activate to sort column descending">ID</th><th className="sorting" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1} style={{ width: '207.25px' }} aria-label="Age (AutoFill): activate to sort column ascending">Age (AutoFill)</th><th className="sorting" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1} style={{ width: '375.8px' }} aria-label="Qty (AutoFill and Editable): activate to sort column ascending">Qty (AutoFill and Editable)</th><th className="sorting" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1} style={{ width: '221.083px' }} aria-label="Cost (Editable): activate to sort column ascending">Cost (Editable)</th></tr>
                                    </thead>
                                    <tbody>
                                        <tr role="row" className="odd">
                                            <td className="sorting_1"><div style={{ position: 'absolute', height: '1px', width: '0px', overflow: 'hidden' }}><input type="text" tabIndex={0} /></div>1</td>
                                            <td data-original-value={11}>11</td>
                                            <td data-original-value={1}><a href="#" data-type="text" data-pk={1} className="editable editable-click" data-url data-title="Edit Quantity">1</a></td>
                                            <td data-original-value="1.99"><a href="#" data-type="text" data-pk={1} className="editable editable-click" data-url data-title="Edit Quantity">1.99</a></td>
                                        </tr><tr role="row" className="even">
                                            <td className="sorting_1">2</td>
                                            <td data-original-value={22}>22</td>
                                            <td data-original-value={2}><a href="#" data-type="text" data-pk={2} className="editable editable-click" data-url data-title="Edit Quantity">2</a></td>
                                            <td data-original-value="2.99"><a href="#" data-type="text" data-pk={1} className="editable editable-click" data-url data-title="Edit Quantity">2.99</a></td>
                                        </tr><tr role="row" className="odd">
                                            <td className="sorting_1">3</td>
                                            <td data-original-value={33}>33</td>
                                            <td data-original-value={3}><a href="#" data-type="text" data-pk={3} className="editable editable-click" data-url data-title="Edit Quantity">3</a></td>
                                            <td data-original-value="3.99"><a href="#" data-type="text" data-pk={1} className="editable editable-click" data-url data-title="Edit Quantity">3.99</a></td>
                                        </tr><tr role="row" className="even">
                                            <td className="sorting_1">4</td>
                                            <td data-original-value={44}>44</td>
                                            <td data-original-value={4}><a href="#" data-type="text" data-pk={4} className="editable editable-click" data-url data-title="Edit Quantity">4</a></td>
                                            <td data-original-value="4.99"><a href="#" data-type="text" data-pk={1} className="editable editable-click" data-url data-title="Edit Quantity">4.99</a></td>
                                        </tr><tr role="row" className="odd">
                                            <td className="sorting_1">5</td>
                                            <td data-original-value={55}>55</td>
                                            <td data-original-value={5}><a href="#" data-type="text" data-pk={5} className="editable editable-click" data-url data-title="Edit Quantity">5</a></td>
                                            <td data-original-value="5.99"><a href="#" data-type="text" data-pk={1} className="editable editable-click" data-url data-title="Edit Quantity">5.99</a></td>
                                        </tr></tbody></table></div></div><div className="row"><div className="col-sm-12 col-md-5"><div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 5 of 5 entries</div></div><div className="col-sm-12 col-md-7" /></div></div>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        );
    }
}


export default ServicePackageAdd;