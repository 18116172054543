import React, { Component, useState } from 'react';
import _ from 'lodash';
import callApi from '../../util/apiCaller';
import downloadApi from '../../util/apiDownloader';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select';
import DateRange from '../../components/DateRangePicker';

const MODEL_NAME = 'servicebooking';

export default class SpecialTasks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start: '',
            end: '',
            isDownloadingData: false
        };
    }

    downloadData = () => {
        this.setState({
            isDownloadingData: true,
        });
        downloadApi(`specialTasks[${moment(this.state.start).format('DD-MM-YYYY')} - ${moment(this.state.end).format('DD-MM-YYYY')}].csv`, `admin/special-tasks/dump?startDate=%22${this.state.start}%22&endDate=%22${this.state.end}%22`, 'GET').then(
            (blob) => {
                this.setState({
                    isDownloadingData: false,
                });
            }
        );
    }

    handleDateRange = (item) => {
        this.setState({
            start: moment(item[0]).format('MM-DD-YYYY'),
            end: moment(item[1]).format('MM-DD-YYYY')
        })
    }

    render() {
        return (
            <div className="container-fluid">
                {/* start page title */}
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="mb-0">Grooming services / On-Demand</h4>
                            <div className="page-title-right">
                                <Button
                                    className="mr-3"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#filter_modal"
                                    aria-expanded="false"
                                    aria-controls="multiCollapseExample2">
                                    <i className="fa fa-filter">
                                    </i>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Filter  */}
                <div className="collapse multi-collapse" id="filter_modal">
                    <div className="card card-body">
                        <h5>Apply Filters</h5>
                        <div className="container mt-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Date Range</label>
                                    <DateRange
                                        dateRangeValue={this.handleDateRange}
                                    />
                                </div>
                            </div>
                        </div>
                        <Button className="w-25 mx-auto mt-3" disabled={this.state.isDownloadingData} onClick={() => this.downloadData()}>
                            {this.state.isDownloadingData ? 'Downloading' : 'Download Data'}
                        </Button>
                    </div>
                </div>
                {/* end row */}
            </div>
        );
    }
}
