import React, { Component } from 'react';
import moment from 'moment';

class ListTableRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSidebarEnabled: true
        };
    }


    toggleSidebar = () => {
        this.setState({
            isSidebarEnabled: !this.state.isSidebarEnabled
        });
        if (this.state.isSidebarEnabled) {
            document.body.className = 'vertical-collpsed';
        } else {
            document.body.className = 'sidebar-enable';
        }
    };

    render() {
        
        return (
             <tr role="row" className="odd">
                <td>
                    {this.props.index + 1 + (this.props.page - 1) * this.props.size}
                </td>
                <td>
                    {moment(this.props.item.payment_date).format('DD MMM YYYY')}
                </td>
                <td><button
                        onClick={() => this.props.handleDetails(this.props.item._id,this.props.item.servicebookingId)}
                        className="btn btn-light btn-rounded"> {this.props.item.customerId} </button></td>
                
                <td ><button
                        onClick={() => this.props.handleDetails(this.props.item._id,this.props.item.servicebookingId)}
                        className="btn btn-light btn-rounded">{this.props.item.servicebookingId}</button></td>
                <td>
                    {this.props.item.refund_payment_amount}
                </td>
                <td>
                    {(this.props.item.isRefundPayment==true)?moment(this.props.item.refund_payment_date).format('DD MMM YYYY'):'-'}
                </td>
                {/* <td>
                    <div className="badge badge-soft-warning font-size-12">{this.props.item.isPaymentSuccess ? 'paid' : 'unpaid'}</div>
                </td> */}
                <td>
                    <button
                        onClick={() => this.props.handleDetails(this.props.item._id,this.props.item.servicebookingId)}
                        className="btn btn-light btn-rounded"> Details </button>

                </td>
                <td style={{ display: 'none' }}>
                    <a href="javascript:void(0);" className="mr-3 text-primary" data-toggle="tooltip" data-placement="top" title data-original-title="Edit"><i className="mdi mdi-pencil font-size-18" /></a>
                    <a
                        disable={this.props.item.isDeletingItem}
                        onClick={() => this.props.deleteItem(this.props.item._id)}
                        className="text-danger">
                        <i className="mdi mdi-trash-can font-size-18" />
                    </a>
                </td>
            </tr>
        );
    }
}

export default ListTableRow;
