import React, { Component } from "react";
import _ from "lodash";
import {
  Button,
  Card,
  Col,
  FormControl,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import moment from "moment";
import callApi from "../../../../util/apiCaller";

class ListVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
      item: {},
      isSubmittingVehicleDetails: false,
    };
  }

  componentDidMount() {
    if (this.props.item) {
      this.setState({
        item: this.props.item,
      });
    }
  }

  updateVehicleDetails = () => {
    let url = "";
    let data = {};

    if (this.state.item.vehicle_type == "car") {
      url = `cars/${this.state.item._id}/update`;
      data.car = {
        license_number: this.state.item.license_number,
      };
    } else {
      url = `bikes/${this.state.item._id}/update`;
      data.bike = {
        license_number: this.state.item.license_number,
      };
    }
	// console.log("bhanu",url,data);
    this.setState({
      isSubmittingVehicleDetails: true,
    });
    callApi(url, "post")
      .then((res) => {
        if (res.status === "Success") {
          this.props.fetchAllVehicles();
        }
        this.setState({
          isEditMode: false,
          isSubmittingVehicleDetails: false,
        });
      })
      .catch((err) => {
        this.setState({
          isEditMode: false,
          isSubmittingVehicleDetails: false,
        });
        alert("could not update, some internal issue occured.");
      });
  };

  onChangeLicenseNumber=(val)=>{
	let item = this.state.item;
	item.license_number = val;
	this.setState({
		item
	});
  }

  render() {
    return (
      <>
        {this.state.isEditMode ? (
          <Card>
            <Card.Header>
              <Row>
                <Col xs={6}>{this.props.heading}</Col>
                <Col xs={6} className="text-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.updateVehicleDetails();
                    }}
					disabled={this.state.isSubmittingVehicleDetails}
                  >
					  {this.state.isSubmittingVehicleDetails?"Submitting":"Submit"}
                  </button>
                </Col>
              </Row>
            </Card.Header>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                <Row>
                  <Col xs={5}>Vehicle name</Col>
                  <Col xs={7}>
                    {this.state.item && this.state.item.name
                      ? this.state.item.name
                      : "--NA--"}
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col xs={5}>Vehicle type</Col>
                  <Col xs={7}>
                    {this.state.item &&
                    this.state.item.model_id &&
                    this.state.item.model_id.car_body_type
                      ? this.state.item.model_id.car_body_type
                      : "bike"}
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col xs={5}>Vehicle brand</Col>
                  <Col xs={7}>
                    {this.state.item &&
                    this.state.item.brand_id &&
                    this.state.item.brand_id.name
                      ? this.state.item.brand_id.name
                      : "--NA--"}
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col xs={5}>Vehicle model</Col>
                  <Col xs={7}>
                    {this.state.item &&
                    this.state.item.model_id &&
                    this.state.item.model_id.name
                      ? this.state.item.model_id.name
                      : "--NA--"}
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col xs={5}>Vehicle Reg. NO</Col>
                  <Col xs={7}>
					  <input type="text" value={this.state.item && this.state.item.license_number
                      ? this.state.item.license_number
                      : "--NA--"} className="form-control" onChange={(e)=>{this.onChangeLicenseNumber(e.target.value)}}/>
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col xs={5}>Vehicle Fuel Type</Col>
                  <Col xs={7}>
                    {this.state.item && this.state.item.fuel_type
                      ? this.state.item.fuel_type
                      : "--NA--"}
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col xs={5}>Vehicle Tyres Type</Col>
                  <Col xs={7}>
                    {this.state.item && this.state.item.tyres_type
                      ? this.state.item.tyres_type
                      : ""}
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        ) : (
          <Card>
            <Card.Header>
              <Row>
                <Col xs={6}>{this.props.heading}</Col>
                <Col xs={6} className="text-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.setState({ isEditMode: true });
                    }}
                  >
                    Edit
                  </button>
                </Col>
              </Row>
            </Card.Header>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                <Row>
                  <Col xs={5}>Vehicle name</Col>
                  <Col xs={7}>
                    {this.state.item && this.state.item.name
                      ? this.state.item.name
                      : "--NA--"}
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col xs={5}>Vehicle type</Col>
                  <Col xs={7}>
                    {this.state.item &&
                    this.state.item.model_id &&
                    this.state.item.model_id.car_body_type
                      ? this.state.item.model_id.car_body_type
                      : "bike"}
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col xs={5}>Vehicle brand</Col>
                  <Col xs={7}>
                    {this.state.item &&
                    this.state.item.brand_id &&
                    this.state.item.brand_id.name
                      ? this.state.item.brand_id.name
                      : "--NA--"}
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col xs={5}>Vehicle model</Col>
                  <Col xs={7}>
                    {this.state.item &&
                    this.state.item.model_id &&
                    this.state.item.model_id.name
                      ? this.state.item.model_id.name
                      : "--NA--"}
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col xs={5}>Vehicle Reg. NO</Col>
                  <Col xs={7}>
                    {this.state.item && this.state.item.license_number
                      ? this.state.item.license_number
                      : "--NA--"}
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col xs={5}>Vehicle Fuel Type</Col>
                  <Col xs={7}>
                    {this.state.item && this.state.item.fuel_type
                      ? this.state.item.fuel_type
                      : "--NA--"}
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col xs={5}>Vehicle Tyres Type</Col>
                  <Col xs={7}>
                    {this.state.item && this.state.item.tyres_type
                      ? this.state.item.tyres_type
                      : ""}
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        )}
      </>
    );
  }
}

export default ListVehicle;
