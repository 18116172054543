import React, { Component } from "react";
import { Button, Card, Modal, Form, Col, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import Select from 'react-select';
import callApi from '../../../util/apiCaller';
import _, { filter } from 'lodash';

class AddBikeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: {
                name: "",
                bikeBrand: "",
                pageNum: 1,
                pageSize: 25,
                listItems: {},
                servicepackages: [{
                    "addons": [],
                    "agencyId": "",
                    "agency_logo": "",
                    "agency_name": "",
                    "apartmentIds": [],
                    "apartments": [{}],
                    "bike_types": [],
                    "car_type_prices": [{}],
                    "car_types": [],
                    "description": [],
                    "name": "",
                    "products_used": [],
                    "service_type": "",
                    "servicelistId": "",
                    "vehicle_type": ""
                }],
                servicepackage: [],
                name: "",
                quantity: 0,
                cost: 0,
                car_type: "",
                descriptions: [],
                product_used: [],
                adons: [{ quantity: "", name: "" }],
                car_type_prices: [{ car_type: "", cost: "" }],
                servicelist: [],
                agency_name: "",
                car_body_types: [{}],
                agencies: [{}]

            }
        }
    }
    componentDidMount() {
        let item = this.state.item.servicepackages;
        item[0]['description'] = this.props.descriptions;
        item[0]['products_used'] = this.props.product_used;


        this.setState({
            descriptions: this.props.descriptions,
            product_used: this.props.product_used,
            adons: this.props.adons,
            car_type_prices: this.props.car_type_prices,
            servicelist: this.props.servicelist,
            items: item,
            car_body_types: this.props.car_body_types,
            agencies: this.props.agencies,
        });
        console.log(this.props.agencies)

    }

    handleChangeText = (keyName, keyValue) => {
        let item = this.state.item.servicepackages;
        if (keyName == 'agencyId') {
            let resKey = keyValue.split("@conact@");
            item[0]['agencyId'] = resKey[0];
            item[0]['agency_name'] = resKey[1];
        } else {
            item[0][keyName] = keyValue;
        }

        this.setState({
            items: item,
        });
        console.log(this.state.items);
    };

    handleChange = (keyName, keyValue, i) => {
        console.log("bhanu", keyName, keyValue)

        let item = this.state.item.servicepackages;
        if (keyName == 'filter_apartments') {
            if (keyValue != null) {
                let aptValues = keyValue.map(item => ({
                    apartmentId: item.value,
                    apartment_name: item.label
                }));
                let aptIds = [];

                keyValue.map(aptitem => {
                    aptIds.push(aptitem.value)
                });

                item[0]['apartmentIds'] = aptIds
                item[0]['apartments'] = aptValues;
                this.setState({
                    items: item,
                });
            }

        }
        if (keyName == 'products_used') {
            let products_used = [...this.state.product_used];
            products_used[i] = keyValue;
            //this.setState({ multipleaccolades });
            item[0]['products_used'] = products_used;
            console.log(this.state.product_used);
            this.setState({
                items: item,
                product_used: products_used
            });
        }
        if (keyName == 'descriptions') {
            let descriptions = [...this.state.descriptions];
            descriptions[i] = keyValue;
            //this.setState({ multipleaccolades });
            item[0]['description'] = descriptions;
            console.log(this.state.descriptions);
            this.setState({
                items: item,
                descriptions: descriptions
            });
        }

        console.log(this.state.items);
    };

    handleChangeMulti = (keyName, SubKeyName, keyValue, i) => {
        let item = this.state.item.servicepackages;
        if (keyName == 'car_type_prices') {
            let car_type_prices = [...this.state.car_type_prices];
            car_type_prices[i][SubKeyName] = keyValue;
            //this.setState({ multipleaccolades });
            item[0][keyName] = car_type_prices;
            if (SubKeyName == 'car_type') {
                item[0]['car_types'] = keyValue
            }

            this.setState({
                items: item,
                car_type_prices: car_type_prices
            });
        }
        if (keyName == 'addons') {
            let addons = [...this.state.adons];
            addons[i][SubKeyName] = keyValue;
            //this.setState({ multipleaccolades });
            item[0][keyName] = addons;
            this.setState({
                items: item,
                adons: addons
            });
        }


        console.log(this.state.items);
    };

    submitHandler = (e) => {
        e.preventDefault();
        let servicepackage_model = this.state.items[0];
        let servicePackage = {
            "agencyId": servicepackage_model.agencyId,
            "apartmentIds": servicepackage_model.apartmentIds,
            "vehicle_type_prices": servicepackage_model.car_type_prices,
            "vehicle_types":servicepackage_model.car_type_prices.map(item=>(
                item.car_type
            )),
            "description": servicepackage_model.description,
            "name": servicepackage_model.name,
            "products_used": servicepackage_model.products_used,
            "vehicle_type": servicepackage_model.vehicle_type,
            "service_name": servicepackage_model.service_name,
            "service_start_date": servicepackage_model.service_start_date,
            "rebooking_charges": servicepackage_model.rebooking_charges
        };
        
        this.props.addItem(servicePackage);
        this.props.close();
    };

    handleAddProductUsed = () => {
        this.setState({
            product_used: this.state.product_used.concat('')
        });

    };
    handleAddDescriptions = () => {
        this.setState({
            descriptions: this.state.descriptions.concat('')
        });

    };

    handleRemoveAddOnholder(idx) {
        //  this.setState({
        //      product_used: this.state.product_used.filter((s, sidx) => idx !== sidx)
        //  });
        //  console.log(this.state.product_used);
        let productused = (this.state.items) ? [...this.state.items[0]['addons']] : '';
        console.log(idx)
        //this.state.items[0]['products_used'] = [...this.state.items[0]['products_used']];
        productused.splice(idx, 1);
        this.setState({ adons: productused }, () => {
            console.log(this.state.adons)
        });
        console.log(this.state.adons)
        this.state.items[0]['addons'] = productused;
    };

    handleRemoveCarTypePriceholder(idx) {
        //  this.setState({
        //      product_used: this.state.product_used.filter((s, sidx) => idx !== sidx)
        //  });
        //  console.log(this.state.product_used);
        let productused = (this.state.items) ? [...this.state.items[0]['car_type_prices']] : '';
        console.log(idx)
        //this.state.items[0]['products_used'] = [...this.state.items[0]['products_used']];
        productused.splice(idx, 1);
        this.setState({ car_type_prices: productused }, () => {
            console.log(this.state.car_type_prices)
        });
        console.log(this.state.product_used)
        this.state.items[0]['car_type_prices'] = productused;
    };

    handleRemoveProductUsedholder(idx) {
        //  this.setState({
        //      product_used: this.state.product_used.filter((s, sidx) => idx !== sidx)
        //  });
        //  console.log(this.state.product_used);
        let productused = (this.state.items) ? [...this.state.items[0]['products_used']] : '';
        console.log(idx)
        //this.state.items[0]['products_used'] = [...this.state.items[0]['products_used']];
        productused.splice(idx, 1);
        this.setState({ product_used: productused }, () => {
            console.log(this.state.product_used)
        });
        console.log(this.state.product_used)
        this.state.items[0]['products_used'] = productused;
    };

    handleRemoveDescriptionsholder(idx) {
        //  this.setState({
        //      product_used: this.state.product_used.filter((s, sidx) => idx !== sidx)
        //  });
        //  console.log(this.state.product_used);
        let productused = (this.state.items) ? [...this.state.items[0]['description']] : '';
        console.log(idx)
        //this.state.items[0]['products_used'] = [...this.state.items[0]['products_used']];
        productused.splice(idx, 1);
        this.setState({ descriptions: productused }, () => {
            console.log(this.state.product_used)
        });
        console.log(this.state.descriptions)
        this.state.items[0]['description'] = productused;
    };
    handleRemoveDescriptionholder(idx) {
        /*this.setState({
            descriptions: this.state.descriptions.filter((s, sidx) => idx !== sidx)
        });*/
        //  this.setState({
        //      product_used: this.state.product_used.filter((s, sidx) => idx !== sidx)
        //  });
        //  console.log(this.state.product_used);
        let descriptionslocal = (this.state.items) ? [...this.state.items[0]['description']] : '';

        //this.state.items[0]['products_used'] = [...this.state.items[0]['products_used']];

        descriptionslocal.splice(idx, 1);


        this.setState({ descriptions: descriptionslocal }, () => {
            console.log(this.state.descriptions)
        });
        console.log(this.state.descriptions)

        this.state.items[0]['description'] = descriptionslocal;



    };


    handleAddAdOn = () => {
        this.setState({
            adons: this.state.adons.concat([{ quantity: "", name: "" }])
        });

    };
    handleRemoveAdOnholder = idx => () => {
        this.setState({
            adons: this.state.adons.filter((s, sidx) => idx !== sidx)
        });
    };

    handleAddAdCarTypePrice = () => {
        this.setState({
            car_type_prices: this.state.car_type_prices.concat([{ car_type: "", cost: "" }])
        });
    };


    // handleRemoveCarTypePriceholder = idx => () => {
    //     this.setState({
    //         car_type_prices: this.state.car_type_prices.filter((s, sidx) => idx !== sidx)
    //     });
    // };




    handleShareholderNameChange = idx => evt => {
        const newShareholders = this.props.shareholders.map((shareholder, sidx) => {
            if (idx !== sidx) return shareholder;
            return { ...shareholder, name: evt.target.value };
        });

        this.setState({ shareholders: newShareholders });
    };


    render() {

        let apartments = this.props.apartments.map(item => ({ value: item._id, label: item.name }));

        let agencies = this.props.agencies.map(item => ({ value: item._id, label: item.name }));



        return (
            <Modal
                dialogClassName={'modal-dialog modal-dialog-scrollable modal-md'}
                show={this.props.isVisible}
                onHide={this.props.close}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add a New Service Package</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="d-flex justify-content-center">
                            <Form onSubmit={(e) => this.submitHandler(e)}>
                                <ListGroup className="list-group-flush">

                                    <ListGroupItem>
                                        <Row>
                                            <Col xs={6}>
                                                agencyId
                                            </Col>
                                            <Col xs={6}>

                                                <FormControl
                                                    as="select"
                                                    onChange={(e) => this.handleChangeText('agencyId', e.target.value)}
                                                >
                                                    <option value=""></option>
                                                    {this.state.agencies &&
                                                        this.state.agencies.map((item) => (
                                                            <option value={item._id + "@conact@" + item.name}>{item.name}</option>
                                                        ))}
                                                </FormControl>

                                            </Col>

                                        </Row>
                                    </ListGroupItem>

                                    <ListGroupItem>
                                        <Row>
                                            <Col xs={6}>
                                                Name
                                            </Col>
                                            <Col xs={6}>
                                                <input
                                                    type="text"
                                                    placeholder={''}
                                                    value={this.state.item.servicepackages.name}
                                                    onChange={(e) => this.handleChangeText('name', e.target.value)}
                                                    className="form-control"
                                                />
                                            </Col>

                                        </Row>
                                    </ListGroupItem>

                                    <ListGroupItem>
                                        <Row>
                                            <Col xs={6}>
                                                apartments
                                            </Col>
                                            <Col xs={6}>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    onChange={(value) => this.handleChange("filter_apartments", value)}
                                                    isMulti
                                                    options={apartments}
                                                />

                                            </Col>

                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <Row>
                                            <Col xs={6}>
                                                Vehicle Type
                                            </Col>
                                            <Col xs={6}>
                                                <select
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        if(e.target.value === "bike")
                                                        this.handleChangeMulti("car_type_prices", "car_type", e.target.value,0)
                                                        this.handleChangeText('vehicle_type', e.target.value)
                                                    }}
                                                >
                                                    <option selected="true" disabled>--Select--</option>
                                                    <option value={"bike"}>bike</option>
                                                    <option value={"car"}>car</option>
                                                </select>
                                            </Col>

                                        </Row>
                                    </ListGroupItem>

                                    <ListGroupItem>
                                        <Row>
                                            <Col xs={6}>
                                                <Form.Label> Vehicle Type Prices </Form.Label>
                                            </Col>
                                            <Col xs={6}>
                                                {this.state.item && this.state.item.servicepackages[0].vehicle_type === "bike"?
                                                null:
                                                <button
                                                type="button"
                                                onClick={this.state.item && this.state.item.servicepackages[0].vehicle_type === "bike"?null:this.handleAddAdCarTypePrice}
                                                className="btn btn-primary"
                                                style={{ height: "90%" }}
                                            >
                                                Add New
                                            </button>
                                                }
                                            </Col>
                                            <Col xs={12}>
                                                {this.state.car_type_prices && this.state.car_type_prices.map((shareholder, idx) => (
                                                    <div style={{ width: "100%", display: "inline-flex" }}>
                                                        {(this.state.item && this.state.item.servicepackages[0].vehicle_type == 'bike') ? (
                                                            <input
                                                                type="text"
                                                                placeholder={`Car Type #${idx + 1}`}
                                                                value={this.state.item && this.state.item.servicepackages[0].vehicle_type}
                                                                onChange={(e) => this.handleChangeMulti("car_type_prices", "car_type", e.target.value, idx)}
                                                                className="form-control"
                                                            />
                                                        ) : (

                                                            <FormControl
                                                                as="select"
                                                                onChange={(e) => this.handleChangeMulti("car_type_prices", "car_type", e.target.value, idx)}
                                                                value={shareholder.car_type}
                                                            >
                                                                <option value=""></option>
                                                                {this.state.car_body_types &&
                                                                    this.state.car_body_types.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                    ))}
                                                            </FormControl>
                                                        )

                                                        }
                                                        <input
                                                            type="hidden"
                                                            placeholder={`Car Type #${idx + 1}`}
                                                            value={shareholder.car_type}
                                                            onChange={(e) => this.handleChangeMulti("car_type_prices", "car_type", e.target.value, idx)}
                                                            className="form-control"
                                                        />



                                                        &nbsp;
                                                        &nbsp;
                                                        <input
                                                            type="text"
                                                            placeholder={`Cost #${idx + 1}`}
                                                            value={shareholder.cost}
                                                            onChange={(e) => this.handleChangeMulti("car_type_prices", "cost", e.target.value, idx)}
                                                            className="form-control"
                                                        />
                                                        &nbsp;
                                                        &nbsp;
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={this.handleRemoveCarTypePriceholder.bind(this, idx)}
                                                            style={{ height: "90%" }}
                                                        >
                                                            -
                                                        </button>

                                                    </div>
                                                ))}
                                            </Col>

                                        </Row>
                                    </ListGroupItem>

                                    <ListGroupItem>
                                        <Row>
                                            <Col xs={6}>
                                                <Form.Label> Descriptions </Form.Label>
                                            </Col>
                                            <Col xs={6}>
                                                <button
                                                    type="button"
                                                    onClick={this.handleAddDescriptions}
                                                    className="btn btn-primary"
                                                    style={{ height: "90%" }}
                                                >
                                                    Add New
                                                </button>
                                            </Col>
                                            <Col xs={12}>
                                                {this.state.descriptions && this.state.descriptions.map((shareholder, idx) => (
                                                    <div style={{ width: "100%", display: "inline-flex" }}>
                                                        <input
                                                            type="text"
                                                            placeholder={`Descriptions #${idx + 1}`}
                                                            value={shareholder}
                                                            onChange={(e) => this.handleChange("descriptions", e.target.value, idx)}
                                                            className="form-control"
                                                        />
                                                        &nbsp;
                                                        &nbsp;
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={this.handleRemoveDescriptionsholder.bind(this, idx)}
                                                            style={{ height: "90%" }}
                                                        >
                                                            -
                                                        </button>

                                                    </div>
                                                ))}
                                            </Col>

                                        </Row>
                                    </ListGroupItem>

                                    <ListGroupItem>
                                        <Row>
                                            <Col xs={6}>
                                                <Form.Label> Terms and Conditions </Form.Label>
                                            </Col>
                                            <Col xs={6}>
                                                <button
                                                    type="button"
                                                    onClick={this.handleAddProductUsed}
                                                    className="btn btn-primary"
                                                    style={{ height: "90%" }}
                                                >
                                                    Add New
                                                </button>
                                            </Col>
                                            <Col xs={12}>
                                                {this.state.product_used && this.state.product_used.map((shareholder, idx) => (
                                                    <div style={{ width: "100%", display: "inline-flex" }}>
                                                        <input
                                                            type="text"
                                                            placeholder={`Product Used #${idx + 1}`}
                                                            value={shareholder}
                                                            onChange={(e) => this.handleChange("products_used", e.target.value, idx)}
                                                            className="form-control"
                                                        />
                                                        &nbsp;
                                                        &nbsp;
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={this.handleRemoveProductUsedholder.bind(this, idx)}
                                                            style={{ height: "90%" }}
                                                        >
                                                            -
                                                        </button>

                                                    </div>
                                                ))}
                                            </Col>

                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <Row>
                                            <Col xs={6}>
                                                service_name
                                            </Col>
                                            <Col xs={6}>
                                                <input
                                                    type="text"
                                                    placeholder={''}
                                                    value={this.state.item.servicepackages.service_name}
                                                    onChange={(e) => this.handleChangeText('service_name', e.target.value)}
                                                    className="form-control"
                                                />
                                            </Col>

                                        </Row>
                                    </ListGroupItem>
                                    {/* <ListGroupItem>
                                        <Row>
                                            <Col xs={6}>
                                                service_type
                                            </Col>
                                            <Col xs={6}>
                                                <input
                                                    type="text"
                                                    placeholder={''}
                                                    value={this.state.item.servicepackages.service_type}
                                                    onChange={(e) => this.handleChangeText('service_type', e.target.value)}
                                                    className="form-control"
                                                />
                                            </Col>

                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <Row>
                                            <Col xs={6}>
                                                servicelistId
                                            </Col>
                                            <Col xs={6}>
                                                <input
                                                    type="text"
                                                    placeholder={''}
                                                    value={this.state.item.servicepackages.servicelistId}
                                                    onChange={(e) => this.handleChangeText('servicelistId', e.target.value)}
                                                    className="form-control"
                                                />
                                            </Col>

                                        </Row>
                                    </ListGroupItem> */}
                                    <ListGroupItem>
                                        <Row>
                                            <Col xs={6}>
                                                service start date
                                            </Col>
                                            <Col xs={6}>
                                                <input
                                                    type="date"
                                                    placeholder={''}
                                                    onChange={(e) => this.handleChangeText('service_start_date', e.target.value)}
                                                    className="form-control"
                                                />
                                            </Col>

                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <Row>
                                            <Col xs={6}>
                                                Rebooking Charges
                                            </Col>
                                            <Col xs={6}>
                                                <input
                                                    type="text"
                                                    placeholder={''}
                                                    onChange={(e) => this.handleChangeText('rebooking_charges', e.target.value)}
                                                    className="form-control"
                                                />
                                            </Col>

                                        </Row>
                                    </ListGroupItem>
{/* 
                                    <ListGroupItem>
                                        <Row>
                                            <Col xs={6}>
                                                <Form.Label> Ad-ons </Form.Label>
                                            </Col>
                                            <Col xs={6}>
                                                <button
                                                    type="button"
                                                    onClick={this.handleAddAdOn}
                                                    className="btn btn-primary"
                                                    style={{ height: "90%" }}
                                                >
                                                    Add New
                                                </button>
                                            </Col>
                                            <Col xs={12}>
                                                {this.state.adons && this.state.adons.map((shareholder, idx) => (
                                                    <div style={{ width: "100%", display: "inline-flex" }}>
                                                        <input
                                                            type="text"
                                                            placeholder={`Quantity #${idx + 1}`}
                                                            value={shareholder.quantity}
                                                            onChange={(e) => this.handleChangeMulti("addons", "quantity", e.target.value, idx)}
                                                            className="form-control"
                                                        />
                                                        &nbsp;
                                                        &nbsp;
                                                        <input
                                                            type="text"
                                                            placeholder={`AdOn #${idx + 1}`}
                                                            value={shareholder.name}
                                                            onChange={(e) => this.handleChangeMulti("addons", "name", e.target.value, idx)}
                                                            className="form-control"
                                                        />
                                                        &nbsp;
                                                        &nbsp;
                                                        <button
                                                            type="button"
                                                            onClick={this.handleRemoveAddOnholder.bind(this, idx)}
                                                            style={{ height: "90%" }}
                                                        >
                                                            -
                                                        </button>

                                                    </div>
                                                ))}
                                            </Col>

                                        </Row>
                                    </ListGroupItem> */}

                                </ListGroup>



                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        )
    }
}

export default AddBikeModal;
