import React, { Component } from 'react';

class ListTablePagination extends Component {
    constructor(props) {
        super(props);
    }

    // Counting the number of pages
    getPages = (count, pagesize) => {
        if (count > 0) {
            return Math.ceil(count / pagesize);
        } else {
            return 0;
        }
    };

    // Getting the First Row of each page according the pageSize
    getPageStart = (pageNum = 1, pageSize = 50, count = 0) => {
        if (parseInt(count) > 0) {
            return (parseInt(pageNum) - 1) * parseInt(pageSize) + 1;
        } else {
            return 0;
        }
    };

    getPageEnd = (pageNum = 1, pageSize = 50, count = 0) => {
        if (parseInt(count) > 0) {
            if ((count - ((pageNum - 1) * pageSize)) < pageSize) {
                return count;
            } else {
                return pageNum * pageSize;
            }
        } else {
            return 0;
        }
    };

    render() {
        let items = [];
        let pageCount = this.getPages(this.props.count, this.props.size);
        if (pageCount <= 8) {
            for (let i = 1; i <= pageCount; i++) {
                let num = i;
                let state = "";
                if (i === this.props.page) {
                    state = "active disabled";
                }
                items.push({"num": num, "state": state});
            }
        } else {
            if (this.props.page < 7) {
                items.push({"num": 1, "state": ""});
                items.push({"num": "-", "state": "disabled"});
                for (let i = 2; i <= 6; i++) {
                    let num = i;
                    let state = "";
                    if (i === this.props.page) {
                        state = "active disabled";
                    }
                    items.push({"num": num, "state": state});
                }
                items.push({"num": "-", "state": "disabled"});
                items.push({"num": pageCount, "state": ""});
            } else if ((pageCount - this.props.page) < 4) {
                items.push({"num": 1, "state": ""});
                items.push({"num": "-", "state": "disabled"});
                for (let i = 0; i < 5; i++) {
                    let num = parseInt(pageCount) - 5 + i;
                    let state = "";
                    if (num === this.props.page) {
                        state = "active disabled";
                    }
                    items.push({"num": num, "state": state});
                }
                items.push({"num": pageCount, "state": ""});
            } else {
                items.push({"num": 1, "state": ""});
                items.push({"num": "-", "state": "disabled"});
                for (let i = 0; i < 5; i++) {
                    let num = i + parseInt(this.props.page) - 2;
                    let state = "";
                    if (i === 2) {
                        state = "active disabled";
                    }
                    items.push({"num": num, "state": state});
                }
                items.push({"num": "-", "state": "disabled"});
                items.push({"num": pageCount, "state": ""});
            }
        }
        return (
            <div className="row">
                <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info" id="DataTables_Table_0_info" role="status"
                         aria-live="polite"> Showing {this.getPageStart(this.props.page, this.props.size, this.props.count)}
                        &nbsp;
                        to {this.getPageEnd(this.props.page, this.props.size, this.props.count)}
                        &nbsp;of {this.props.count} entries
                    </div>
                </div>
                <div className="col-sm-12 col-md-7">
                    <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                        <ul className="pagination pagination-rounded">
                            <li className={`paginate_button page-item previous ${this.props.page < 1 ? 'disabled' : ''}`}><a
                                onClick={() => this.props.page < 1 ? null : this.props.changePageNum(this.props.page - 1)}
                                className="page-link"><i
                                className="mdi mdi-chevron-left"></i></a></li>
                            {items.map(pageItem => (
                                <li
                                    onClick={() => this.props.changePageNum(pageItem.num)}
                                    className={`paginate_button page-item ${pageItem.state}`}><a className="page-link">{pageItem.num}</a></li>
                            ))}
                            <li className={`paginate_button page-item next ${this.getPageEnd(this.props.page, this.props.size, this.props.count) < this.props.count ? 'disabled' : ''}`} id="DataTables_Table_0_next"><a
                                onClick={() => this.getPageEnd(this.props.page, this.props.size, this.props.count) < this.props.count ?  this.props.changePageNum(this.props.page + 1) : null}
                                aria-controls="DataTables_Table_0" data-dt-idx="3" tabIndex="0"
                                className="page-link"><i className="mdi mdi-chevron-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        );

    }
}

export default ListTablePagination;
