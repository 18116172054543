import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row,Modal } from 'react-bootstrap';
import moment from 'moment';
import callApi from '../../../../util/apiCaller';
import Loader from 'react-loader-spinner';
import Datepicker from '../../../../components/DatePicker';
import CleanlinessCheck from './CleanlinessCheck';
import Loading from '../../../../components/Loading';

class GarageModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
            item:{}
		};
	}

    componentDidMount() {
		if (this.props.item) {
			this.setState({
				item: this.props.item,
			});
		}
	}

	render() {
			return (
				<>
				<Card>
					{this.state.item.serviceType === "on-demand" ?
						<div style={{ width: '100%', backgroundColor: 'orange', textAlign: 'center', height: 20 }}>
							<p style={{ fontWeight: 'bold' }}>On Demand</p>
						</div>
						: null}
					<Card.Header>
						<Row>
							<Col xs={6}>{this.props.heading}</Col>
							{/* <Col xs={6}>
								<div className="text-right">
									<Button onClick={() => this.toggleEdit()}>edit</Button>
								</div>
							</Col> */}
						</Row>
					</Card.Header>
					<ListGroup className="list-group-flush">
						<ListGroupItem>
							<Row>
								<Col xs={5}>Booking Number</Col>
								<Col xs={7}>{this.state.item.booking_num}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Service Name</Col>
								<Col xs={7}>{this.state.item && this.state.item.servicepackageId && this.state.item.servicepackageId.name ? this.state.item.servicepackageId.name : ''}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Payment Status</Col>
								<Col xs={7}>
									{this.state.item.isPaymentSuccess ? 'Paid' : 'Unpaid'}
									{/* {this.state.item.isPaymentFailed ? 'Failed' : ''} */}
								</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Vehicle Details</Col>
								<Col xs={7}>
									{_.startCase(this.state.item.vehicle_type)},{'  '}
									{_.startCase(this.state.item && this.state.item.vehicleId && this.state.item.vehicleId.brand_id && this.state.item.vehicleId.brand_id.name?this.state.item.vehicleId.brand_id.name:"")},{'  '}
									{_.startCase(this.state.item && this.state.item.vehicleId && this.state.item.vehicleId.model_id && this.state.item.vehicleId.model_id.name?this.state.item.vehicleId.model_id.name:"")}
								</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>License Number</Col>
								<Col xs={7}>{this.state.item &&this.state.item.vehicleId && this.state.item.vehicleId.license_number ? this.state.item.vehicleId.license_number : ''}</Col>
							</Row>
						</ListGroupItem>
						{/* <ListGroupItem>
							<Row>
								<Col xs={5}>Location Name</Col>
								<Col xs={7}>{this.state.item && this.state.item.addressId && this.state.item.addressId.location_id && this.state.item.addressId.location_id.name ? this.state.item.addressId.location_id.name : ''}</Col>
							</Row>
						</ListGroupItem> */}
						<ListGroupItem>
							<Row>
								<Col xs={5}>Community Name</Col>
								<Col xs={7}>{this.state.item && this.state.item.addressId && this.state.item.addressId.community_id && this.state.item.addressId.community_id.name ? this.state.item.addressId.community_id.name : ''}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Block Name</Col>
								<Col xs={7}>{this.state.item && this.state.item.addressId && this.state.item.addressId.block_id && this.state.item.addressId.block_id.name ? this.state.item.addressId.block_id.name : ''}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Flat Number</Col>
								<Col xs={7}>{this.state.item && this.state.item.addressId && this.state.item.addressId.flat_no ? this.state.item.addressId.flat_no : ''}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Parking Slot</Col>
								<Col xs={7}>{this.state.item && this.state.item.addressId && this.state.item.addressId.parking_slot ? this.state.item.addressId.parking_slot : ''}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Basement No</Col>
								<Col xs={7}>{this.state.item && this.state.item.addressId && this.state.item.addressId.basement ? this.state.item.addressId.basement : ''}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Time Slot</Col>
								<Col xs={7}>{this.state.item && this.state.item.timeSlot && this.state.item.timeSlot.time_slot_name ? this.state.item.timeSlot.time_slot_name : ''}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Service Start Date</Col>
								<Col xs={7}>
									{this.state.item && this.state.item.service_booking_date ? moment(new Date(this.state.item.service_booking_date)).format('DD MMM YYYY') : ''}
								</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Package Duration</Col>
								<Col xs={7}>
									{this.state.item && this.state.item.package_duration}
								</Col>
							</Row>
						</ListGroupItem>
					</ListGroup>
				</Card>
				</>
			);
		}
	}

export default GarageModal;
