import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import callApi from '../../../../util/apiCaller';

class VendorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditable: false,
            item: {},
            isLoading: true,
            isUpdatingCommunity: false,
            updateObject: {},
            listItems: {},
            users: {}
            
        };
    }

    componentDidMount() {
        console.log("selected Id supervisor", this.props.apartmentId);
        if (this.props.apartmentId) {
            this.fetchUserById(this.props.apartmentId);
        }
    }

    fetchUserById = (id) => {
        this.setState(
            {
                isLoading: true,
            },
            () => {
                callApi(`users/${id}`, 'get').then((res) => {
                    console.log(res);
                    if (res.status === 'Success') {
                        console.log("response", res.data);
                        this.setState({
                            isLoading: false,
                            users: res.data
                        });
                        //console.log(this.state.users);
                    }
                });
            }
        );
    };

    toggleEdit = () => {
        this.setState({
            isEditable: !this.state.isEditable,
        });
    };

    handleChange = (keyName,keyValue,i) => {
        let item = this.state.users;
        item.user[keyName] = keyValue;
        this.setState({
            updateObject: item,
        });
        console.log(item);
    };

    saveDetails = () => {
        let item = this.state.users.user;
        console.log(item);
        this.updateCommunity(this.state.users.user._id, item);
    };

    updateCommunity = (id, user) => {
        this.setState({
            isUpdatingCommunity: true,
        });
        console.log(user);
        callApi(`users/${id}/update`, 'post', {
            user,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    isUpdatingCommunity: false,
                });
                this.toggleEdit();
                this.fetchUserById(id);
            } else {
                this.setState({
                    isUpdatingCommunity: false,
                });
                this.toggleEdit();
            }
        });
    };

    
    render() {
        //let locations = this.state.servicepackages.servicepackage.products_used.map(item => ({ value: item._id, label: item.name }));

        if (this.state.isEditable) {
            return (
                <Card>
                    <Card.Header>
                        <Row>
                            <Col xs={6}> {this.props.heading}</Col>
                            <Col xs={6}>
                                <div className={'text-right'}>
                                      
                                    <Button disabled={this.state.isUpdatingCommunity} onClick={() => this.saveDetails()}>
                                        {this.state.isUpdatingCommunity ? 'saving' : 'save'}
                                    </Button>
        							&nbsp;
        							&nbsp;
        							<Button onClick={() => this.toggleEdit()}>close</Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Name</Col>
                                <Col xs={7}><FormControl
                                         onChange={(e) => this.handleChange('name',e.target.value)}
                                         value={this.state.users.user.name}
                                     /></Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Email</Col>
                                <Col xs={7}><FormControl
                                         onChange={(e) => this.handleChange('email',e.target.value)}
                                         value={this.state.users.user.email}
                                     /></Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Phone</Col>
                                <Col xs={7}><FormControl
                                         onChange={(e) => this.handleChange('phone',e.target.value)}
                                         value={this.state.users.user.phone}
                                     /></Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Years Of Experience</Col>
                                <Col xs={7}><FormControl
                                         onChange={(e) => this.handleChange('yearsOfExperience',e.target.value)}
                                         value={this.state.users.user.yearsOfExperience}
                                     /></Col>
                            </Row>
                        </ListGroupItem>


                        
                    </ListGroup> 
                </Card>
            );
        } else {
            if (!this.state.isLoading) {
                return (
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={6}>{this.props.heading}</Col>
                                <Col xs={6}>
                                    <div className="text-right">
                                        <Button onClick={() => this.toggleEdit()}>edit</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        <ListGroup className="list-group-flush">
                       
                        <ListGroupItem>
                            
                            <Row>
                                <Col xs={5}>Agency Id</Col>
                                <Col xs={7}>{this.state.users.user.agencyId}</Col>
                            </Row>
                            </ListGroupItem>
                            <ListGroupItem>    
                            <Row>
                                <Col xs={5}>Agency Name</Col>
                                <Col xs={7}>{this.state.users.user.agency_name}</Col>
                            </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                            <Row>
                                <Col xs={5}>Name</Col>
                                <Col xs={7}>{this.state.users.user.name}</Col>
                            </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                            <Row>
                                <Col xs={5}>Email</Col>
                                <Col xs={7}>{this.state.users.user.email}</Col>
                            </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                            <Row>
                                <Col xs={5}>Phone</Col>
                                <Col xs={7}>{this.state.users.user.phone}</Col>
                            </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                            <Row>
                                <Col xs={5}>Year of Exp</Col>
                                <Col xs={7}>{this.state.users.user.yearsOfExperience}</Col>
                            </Row>
   
                        </ListGroupItem>
                  
                       
                            
                        </ListGroup>
                    </Card>
                );
            }
            else {
                return (
                    <h1>Loading...</h1>
                )
            }
        }
    }
}

export default VendorDetails;
