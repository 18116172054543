import React, { Component } from 'react';
import { Button, Card, FormControl, Modal, Nav, Tab, TabContainer, TabContent, Tabs } from 'react-bootstrap';
import callApi from '../../../../util/apiCaller';
import CommunityDetails from './CommunityDetails';
import CommunityVendorDetails from './CommunityVendorDetails';

class ApartmentDetailsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSidebarEnabled: true,
            selectedItem: {},
            isLoading: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedItemId !== this.props.selectedItemId) {
            if (this.props.selectedItemId) {
                this.fetchItem(this.props.selectedItemId);
            }
        }
    }

    render() {
        return (
            <Modal
                dialogClassName={'modal-dialog modal-dialog-scrollable modal-lg'}
                show={this.props.isVisible}
                onHide={this.props.close}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.user && this.state.user.name ? this.state.user.name : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ minHeight: '230px' }}>
                        <Card>
                            <TabContainer defaultActiveKey={'community-details'}>
                                <Card.Header>
                                    <Nav variant="pills">
                                        <Nav.Item>
                                            <Nav.Link eventKey="community-details">Community Details</Nav.Link>
                                        </Nav.Item>
                                        {/* <Nav.Item>
                                            <Nav.Link eventKey="vendor-details">Vendor Details</Nav.Link>
                                        </Nav.Item> */}
                                    </Nav>
                                </Card.Header>
                                <Card.Body>
                                    <TabContent>
                                        <Tab.Pane eventKey="community-details" title="Community Details">
                                            <CommunityDetails
                                                fetchApartments={this.props.fetchApartments}
                                                heading="Community"
                                                apartmentId={this.props.selectedItemId}
                                            />
                                        </Tab.Pane>
                                        {/* <Tab.Pane eventKey="vendor-details" title="Vendor Details">
                                            <CommunityVendorDetails
                                                fetchApartments={this.props.fetchApartments}
                                                heading="Agency"
                                                apartmentId={this.props.selectedItemId}
                                            />
                                        </Tab.Pane> */}
                                    </TabContent>
                                </Card.Body>
                            </TabContainer>
                        </Card>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.close}>
                        Close
					</Button>
                    <Button variant="primary">Save Changes</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ApartmentDetailsModal;
