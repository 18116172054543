import React, { Component } from 'react';
import callApi from '../../../util/apiCaller';
import ListTable from './components/ListTable';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import AddBikeModal from './components/AddBikeModal';
import downloadApi from "../../../util/apiDownloader";
import moment from "moment";
import SupervisorDetailsModal from './components/CleanerDetails'

class Cleaners extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: 'all',
			query: '',
			pageNum: 1,
			pageSize: 25,
			totalCount: 0,
			items: [],
			bikebrands: [],
			isAddBikeModalVisible: false,
			supervisors: [],
			selectedItemId: '',
			isDetailsModalVisible: false,
		};
		this.debounceSearch = _.debounce(this.handleSearch, 500);
	}

	componentDidMount() {
		this.fetchCleanerUsers();
		//this.fetchBikeBrands();
	}

	fetchCleanerUsers = () => {
		console.log('Test');
		let filters = this.getFilters();
		callApi('users/cleanerlist', 'post', {
			pageNum: this.state.pageNum,
			pageSize: this.state.pageSize,
			qtext:  true,
			
		}).then((res) => {
			console.log('fetchUsers Api', res);
			this.setState({
				items: res.data['users'],
			});
		});
	};

	fetchCleanerSelectUsers = (id) => {
		console.log('Test');
		let filters = this.getFilters();
		callApi('users/id', 'post', {
			pageNum: this.state.pageNum,
			pageSize: this.state.pageSize,
			qtext:  true,
			
		}).then((res) => {
			console.log('fetchUsers Api', res);
			this.setState({
				items: res.data['users'],
			});
		});
	};

	

	getFilters = () => {
		let input = {}; input.query = this.state.query;
		input.selected_filter = this.state.isSupervisor;
		input.pageNum = this.state.pageNum;
		input.pageSize = this.state.pageSize;
		return input;
	};

	deleteItem = (id) => {
		let items = this.state.items.map((item) => {
			if (item._id === id) {
				item.isDeletingItem = true;
			}
			return item;
		});
		this.setState(
			{
				items: items,
			},
			() => {
				callApi(`bikemodels/${id}/remove`, 'post', {}).then((res) => {
					if (res.status === 'Success') {
						let items = this.state.items.filter((item) => item._id !== id);
						this.setState({
							items: items,
							totalCount: this.state.totalCount - 1,
						});
					}
				});
			}
		);
	};

	addItem = (item) => {
		callApi(`bikemodels/new`, 'post', {
			bikemodel: item,
		}).then((res) => {
			if (res.status === 'Success') {
				let items = this.state.items;
				items.unshift(res.data.bikemodel);
				this.setState({
					items: items,
				});
			}
		});
	};

	
	downloadData = () => {
		console.log('Download pressed');
		this.setState({
			isDownloadingData: true,
		});
		let filters = this.getFilters();
		downloadApi(`bikes-${moment().format('DD-MM-YYYY-HH:mm')}.csv`, `/admin/bikemodels/dump`, 'POST', filters).then(
			(blob) => {
				this.setState({
					isDownloadingData: false,
				});
				console.log(JSON.stringify(blob));
			}
		);
	};

	handleQuery = (val) => {
		this.setState(
			{
				query: val,
			},
			() => {
				this.debounceSearch();
			}
		);
	};

	handleSearch = () => {
		let filters = this.getFilters();
		this.fetchItems(filters);
	};

	addBikeHandler = () => {
		this.setState({
			isAddBikeModalVisible: true
		})
	}

	closeModal = () => {
		this.setState({
			isDetailsModalVisible: false,
		});
	};

	handleDetails = (id) => {
        this.setState({
            selectedItemId: id,
            isDetailsModalVisible: true,
        });
    };

	render() {
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box d-flex align-items-center justify-content-between">
							<h4 className="mb-0">Cleaners</h4>
							<div className="page-title-right">
								{/* <Button onClick={() => this.addBikeHandler()}>
									<i className="fa fa-plus"></i> Add
								</Button>
								&nbsp;
								&nbsp;
								<Button disabled={this.state.isDownloadingData}
									onClick={() => this.downloadData()}>
									{this.state.isDownloadingData ? 'Downloading' : 'Download Data'}
								</Button> */}
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-body">
							<ListTable
                                    changePageNum={this.changePageNum}
                                    handleQuery={this.handleQuery}
                                    updateItem={this.updateItem}
                                    deleteItem={this.deleteItem}
                                    handleDetails={this.handleDetails}
                                    query={this.state.query}
                                    items={this.state.items}
                                    locations={this.state.locations}
                                    pageSize={this.state.pageSize}
                                    totalCount={this.state.totalCount}
                                    pageNum={this.state.pageNum}
                                />
							</div>
						</div>
					</div>
				</div>
				{this.state.isAddBikeModalVisible ? (
					<AddBikeModal
						isVisible={this.state.isAddBikeModalVisible}
						close={this.closeModal}
						addItem={this.addItem}
						bikebrands={this.state.bikebrands}
					/>
				) : null}
				{this.state.isDetailsModalVisible ? (
                    <SupervisorDetailsModal
                        isVisible={this.state.isDetailsModalVisible}
                        selectedItemId={this.state.selectedItemId}
                        close={this.closeModal}
                        fetchApartments={this.fetchPackages}
                    />
                ) : null}
			</div>
		);
	}
}

export default Cleaners;
