import React, { Component } from 'react';
import _ from 'lodash';
import {Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row} from 'react-bootstrap';

class ModalTable extends Component {

    constructor(props) {
        super(props);
        this.state=  {
            isEditable: false,
            item: {}
        };
    }

    componentDidMount() {
        if (this.props.item) {
            this.setState({
                item: this.props.item
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.item, this.props.item)) {
            this.setState({
                item: this.props.item
            });
        }
    }

    toggleEdit = () => {
        this.setState({
            isEditable: !this.state.isEditable
        })
    };

    handleChange = () => {

    };

    render() {
        if (this.state.isEditable) {
            return (
                <Card>
                    <Card.Header>
                        {this.props.heading}
                        <Button onClick={() => this.toggleEdit()}>edit</Button>
                    </Card.Header>
                    <ListGroup className="list-group-flush">
                        {this.props.body && Object.entries(this.props.body).map((item, index) => (
                            <ListGroupItem key={index}>
                                <Row>
                                    <Col xs={5}>
                                        {item[0]}
                                    </Col>
                                    <Col xs={7}>
                                        <FormControl
                                            onChange={(e) => this.handleChange('', e.target.value)}
                                            value={item[1]}
                                        />
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </Card>
            )
        } else {
            return (
                <Card>
                    <Card.Header>
                        {this.props.heading}
                        <Button onClick={() => this.toggleEdit()}>edit</Button>
                    </Card.Header>
                    <ListGroup className="list-group-flush">
                        {this.props.body && Object.entries(this.props.body).map((item, index) => (
                            <ListGroupItem key={index}>{item[0]} - {item[1]} </ListGroupItem>
                        ))}
                    </ListGroup>
                </Card>
            )
        }
    }
}


export default ModalTable;