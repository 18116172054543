import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import callApi from '../../../util/apiCaller';

class CommunityVendorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditable: false,
            item: {},
            isLoading: true,
        };
    }

    componentDidMount() {
        console.log("selected Id", this.props.apartmentId);
        if (this.props.apartmentId) {
            this.fetchItem(this.props.apartmentId);
        }
    }

    fetchItem = (id) => {
        this.setState(
            {
                isLoading: true,
            },
            () => {
                callApi(`admin/community/${id}/agency-details`, 'get').then((res) => {
                    console.log(res);
                    if (res.status === 'Success') {
                        console.log("response", res.data);
                        this.setState({
                            isLoading: false,
                            item: res.data
                        });
                    }
                });
            }
        );
    };

    render() {
        if (!this.state.isLoading) {
            return (
                <Card>
                    <Card.Header>
                        <Row>
                            <Col xs={6}>{this.props.heading}</Col>
                        </Row>
                    </Card.Header>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Agency Name</Col>
                                <Col xs={7}>{this.state.item.agency.name}</Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Vendor Name</Col>
                                <Col xs={7}>{this.state.item.agency.owner_name}</Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Phone Number</Col>
                                <Col xs={7}>{this.state.item.agency.owner_phone}</Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Email</Col>
                                <Col xs={7}>{this.state.item.agency.agency_email}</Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Supervisor Name</Col>
                                <Col xs={7}>{this.state.item.agency.supervisor_name}</Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Supervisor Phone Number</Col>
                                <Col xs={7}>{this.state.item.agency.supervisor_phone}</Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Address</Col>
                                <Col xs={7}>{this.state.item.agency.address}</Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>GST Number</Col>
                                <Col xs={7}>{this.state.item.agency.gst_number}</Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Date of Registration</Col>
                                <Col xs={7}>{new Date(this.state.item.agency.created_at).toDateString()}</Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            );
        }
        else {
            return (
                <h1>Loading...</h1>
            )
        }
    }
}

export default CommunityVendorDetails;
