import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import callApi from '../../../util/apiCaller';
import Loader from 'react-loader-spinner';

class OwnerDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditable: false,
            item: {},
            isLoading: true,
            agency: {},
            owner: {},
            isUpdating: false,
        };
    }

    componentDidMount() {
        console.log("selected Id", this.props.agencyId);
        if (this.props.agencyId) {
            this.fetchItem(this.props.agencyId);
        }
    }
    toggleEdit = () => {
        this.setState({
            isEditable: !this.state.isEditable,
        });
    };

    fetchItem = (id) => {
        this.setState(
            {
                isLoading: true,
            },
            () => {
                callApi(`agencys/${id}`, 'get').then((res) => {
                    console.log(res);
                    if (res.status === 'Success') {
                        console.log("response", res.data);
                        this.setState({
                            agency: res.data.agency
                        }, () => {
                            callApi(`users/${this.state.agency.ownerId}`, 'get').then((res) => {
                                console.log(res);
                                if (res.status === 'Success') {
                                    console.log("response", res.data);
                                    this.setState({
                                        isLoading: false,
                                        owner: res.data.user,
                                        item: res.data.user
                                    });

                                }
                            });
                        });

                    }
                });
                console.log(this.state);
            }
        );
    };

    updateUserDetails = (userId, user) => {
        this.setState({
            isUpdating: true,
        })
        callApi(`admin/user/${userId}/update`, 'post', {
            user: user
        }).then((res) => {
            console.log(res);
            if (res.status === 'Success') {
                console.log("response", res.data);
                this.setState({
                    isUpdating: false,
                    owner: res.data.user,
                    item: res.data.user
                });
            } else {
                this.setState({
                    isUpdating: false,
                })
            }
        });
    }
    saveDetails = () => {
        let item = this.state.item;
        this.updateUserDetails(this.state.item._id, item);
        console.log("item", item)
        this.toggleEdit();
    };

    handleChange = (keyName, keyValue) => {
        let item = this.state.item;
        item[keyName] = keyValue;
        this.setState({
            item: item,
        })
    };

    render() {
        if (!this.state.isLoading) {
            if (this.state.isEditable) {
                return (
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={6}> {this.props.heading}</Col>
                                <Col xs={6}>
                                    <div className={'text-right'}>
                                        <Button disabled={this.state.isUpdating} onClick={() => this.saveDetails()}>
                                            {this.state.isUpdating ? 'saving' : 'save'}
                                        </Button>
									&nbsp;
									&nbsp;
									<Button onClick={() => this.toggleEdit()}>close</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        {
                            this.state.isUpdating ? <Loader></Loader> :
                                <ListGroup className="list-group-flush">
                                    <ListGroupItem>
                                        <Row>
                                            <Col xs={5}>Owner Name</Col>
                                            <Col xs={7}>
                                                <FormControl
                                                    onChange={(e) => this.handleChange('name', e.target.value)}
                                                    value={this.state.item.name}
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <Row>
                                            <Col xs={5}>Email</Col>
                                            <Col xs={7}>
                                                <FormControl
                                                    onChange={(e) => this.handleChange('email', e.target.value)}
                                                    value={this.state.item.email}
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <Row>
                                            <Col xs={5}>Phone</Col>
                                            <Col xs={7}>
                                                {/* <FormControl
                                                    onChange={(e) => this.handleChange('phone', e.target.value)}
                                                    value={this.state.item.phone}
                                                /> */}
                                                {this.state.item.phone}
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <Row>
                                            <Col xs={5}>OTP</Col>
                                            <Col xs={7}>{this.state.item.otp}</Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <Row>
                                            <Col xs={5}>Id Proof</Col>
                                            <Col xs={7}>{this.state.item.aadhar_num}</Col>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <Row>
                                            <Col xs={5}>Date Of Joining</Col>
                                            <Col xs={7}>{new Date(this.state.item.created_at).toDateString()}</Col>
                                        </Row>
                                    </ListGroupItem>
                                </ListGroup>
                        }
                    </Card>
                )
            } else {
                return (
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={6}>{this.props.heading}</Col>
                                <Col xs={6}>
                                    <div className="text-right">
                                        <Button onClick={() => this.toggleEdit()}>edit</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        <ListGroup className="list-group-flush">
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>Owner Name</Col>
                                    <Col xs={7}>{this.state.owner.name}</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>Email</Col>
                                    <Col xs={7}>{this.state.owner.email}</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>Phone Number</Col>
                                    <Col xs={7}>{this.state.owner.phone}</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>OTP</Col>
                                    <Col xs={7}>{this.state.owner.otp}</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>Date Of Joining</Col>
                                    <Col xs={7}>{new Date(this.state.owner.created_at).toDateString()}</Col>
                                </Row>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                );
            }
        }
        else {
            return (
                <h1>Loading...</h1>
            )
        }
    }
}

export default OwnerDetails;
