import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';
import _ from 'lodash';


class ListTableRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditModeVisible: false,
            saved: {},
        };
    }

    handleChange = (keyName, keyValue) => {
        let item = this.state.saved;
        item[keyName] = keyValue;
        this.setState({
            saved: item,
        });
    };

    deleteHandler = (item) => {
        this.props.deleteItem(item.apartmentId);
    }

    render() {
        return (
            <tr role="row" className="odd">
                <td>{this.props.index + 1 + (this.props.page - 1) * this.props.size}</td>
                <td>{this.props.item.apartment_name}</td>
                <td>{this.props.item.location_name}</td>
                <td>{this.props.item.city_name}</td>
                <td>{this.props.item.isActive ? "Active" : "In Active"}</td>
                <td>
                    <button
                        onClick={() => this.props.handleDetails(this.props.item.apartmentId)}
                        className="btn btn-primary btn-rounded ml-3"
                    >
                        Details
                        </button>
                    <button
                        disabled={this.props.item.isDeletingItem}
                        onClick={() => this.deleteHandler(this.props.item)}
                        className="btn btn-danger btn-rounded ml-3"
                    >
                        {this.props.item.isDeletingItem ? "Deleting" : "Delete"}
                    </button>
                </td>
            </tr>
        );
    }
}

export default ListTableRow;
