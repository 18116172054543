import React, { Component } from 'react';
import moment from 'moment';
import { FormControl } from 'react-bootstrap';
import _ from 'lodash';

class ListTableRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEditModeVisible: false,
			saved: {},
		};
	}

	openEditMode = (item) => {
		console.log(item);
		this.setState({
			isEditModeVisible: true,
			saved: _.clone(item),
		});
	};

	closeEditMode = () => {
		this.setState({
			isEditModeVisible: false,
			saved: {},
		});
	};

	handleChange = (keyName, keyValue) => {
		let item = this.state.saved;
		if (keyName === 'bikebrand') {
			let filterItem = this.props.bikebrands.filter((item) => item.name === keyValue);
			item['bikebrand'] = filterItem[0].name;
			item['bikebrand_slug'] = filterItem[0].slug;
			item['bikebrandId'] = filterItem[0]._id;
		} else {
			item[keyName] = keyValue;
		}
		this.setState({
			saved: item,
		});
	};

	saveData = () => {
		let item = this.state.saved;
		this.props.updateItem(this.props.item._id, item);
		this.closeEditMode();
	};

	render() {
		//console.log('brands', this.props.bikebrands);
		if (this.state.isEditModeVisible) {
			return (
				<tr role="row" className="odd">
					<td>{this.props.index + 1 + (this.props.page - 1) * this.props.size}</td>
					<td>
						<FormControl
							onChange={(e) => this.handleChange('name', e.target.value)}
							value={this.state.saved ? this.state.saved.name : ''}
						/>
					</td>
					<td>
						<FormControl
							as="select"
							onChange={(e) => this.handleChange('bikebrand', e.target.value)}
							value={this.state.saved ? this.state.saved.bikebrand : ''}
						>
							<option value=""></option>
							{this.props.bikebrands.map((item) => (
								<option value={item.name}>{item.name}</option>
							))}
						</FormControl>
					</td>
					<td>
						
					</td>
				</tr>
			);
		} else {
			return (
				<tr role="row" className="odd">
					<td>{this.props.index + 1 + (this.props.page - 1) * this.props.size}</td>
					<td>{this.props.item.agency_name}</td>
					<td>{this.props.item.name}</td>
					<td>{this.props.item.email}</td>
					<td>{this.props.item.phone}</td>
					<td>
					<button
                        onClick={() => this.props.handleDetails(this.props.item._id)}
                        className="btn btn-primary btn-rounded ml-3"
                    >
                        Details
                        </button>
					</td>
				</tr>
			);
		}
	}
}

export default ListTableRow;
