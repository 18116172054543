import React, { Component, useState } from 'react';
import moment from 'moment';
import { Button, Card, FormControl, ListGroup, Modal, Nav, Tab, TabContainer, TabContent, Tabs, Col, ListGroupItem, Row } from 'react-bootstrap';
import callApi from '../../../util/apiCaller';
import UserModalTable from './components/UserModalTable';
import CarDetailsModal from './components/CarDetailsModal';
import BookingDetailsModal from './components/BookingDetailsModal';
import PaymentDetailsModal from './components/PaymentDetailsModal';
import ScheduleDetailsModal from './components/ScheduleDetailsModal';
import CleanlinessCheck from './components/CleanlinessCheck';
import Loading from '../../../components/Loading';
import GroomingAddonModal from './components/GroomingAddonsModal';
import GarageModal from './components/GarageModal';
import ListVehicle from './components/ListVehiclesModal';
import ListAddress from './components/ListAddress';
import ListPayments from './components/ListPayments';
import ListInvoices from './components/ListInvoices';
import EditInvoicesModal from './components/EditInvoicesModal';
import _ from 'lodash';
import ListBills from './components/ListBills';

const MODEL_NAME = 'servicebooking';

export default class ServiceBookingDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSidebarEnabled: true,
			isLoading: false,
			currentServiceBooking: {},
			user: {},
			servicebookings: [],
			isCleanlinessCheckModalEnable: false,
			isFetchingBookings: false,
			selectedServiceBooking: {},
			selectedBookingServiceRequests: false,
			isFetchingVehicles: false,
			vehicles: [],
			isFetchingAddress: false,
			addresses: [],
			isFetchingCustomerPaymentHistory: false,
			paymentsHistory: [],
			isEditInvoiceModalEnable: false,
			editInvoicesList: [],
			selectedPaymentInvoices: [],
			selectedPaymentBookings: [],
			isStopingService:false,
			isReactivatingService:false,
			isFetchingTransactions: false,
		};
	}

	async componentDidMount() {
		if (this.props.location && this.props.location.state && this.props.location.state.selectedUserId) {
			this.fetchUser();
			this.fetchCombinedBookings();
			this.fetchAllVehicles();
			this.fetchAllAddresses();
			this.fetchPaymentHistory();
		}
	}

	fetchUser = () => {
		this.setState({
			isLoading: true
		})
		callApi(`users/${this.props.location && this.props.location.state && this.props.location.state.selectedUserId}`, 'get')
			.then(res => {
				if (res.status === 'Success') {
					this.setState({
						user: res.data.user
					})
				}
				this.setState({
					isLoading: false
				})
			})
			.catch(err => {
				console.log(err);
				this.setState({
					isLoading: false
				})
			})
	}

	fetchCombinedBookings = () => {
		this.setState({
			isFetchingBookings: true
		})
		callApi(`admin/customer/${this.props.location && this.props.location.state && this.props.location.state.selectedUserId}/get-bookings`, 'get')
			.then((res) => {
				if (res.status === 'Success') {
					this.setState({
						servicebookings: res.data,
					});
				}
				this.setState({
					isFetchingBookings: false
				})
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					isFetchingBookings: false
				})
			});
	};

	fetchAllVehicles = () => {
		this.setState({
			isFetchingVehicles: true,
		});
		callApi(`v2/customer/${this.props.location && this.props.location.state && this.props.location.state.selectedUserId}/get-vehicles`, 'get')
			.then((res) => {
				if (res.status == 'Success') {
					this.setState({
						vehicles: res.data,
					});
				}
				this.setState({
					isFetchingVehicles: false,
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					isFetchingVehicles: false,
				});
			});
	};

	fetchAllAddresses = () => {
		this.setState({
			isFetchingAddress: true,
		});
		callApi(`v2/customer/${this.props.location && this.props.location.state && this.props.location.state.selectedUserId}/get-address`, 'get')
			.then((res) => {
				if (res.status == 'Success') {
					this.setState({
						addresses: res.data,
					});
				}
				this.setState({
					isFetchingAddress: false,
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					isFetchingAddress: false,
				});
			});
	};

	fetchPaymentHistory = () => {
		this.setState({
			isFetchingCustomerPaymentHistory: true,
		})
		callApi(`customer/${this.props.location && this.props.location.state && this.props.location.state.selectedUserId}/payment-history`, 'post', {})
			.then((res) => {
				if (res.status === 'Success') {
					this.setState({
						paymentsHistory: res.data.payments,
						isFetchingCustomerPaymentHistory: false,
					})
				} else {
					this.setState({
						isFetchingCustomerPaymentHistory: false,
					})
				}
			});
	};

	openCleanlinessCheckModal = (selectedServiceBookingId) => {
		let filteredServiceBookings = this.state.servicebookings.filter(item => item.servicebooking._id === selectedServiceBookingId);
		let selectedServiceBooking = filteredServiceBookings.length > 0 ? filteredServiceBookings[0].servicebooking : null;
		this.setState({
			isCleanlinessCheckModalEnable: true,
			selectedServiceBooking
		})
	}
	closeCleanlinessCheckModal = () => {
		this.setState({
			isCleanlinessCheckModalEnable: false
		})
	}

	checkIfServiceRequests = (name, extraAddonServiceRequestId) => {
		if (extraAddonServiceRequestId) {
			let currentExtraAddon = this.state.selectedServiceBooking && this.state.selectedServiceBooking.extraAddons && this.state.selectedServiceBooking.extraAddons.length > 0 ? this.state.selectedServiceBooking.extraAddons.filter(
				(item) => item.id._id === extraAddonServiceRequestId,
			)[0] : [];
			if (
				currentExtraAddon.specialtaskId &&
				currentExtraAddon.specialtaskId.isCompleted
			) {
				return 'completed';
			}
			if (
				currentExtraAddon.specialtaskId &&
				currentExtraAddon.specialtaskId.isRescheduled
			) {
				return 'scheduled';
			}
			if (
				currentExtraAddon.specialtaskId &&
				!currentExtraAddon.specialtaskId.isRescheduled
			) {
				return 'reschedule';
			}
			if (!currentExtraAddon.specialtaskId) {
				return 'schedule';
			}
		} else {
			let exists = false;
			let completed = false;
			let rescheduled = false;
			if (this.state.servicerequests && this.state.servicerequests.length > 0) {
				this.state.servicerequests.forEach((item) => {
					if (item.service_name === name) {
						exists = true;
						if (item.isCompleted) {
							completed = true;
						}
						if (item.isRescheduled) {
							rescheduled = true;
						}
					}
				});
				if (exists && !completed) {
					if (rescheduled) {
						return 'scheduled';
					} else {
						return 'reschedule';
					}
				} else if (exists && completed) {
					return 'completed';
				} else {
					return 'schedule';
				}
			} else {
				return 'schedule';
			}
		}
	};

	openEditInvoiceModal = () => {
		let invoicesList = [];
		if (this.state.garageInvoices && this.state.garageInvoices.length > 0) {
			let latestInvoice = {
				"id": this.state.garageInvoices[0].recentInvoiceId,
				"due_date": this.state.garageInvoices[0].recentInvoiceDueDate,
				"amount": this.state.garageInvoices[0].recentInvoiceAmount,
				"notes": this.state.garageInvoices[0].recentInvoiceNotes
			}
			invoicesList.push(latestInvoice);
			this.state.garageInvoices[0].invoiceIds.forEach(item => {
				invoicesList.push(item);
			});
			this.setState({
				editInvoicesList: invoicesList,
				isEditInvoiceModalEnable: true
			})
		}
	}

	closeEditInvoiceModal = () => {
		this.setState({
			isEditInvoiceModalEnable: false
		})
	}

	openPaymentInvoicesModal = (paymentId) => {
		let filterPayment = this.state.paymentsHistory.filter(item => (item._id == paymentId));
		let selectedPaymentInvoices = [];
		if (filterPayment && filterPayment.length > 0 && filterPayment[0].customerinvoices.length > 0) {
			filterPayment[0].customerinvoices.forEach(customerInvoiceItem => {
				if (customerInvoiceItem && customerInvoiceItem.invoices && customerInvoiceItem.invoices.length > 0) {
					customerInvoiceItem.invoices.forEach(invoice => {
						selectedPaymentInvoices.push(invoice);
					})
				}
			})
		}
		this.setState({
			isPaymentInvoicesModalEnable: true,
			selectedPaymentInvoices
		})
	}

	closePaymentInvoicesModal = () => {
		this.setState({
			isPaymentInvoicesModalEnable: false
		})
	}

	openPaymentBookingsModal = (paymentId) => {
		let filterPayment = this.state.paymentsHistory.filter(item => (item._id == paymentId));
		let selectedPaymentBookings = filterPayment[0].servicebookingIds;
		this.setState({
			isPaymentBookingsModalEnable: true,
			selectedPaymentBookings
		})
	}

	closePaymentBookingsModal = () => {
		this.setState({
			isPaymentBookingsModalEnable: false
		})
	}

	stopService = (bookingId) => {
		this.setState({
		  isStopingService: true,
		});
		callApi(
		  `v2/customer/${this.props?.location?.state?.selectedUserId}/servicebooking/${bookingId}/stop-booking`,
		  'post',
		  {
			reason: 'Stopped by admin',
		  },
		)
		  .then((res) => {
			if (res.status == 'Success') {
			  this.fetchCombinedBookings();
			}
			this.setState({
			  isStopingService: false,
			});
		  })
		  .catch((err) => {
			console.log(err);
			this.setState({
			  isStopingService: false,
			});
		  });
	  };
	
	  reactivateService = (bookingId) => {
		this.setState({
		  isReactivatingService: true,
		});
		callApi(
		  `v2/customer/${this.props?.location?.state?.selectedUserId}/servicebooking/${bookingId}/reactivate-booking`,
		  'post',
		  {
			reason: 'Reactivated by admin',
		  },
		)
		  .then((res) => {
			if (res.status == 'Success') {
			  this.fetchCombinedBookings();
			}
			this.setState({
			  isReactivatingService: false,
			});
		  })
		  .catch((err) => {
			console.log(err);
			this.setState({
			  isReactivatingService: false,
			});
		  });
	  };

	render() {
		return (
			<div>
				<Modal.Title>{this.state.user && this.state.user.name ? this.state.user.name : ''}</Modal.Title>
				<div style={{ minHeight: '230px' }}>
					<Card>
						<TabContainer defaultActiveKey={'user-details'}>
							<Card.Header>
								<Nav variant="pills">
									<Nav.Item>
										<Nav.Link eventKey="user-details">User Details</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="booking-details">Bookings</Nav.Link>
									</Nav.Item>

									<Nav.Item>
										<Nav.Link eventKey="vehicle-details">Vehicles</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="address-details">Addresses</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="payment-details">Payments</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="bill-details">Pocket</Nav.Link>
									</Nav.Item>
								</Nav>
							</Card.Header>
							<Card.Body>
								<TabContent>
									<Tab.Pane eventKey="user-details" title="Details">
										<UserModalTable
											heading="User"
											updateUser={this.updateUser}
											isUpdatingUser={this.state.isUpdatingUser}
											item={this.state.user ? this.state.user : null}
											isLoading={this.state.isLoading}
										/>
									</Tab.Pane>
									<Tab.Pane eventKey="booking-details" title="ServiceBookings">
										{this.state.isFetchingBookings ?
											<Loading />
											:
											this.state.servicebookings &&
											this.state.servicebookings.map((item) => {
												return (
													<BookingDetailsModal
														heading="Servicebooking"
														item={item.servicebooking}
														vehicle={item.vehicle}
														timeSlot={item.timeSlot}
														openCleanlinessCheckModal={this.openCleanlinessCheckModal}
														userDetails={this.props.loginUser}
														stopService={this.stopService}
														reactivateService={this.reactivateService}
														isStopingService={this.state.isStopingService}
														isReactivatingService={this.state.isReactivatingService}
													/>
												)
											})
										}
									</Tab.Pane>
									<Tab.Pane eventKey="vehicle-details" title="Vehicles">
										{
											this.state.isFetchingVehicles ?
												<Loading />
												:
												this.state.vehicles &&
												this.state.vehicles.map((item) => (
													<ListVehicle
														heading="Vehicles"
														item={item}
														fetchAllVehicles={this.fetchAllVehicles}
													/>
												))}
									</Tab.Pane>
									<Tab.Pane eventKey="address-details" title="Addresses">
										{
											this.state.isFetchingAddress ?
												<Loading />
												:
												this.state.addresses &&
												this.state.addresses.map((item) => (
													<ListAddress
														heading="Addresses"
														item={item}
													/>
												))}
									</Tab.Pane>
									<Tab.Pane eventKey="payment-details" title="payments">
										{
											this.state.isFetchingCustomerPaymentHistory ?
												<Loading />
												:
												this.state.paymentsHistory &&
												this.state.paymentsHistory.map((item) => (
													<ListPayments
														heading="Payments"
														item={item}
														openPaymentInvoicesModal={this.openPaymentInvoicesModal}
														openPaymentBookingsModal={this.openPaymentBookingsModal}
														fetchPaymentHistory={this.fetchPaymentHistory}
													/>
												))}
									</Tab.Pane>
									<Tab.Pane eventKey="bill-details" title="payments">
										{
													<ListBills
														heading="Pocket"
														userId={this.props.location && this.props.location.state && this.props.location.state.selectedUserId}
													/>}
									</Tab.Pane>
								</TabContent>
							</Card.Body>
						</TabContainer>
					</Card>
				</div>
				{/* cleanliness check modal */}
				<Modal
					dialogClassName={'modal-dialog modal-dialog-scrollable modal-xl'}
					show={this.state.isCleanlinessCheckModalEnable}
					onHide={() => this.closeCleanlinessCheckModal()}
				>
					<Modal.Header closeButton>
						<Modal.Title>Cleanliness Check</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<CleanlinessCheck
							heading="Cleanliness check"
							userId={this.props.location && this.props.location.state && this.props.location.state.selectedUserId}
							selectedBookingId={this.state.selectedServiceBooking && this.state.selectedServiceBooking._id}
							service_start_date={this.state.selectedServiceBooking && this.state.selectedServiceBooking.service_start_date}
							cancelledOn={this.state.selectedServiceBooking && this.state.selectedServiceBooking.cancelledOn ? this.state.selectedServiceBooking.cancelledOn : null}
						/>
					</Modal.Body>
				</Modal>

				{/* Edit / Delete invoice Modal*/}
				<Modal
					dialogClassName={'modal-dialog modal-dialog-scrollable modal-xl'}
					show={this.state.isEditInvoiceModalEnable}
					onHide={() => this.closeEditInvoiceModal()}
				>
					<Modal.Header closeButton>
						<Modal.Title>Edit / Delete Invoice(s)</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.state.isFetchingGarage ?
							<Loading />
							:
							this.state.editInvoicesList && this.state.editInvoicesList.length > 0 ? this.state.editInvoicesList.map(item => (

								<EditInvoicesModal
									invoice={item}
									fetchGarageBookings={this.fetchGarageBookings}
									userDetails={this.props.loginUser}
								/>
							))
								:
								null

						}
					</Modal.Body>
				</Modal>

				{/* payment invoices Model */}
				<Modal
					dialogClassName={'modal-dialog modal-dialog-scrollable modal-xl'}
					show={this.state.isPaymentInvoicesModalEnable}
					onHide={() => this.closePaymentInvoicesModal()}
				>
					<Modal.Header closeButton>
						<Modal.Title>Invoices Paid</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.state.selectedPaymentInvoices && this.state.selectedPaymentInvoices.length > 0 ? this.state.selectedPaymentInvoices.map((item, index) => (

							<div>
								<Card>
									<Card.Header>
										<Row>
											<Col xs={6}>Invoice {index + 1}</Col>
											{this.state.item && !this.state.item.isPaymentStepDone && !this.state.item.isPaid && this.state.item.payment_option != "upi" ?
												<Col xs={6} className='text-right'><button className='btn btn-primary' disabled={this.state.isRefreshingPayment} onClick={() => { this.handleOnClickRefresh(this.state.item._id) }}>{this.state.isRefreshingPayment ? "Refreshing..." : "Refresh"}</button></Col>
												:
												null
											}
										</Row>
									</Card.Header>
									<ListGroup className="list-group-flush">
										<ListGroupItem>
											<Row>
												<Col xs={5}>Service Name</Col>
												<Col xs={7}>{item && item.servicebookingId && item.servicebookingId.servicepackageId && item.servicebookingId.servicepackageId.name}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Invoice Month</Col>
												<Col xs={7}>{item && item.invoice_tag}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Invoice Type</Col>
												<Col xs={7}>{item && item.invoice_type}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Booking Number</Col>
												<Col xs={7}>{item && item.servicebookingId && item.servicebookingId.booking_num}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Booking Amount</Col>
												<Col xs={7}>₹ {item && item.booking_amount}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Payment Amount</Col>
												<Col xs={7}>₹ {item && item.paid_amount && parseFloat(item.paid_amount).toFixed(2)}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Invoice Amount</Col>
												<Col xs={7}>₹ {item && item.payment_amount}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Discount Percentage</Col>
												<Col xs={7}>{item && item.discount_percentage}%</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Selected Package</Col>
												<Col xs={7}>{item && item.package} month(s)</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Next Payment Date</Col>
												<Col xs={7}>{item && item.servicebookingId && item.servicebookingId.next_payment_date && moment(item.servicebookingId.next_payment_date).format("DD MMM, YYYY")}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Vehicle Type</Col>
												<Col xs={7}>{item && item.servicebookingId && item.servicebookingId.vehicleId && item.servicebookingId.vehicleId.vehicle_type}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Model Name</Col>
												<Col xs={7}>{item && item.servicebookingId && item.servicebookingId.vehicleId && item.servicebookingId.vehicleId.model_id && item.servicebookingId.vehicleId.model_id.name}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Brand Name</Col>
												<Col xs={7}>{item && item.servicebookingId && item.servicebookingId.vehicleId && item.servicebookingId.vehicleId.brand_id && item.servicebookingId.vehicleId.brand_id.name}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>License Number</Col>
												<Col xs={7}>{item && item.servicebookingId && item.servicebookingId.vehicleId && item.servicebookingId.vehicleId.license_number}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Address Name</Col>
												<Col xs={7}>{item && item.servicebookingId && item.servicebookingId.addressId && item.servicebookingId.addressId.name}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Apartment Name</Col>
												<Col xs={7}>{item && item.servicebookingId && item.servicebookingId.addressId && item.servicebookingId.addressId.community_id && item.servicebookingId.addressId.community_id.name}</Col>
											</Row>
										</ListGroupItem>
									</ListGroup>
								</Card>
							</div>
						))
							:
							null

						}
					</Modal.Body>
				</Modal>

				{/* payment booking Model */}
				<Modal
					dialogClassName={'modal-dialog modal-dialog-scrollable modal-xl'}
					show={this.state.isPaymentBookingsModalEnable}
					onHide={() => this.closePaymentBookingsModal()}
				>
					<Modal.Header closeButton>
						<Modal.Title>Booking Paid</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.state.selectedPaymentBookings && this.state.selectedPaymentBookings.length > 0 ? this.state.selectedPaymentBookings.map((item, index) => (

							<div>
								<Card>
									<Card.Header>
										<Row>
											<Col xs={6}>Booking {index + 1}</Col>
											{this.state.item && !this.state.item.isPaymentStepDone && !this.state.item.isPaid && this.state.item.payment_option != "upi" ?
												<Col xs={6} className='text-right'><button className='btn btn-primary' disabled={this.state.isRefreshingPayment} onClick={() => { this.handleOnClickRefresh(this.state.item._id) }}>{this.state.isRefreshingPayment ? "Refreshing..." : "Refresh"}</button></Col>
												:
												null
											}
										</Row>
									</Card.Header>
									<ListGroup className="list-group-flush">
										<ListGroupItem>
											<Row>
												<Col xs={5}>Booking Number</Col>
												<Col xs={7}>{item && item.id && item.id.booking_num}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Service name</Col>
												<Col xs={7}>{item && item.id && item.id.servicepackageId && item.id.servicepackageId.name}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Service Start Date</Col>
												<Col xs={7}>{item && item.id && item.id.service_start_date && moment(item.id.service_start_date).format("DD MMM, YYYY")}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Next Payment Date</Col>
												<Col xs={7}>{item && item.id && item.id.next_payment_date && moment(item.id.next_payment_date).format("DD MMM, YYYY")}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Package Duration</Col>
												<Col xs={7}>{item && item.package_duration} Month(s)</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Booking Amount</Col>
												<Col xs={7}>{item && item.id && item.id.booking_amount}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Payment Amount</Col>
												<Col xs={7}>{item && item.id && item.id.payment_amount && parseFloat(item.id.payment_amount).toFixed(2)}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Discount Percentage</Col>
												<Col xs={7}>{item && item.discount_percentage}%</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Vehicle Type</Col>
												<Col xs={7}>{item && item.id && item.id.vehicleId && item.id.vehicleId.vehicle_type}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Vehicle Name</Col>
												<Col xs={7}>{item && item.id && item.id.vehicleId && item.id.vehicleId.name}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Model name</Col>
												<Col xs={7}>{item && item.id && item.id.vehicleId && item.id.vehicleId.model_id && item.id.vehicleId.model_id.name}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>Brand Name</Col>
												<Col xs={7}>{item && item.id && item.id.vehicleId && item.id.vehicleId.brand_id && item.id.vehicleId.brand_id.name}</Col>
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col xs={5}>License Number</Col>
												<Col xs={7}>{item && item.id && item.id.vehicleId && item.id.vehicleId.license_number}</Col>
											</Row>
										</ListGroupItem>
									</ListGroup>
								</Card>
							</div>
						))
							:
							null

						}
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}
