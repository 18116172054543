import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import moment from 'moment';
import callApi from '../../../../util/apiCaller';

class ListPayments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEditable: false,
			item: {},
			isRefreshingPayment: false
		};
	}

	componentDidMount() {
		if (this.props.item) {
			this.setState({
				item: this.props.item,
			});
		}
	}

	handleOnClickRefresh = (paymentId) => {
		this.setState({
			isRefreshingPayment: true
		})
		callApi(`admin/payment/${paymentId}/refresh-payment`, 'get')
			.then((res) => {
				if (res.status === 'Success') {
					this.props.fetchPaymentHistory();
					this.setState({
						isRefreshingPayment: false,
					})
				} else {
					this.setState({
						isRefreshingPayment: false,
					})
				}
			})
			.catch(err => {
				console.log("error", err);
				this.setState({
					isRefreshingPayment: false,
				})
			})
	}

	render() {
		return (
			<Card>
				<Card.Header>
					<Row>
						<Col xs={6}>{this.props.heading}</Col>
						{this.state.item && !this.state.item.isPaymentStepDone && !this.state.item.isPaid && this.state.item.payment_option != "upi" ?
							<Col xs={6} className='text-right'><button className='btn btn-primary' disabled={this.state.isRefreshingPayment} onClick={() => { this.handleOnClickRefresh(this.state.item._id) }}>{this.state.isRefreshingPayment ? "Refreshing..." : "Refresh"}</button></Col>
							:
							null
						}
					</Row>
				</Card.Header>
				<ListGroup className="list-group-flush">
					<ListGroupItem>
						<Row>
							<Col xs={5}>Status</Col>
							<Col xs={7}>{this.state.item && this.state.item.isPaymentStepDone && this.state.item.isPaid ? <p className='text-success'>Paid</p> : this.state.item.isPaymentStepDone && this.state.item.isPaymentFailed ? <p className='text-danger'>Failed</p> : <p className='text-warning'>Unpaid</p>}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>New Bookings</Col>
							<Col xs={7}>{this.state.item.servicebookingIds && this.state.item.servicebookingIds.length>0?<button className='btn btn-primary' onClick={()=>{this.props.openPaymentBookingsModal(this.state.item._id)}}>View</button>:"--NA--"}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Invoices</Col>
							<Col xs={7}>{this.state.item.customerinvoices && this.state.item.customerinvoices.length>0 && this.state.item.customerinvoices[0].invoices?<button className='btn btn-primary' onClick={()=>{this.props.openPaymentInvoicesModal(this.state.item._id)}}>View</button>:"--NA--"}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Razorpay Order Id</Col>
							<Col xs={7}>{this.state.item && this.state.item.razorpay_order_id ? this.state.item.razorpay_order_id : '--NA--'}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Razorpay Payment Id</Col>
							<Col xs={7}>{this.state.item && this.state.item.razorpay_payment_id ? this.state.item.razorpay_payment_id : ''}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Invoice Type</Col>
							<Col xs={7}>{this.state.item && this.state.item.invoice_type ? this.state.item.invoice_type : '--NA--'}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Payment Option</Col>
							<Col xs={7}>{this.state.item && this.state.item.payment_option ? this.state.item.payment_option : '--NA--'}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Bill date</Col>
							<Col xs={7}>{this.state.item && this.state.item.bill_date ? moment(this.state.item.bill_date).format("LLL") : '--NA--'}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Due date</Col>
							<Col xs={7}>{this.state.item && this.state.item.due_date ? moment(this.state.item.due_date).format("LLL") : '--NA--'}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Amount</Col>
							<Col xs={7}>₹ {this.state.item && this.state.item.amount ? this.state.item.amount : '--NA--'}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Payment gateway charges</Col>
							<Col xs={7}>{this.state.item && this.state.item.payment_portal_extra ? ((parseFloat(this.state.item.payment_portal_extra) - 1) * 10).toFixed(2) + "%" : ''}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Payment Date</Col>
							<Col xs={7}>{this.state.item && this.state.item.payment_date ? moment(this.state.item.payment_date).format("LLL") : ''}</Col>
						</Row>
					</ListGroupItem>
				</ListGroup>
			</Card>
		);
	}
}

export default ListPayments;
