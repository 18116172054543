import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import moment from 'moment';
import Loading from '../../../components/Loading';

class ModalTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEditable: false,
			item: {},
		};
	}

	componentDidMount() {
		
		
		/*if (this.props.item) {
			this.setState({
				item: this.props.item,
			});
			
		}*/
		
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!_.isEqual(prevProps.item, this.props.item)) {

			if (this.props.item) {
				this.setState({
					item: this.props.item,
				});
			}
			// this.setState({
			// 	items: this.props.item,
			// });
			
			console.log("Users model",this.props.item)
		}
	}

	toggleEdit = () => {
		this.setState({
			isEditable: !this.state.isEditable,
		});
	};

	handleChange = (keyName, keyValue) => {
		let item = this.state.item;
		item[keyName] = keyValue;
		this.setState({
			item: item,
		});
	};

	saveDetails = () => {
		let item = this.state.item;
		this.props.updateUser(this.props.item._id, item);
		this.toggleEdit()
	};

	render() {
		if (this.state.isEditable) {
			return (
				<Card>
					<Card.Header>
						<Row>
							<Col xs={6}> {this.props.heading}</Col>
							<Col xs={6}>
								<div className={'text-right'}>
									<Button disabled={this.props.isUpdatingUser} onClick={() => this.saveDetails()}>
										{this.props.isUpdatingUser ? 'saving' : 'save'}
									</Button>
									&nbsp;
									&nbsp;
									<Button onClick={() => this.toggleEdit()}>close</Button>
								</div>
							</Col>
						</Row>
					</Card.Header>
					<ListGroup className="list-group-flush">
						<ListGroupItem>
							<Row>
								<Col xs={5}>Name</Col>
								<Col xs={7}>
									<FormControl
										onChange={(e) => this.handleChange('customer_name', e.target.value)}
										value={this.props.item.customer_name}
									/>
								</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Email</Col>
								<Col xs={7}>
									<FormControl
										onChange={(e) => this.handleChange('email', e.target.value)}
										value={this.state.item.email}
									/>
								</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Phone</Col>
								<Col xs={7}>
									<FormControl
										onChange={(e) => this.handleChange('phone', e.target.value)}
										value={this.state.item.phone}
									/>
								</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>OTP</Col>
								<Col xs={7}>{this.state.item.otp}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Date of Joining</Col>
								<Col xs={7}>{moment(new Date(this.state.item.created_at)).format('DD MMM YYYY')}</Col>
							</Row>
						</ListGroupItem>
					</ListGroup>
				</Card>
			);
		} else {
			return (
				<Card>
					<Card.Header>
						<Row>
							<Col xs={6}>{this.props.heading}</Col>
							{/* <Col xs={6}>
								<div className="text-right">
									<Button onClick={() => this.toggleEdit()}>edit</Button>
								</div>
							</Col> */}
						</Row>
					</Card.Header>
					{
						this.props.isLoading ? <Loading /> :
							<ListGroup className="list-group-flush">
								<ListGroupItem>
									<Row>
										<Col xs={5}>Name</Col>
										<Col xs={7}>{this.state.item.name}</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Email</Col>
										<Col xs={7}>{this.state.item.email}</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Phone</Col>
										<Col xs={7}>{this.state.item.phone}</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>OTP</Col>
										<Col xs={7}>{this.state.item.otp}</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Date of Joining</Col>
										<Col xs={7}>{moment(new Date(this.state.item.created_at)).format('DD MMM YYYY')}</Col>
									</Row>
								</ListGroupItem>
							</ListGroup>
					}
				</Card>
			);
		}
	}
}

export default ModalTable;
