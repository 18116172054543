import React, {Component} from 'react';

import callApi from '../../../util/apiCaller';

const MODEL_NAME = 'servicebooking';

export default class ProfilePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            isLoggingIn: false
        };
    }

    componentDidMount() {
        // this.fetchItems(this.state.query, this.state.pageNum, this.state.pageSize);
    }

    handleChange = (keyName, keyValue) => {
        let user = this.state.user;
        user[keyName] = keyValue;
        this.setState({
            user: user
        });
    };

    handleLogin = () => {
        this.setState({
            isLoggingIn: true
        });
        let user = this.state.user;
        callApi('/auth/login', 'post', user).then(res => {
            if (res.status === 'Success') {
                this.setState({
                    isLoggingIn: false
                });
                window.location = '/';
            }
        });
    };

    render() {
        return (
            <div>
                <div className="home-btn d-none d-sm-block">
                    <a href="index.html"><i className="mdi mdi-home-variant h2 text-white"></i></a>
                </div>
                <div className="container-fluid p-0">
                    <div className="row no-gutters">
                        <div className="col-lg-4">
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-9">
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        {/*<a href="index.html" className="logo"><img src="assets/images/logo-dark.png" height={20} alt="logo" /></a>*/}
                                                    </div>
                                                    <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                                                    <p className="text-muted">Sign in to continue to Honc.</p>
                                                </div>
                                                <div className="p-2 mt-5">
                                                    <form className="form-horizontal">
                                                        <div className="form-group auth-form-group-custom mb-4">
                                                            <i className="ri-user-2-line auti-custom-input-icon" />
                                                            <label htmlFor="username">Username</label>
                                                            <input
                                                                onChange={(e) => this.handleChange('email', e.target.value)}
                                                                type="text" className="form-control" id="username" placeholder="Enter username" />
                                                        </div>
                                                        <div className="form-group auth-form-group-custom mb-4">
                                                            <i className="ri-lock-2-line auti-custom-input-icon" />
                                                            <label htmlFor="userpassword">Password</label>
                                                            <input
                                                                onChange={(e) => this.handleChange('password', e.target.value)}
                                                                type="password" className="form-control" id="userpassword" placeholder="Enter password" />
                                                        </div>
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                            <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>
                                                        </div>
                                                        <div className="mt-4 text-center">
                                                            <button
                                                                onClick={() => this.handleLogin()}
                                                                className="btn btn-primary w-md waves-effect waves-light" type="submit">Log In</button>
                                                        </div>
                                                        <div className="mt-4 text-center">
                                                            <a href="auth-recoverpw.html" className="text-muted"><i className="mdi mdi-lock mr-1" /> Forgot your password?</a>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="authentication-bg">
                                <div className="bg-overlay" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
