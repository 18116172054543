import React, { Component } from "react";
import { Button, Card, Modal, Form } from 'react-bootstrap';


class AddApartmentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: {
                name: "",
                apartmentId: ""
            }
        }
    }
    handleChange = (keyName, keyValue) => {
        let item = this.state.item;
        item[keyName] = keyValue;
        this.setState({
            item: item,
        });
    };
    submitHandler = (e) => {
        e.preventDefault();
        let block = this.state.item;
        console.log(block);
        this.props.addItem(block);
        this.props.close();
    };
    render() {
        return (
            <Modal
                dialogClassName={'modal-dialog modal-dialog-scrollable modal-md'}
                show={this.props.isVisible}
                onHide={this.props.close}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Block</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="d-flex justify-content-center">
                            <Form onSubmit={(e) => this.submitHandler(e)}>
                                <Form.Group controlId="BlockName">
                                    <Form.Label>Block Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Block Name"
                                        value={this.state.item.name}
                                        onChange={(e) => this.handleChange("name", e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="carModalName">
                                    <Form.Label>Apartment Name</Form.Label>
                                    <Form.Control
                                        as="select"
                                        onChange={(e) => this.handleChange("apartmentId", e.target.value)}
                                    >
                                        <option selected="true" disabled>--Select--</option>
                                        {this.props.apartments.map((item) => (
                                            <option value={item._id}>{item.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>

            </Modal >
        )
    }
}

export default AddApartmentModal;
