import React, { Component } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

class DateRange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
        }
    }

    handleChange = (value) => {
        this.setState({
            value
        })
        this.props.dateRangeValue(value);
    }

    render() {
        return (
            <div>
                <DateRangePicker
                    rangeDivider='(from) -> (to)'
                    dayPlaceholder=' '
                    monthPlaceholder=' '
                    yearPlaceholder=' '
                    onChange={(e) => {
                        if (e != null) {
                            this.handleChange(e)
                        } else {
                            this.setState({
                                value: ''
                            })
                            this.handleChange(['', ''])
                        }
                    }}
                    value={this.state.value}
                />
            </div>
        );
    }
}


export default DateRange;




