import React, { Component } from 'react';

class ListTableHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSidebarEnabled: true,
        };
    }

    toggleSidebar = () => {
        this.setState({
            isSidebarEnabled: !this.state.isSidebarEnabled,
        });
        if (this.state.isSidebarEnabled) {
            document.body.className = 'vertical-collpsed';
        } else {
            document.body.className = 'sidebar-enable';
        }
    };

    render() {
        return (
            <thead className="thead-light">
            <tr role="row">
                <th>#</th>
                <th>Blocks</th>
                <th>Apartments</th>
                <th>Actions</th>
            </tr>
            </thead>
        );
    }
}

export default ListTableHeader;