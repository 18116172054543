import React, { Component } from 'react';

const Dropdown = (props) => {

  return (
    <div className="dropdown">
      <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {props.label} <i className="mdi mdi-chevron-down" />
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a className="dropdown-item" >Action</a>
        <a className="dropdown-item" >Another action</a>
        <a className="dropdown-item" >Something else here</a>
      </div>
    </div>

  );

}

export default Dropdown;