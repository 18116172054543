import React, { Component } from 'react'
import TableUser from './component/TableUser';
import ListTable from './component/ListTable';
import callApi from '../../util/apiCaller';
import _ from 'lodash';
import {Button} from "react-bootstrap";
import downloadApi from "../../util/apiDownloader";
import moment from "moment";

import Select from 'react-select';

export default class Customer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headings:['S.No.','Name', 'Email', 'Phone', 'OTP', 'Type' ],
            customers: [],
            pageNum: 1,
            pageSize: 100,
            count:0,
            communities: [],
			agencies: [],
			filter_communityIds: [],
			filter_agencyIds: [],
			filter_vehicle_type: [],
			filter_date_range: {
				start: '',
				end: '',
			},
        }
        this.debounceSearch = _.debounce(this.handleSearch, 500);
    }

    fetchCommunities = () => {
		callApi(`apartments/list`, 'post', {
			pageNum: 1,
			pageSize: 100,
		}).then((res) => {
			if (res.status === 'Success') {
				this.setState({
					communities: res.data["apartments"]
				})
			}
			else {
				console.log("error")
			}
		})
	};

	fetchAgencies = () => {
		callApi(`agencys/list`, 'post', {
			pageNum: 1,
			pageSize: 100,
		}).then((res) => {
			if (res.status === 'Success') {

				this.setState({
					agencies: res.data["agencys"]
				})
			}
			else {
				console.log("error")
			}
		})
	};
    componentDidMount() {
        let filters = this.getFilters();
        this.fetchUsers(filters);
        this.fetchCommunities();
        this.fetchAgencies();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevState.pageNum, this.state.pageNum)) {
            this.fetchUsers();
        }
    }

    onPageChange = (number) => {
		let filters = this.getFilters();
        this.setState({
            pageNum:number,
        }, () => this.fetchUsers(filters))
    }

    fetchUsers = (filters) => {
        //let filters = this.getFilters();
        callApi(`admin/masterusers/list`, 'post',filters).then((res) => {
            if (res.status === "Success") {
				console.log(res.data.users);
                this.setState({
                    customers: res.data.users.users.map(item => {
                        let type = 'Cleaner';
                        if(item.isCustomer || item.isCustomerAlreadyServiced){
                         type = 'Customer';
                        }else if(item.isOwner){
                            type = 'Owner';
                        }else if(item.isSupervisor){
                            type = 'Supervisor';
                        }
                        item['type'] = type;
                        return item;
                    }),
                    count: res.data.users.userCount,
                },() => console.log(this.state))
            }
            else {
                console.log("Error :", res);
            }
        })
    }
    handleSearch = () => {
		let filters = this.getFilters();
		this.fetchUsers(filters);
	};
    getFilters = () => {
        let input = {};
		input.query = this.state.query;
		input.selected_filter = this.state.selectedTab;
		input.pageNum = this.state.pageNum;
		input.pageSize = this.state.pageSize;
		if (this.state.filter_communityIds && this.state.filter_communityIds.length > 0) {
			input.filter_communityIds = this.state.filter_communityIds.map(item => (item.value));
		}
		if (this.state.filter_agencyIds && this.state.filter_agencyIds.length > 0) {
			input.filter_agencyIds = this.state.filter_agencyIds.map(item => (item.value));
		}
		return input;
    };

    downloadData = () => {
        console.log('Download pressed');
        this.setState({
            isDownloadingData: true,
        });
        let filters = this.getFilters();
        downloadApi(`Payments-${moment().format('DD-MM-YYYY-HH:mm')}.csv`, `/admin/payments/dump`, 'POST', filters).then(
            (blob) => {
                this.setState({
                    isDownloadingData: false,
                });
                console.log(JSON.stringify(blob));
            }
        );
    };

    handleChange = (keyName, keyValue) => {
		let item = {};
		item[keyName] = keyValue;
		this.setState(item);
    };
    
    changePageSize = (size) => {
		this.setState(
			{
				pageSize: size,
				pageNum: 1,
			},
			() => {
				let filters = this.getFilters();
				this.fetchUsers(filters);
			}
		);
	};

	changePageNum = (num) => {
		this.setState(
			{
				pageNum: num,
			},
			() => {
				let filters = this.getFilters();
				this.fetchUsers(filters);
			}
		);
    };
    handleQuery = (val) => {
		this.setState(
			{
				query: val,
			},
			() => {
				this.debounceSearch();
			}
		);
	};

    render() {
        let communities = this.state.communities.map(item => ({ value: item._id, label: item.name }));
		let agencies = this.state.agencies.map(item => ({ value: item._id, label: item.name }));
        return (
            <div className="container-fluid">
                <div className="row">
					<div className="col-12">
						<div className="page-title-box d-flex align-items-center justify-content-between">
							<h4 className="mb-0">Honc Cust Master Data</h4>
							<div className="page-title-right">
								<Button className="mr-3" type="button" data-toggle="collapse" data-target="#filter_modal" aria-expanded="false" aria-controls="multiCollapseExample2"><i className="fa fa-filter"></i></Button>
								{/* <Button onClick={() => this.addBookingHandler()}>
									<i className="fa fa-plus"></i>
									Add
								</Button> */}
								&nbsp;
								&nbsp;
								<Button disabled={this.state.isDownloadingData} onClick={() => this.downloadData()}>
									{this.state.isDownloadingData ? 'Downloading' : 'Download Data'}
								</Button>
							</div>
						</div>
					</div>
				</div>
                <div className="collapse multi-collapse" id="filter_modal">
					<div className="card card-body">
						<h5>Apply Filters</h5>
						<div className="container mt-4">
							<div className="row">
								
								<div className="col-md-6">
									<label>Select Community</label>
									<Select
										closeMenuOnSelect={false}
										onChange={(value) => this.handleChange("filter_communityIds", value)}
										isMulti
										options={communities}
									/>
								</div>
								<div className="col-md-6">
									<label>Select Vendor</label>
									<Select
										closeMenuOnSelect={false}
										onChange={(value) => this.handleChange("filter_agencyIds", value)}
										isMulti
										options={agencies}
									/>
								</div>
								
							</div>
						</div>
						<Button className="w-25 mx-auto mt-3" onClick={() => this.handleSearch()}>
							Apply
                        </Button>
					</div>
				</div>
                <div className="row">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-body  pt-0">
								
                                &nbsp;
								{
                                    <ListTable
										changePageNum={this.changePageNum}
										handleQuery={this.handleQuery}
										changePageSize={this.changePageSize}
										query={this.state.query}
										items={this.state.customers}
										pageSize={this.state.pageSize}
										totalCount={this.state.count}
										pageNum={this.state.pageNum}
									/>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            
               
            
        )
    }
}
