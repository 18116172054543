import React, { Component } from 'react';
import moment from 'moment';
import { Button, Card, FormControl, Modal, Nav, Tab, TabContainer, TabContent, Tabs } from 'react-bootstrap';
import callApi from '../../../../util/apiCaller';
import UserModalTable from './UserModalTable';
import CarDetailsModal from './CarDetailsModal';
import BookingDetailsModal from './BookingDetailsModal';
import BikeDetailsModal from './BikeDetailsModal';
import PaymentDetailsModal from './PaymentDetailsModal';
import ScheduleDetailsModal from './ScheduleDetailsModal';
import ModalTable from './ModalTable';

class UserDetailsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSidebarEnabled: true,
			selectedItem: {},
			isUpdatingUser: false,
			isAddNewServiceVisible: false,
			newServicebooking: {},
			newServicepackage: {},
			newServicerequests: {},
			selectedAddon: '',
			selectedTimeSlot: '',
			timeslots: [],
			isAddingNewService: false,
			isLoading: false,
		};
	}

	componentDidMount() {
		if (this.props.selectedItemId) {
			this.fetchItem(this.props.selectedItemId);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.selectedItemId !== this.props.selectedItemId) {
			if (this.props.selectedItemId) {
				this.fetchItem(this.props.selectedItemId);
			}
		}
	}

	fetchItem = (id) => {
		console.log('testing1', id);
		this.setState(
			{
				isLoading: true,
			},
			() => {
				callApi(`admin/customer/${id}/details`, 'get').then((res) => {
					if (res.status === 'Success') {
						this.setState({
							isLoading: false,
							user: res.data.user,
							servicebookings: res.data.servicebookings ? res.data.servicebookings : [],
							cars: res.data.cars ? res.data.cars : [],
							bikes: res.data.bikes ? res.data.bikes : [],
							invoices: res.data.invoices ? res.data.invoices : [],
							servicerequests: res.data.servicerequests ? res.data.servicerequests : [],
						});
					}
				});
			}
		);
	};

	toggleSidebar = () => {
		this.setState({
			isSidebarEnabled: !this.state.isSidebarEnabled,
		});
		if (this.state.isSidebarEnabled) {
			document.body.className = 'vertical-collpsed';
		} else {
			document.body.className = 'sidebar-enable';
		}
	};

	updateUser = (id, user) => {
		this.setState({
			isUpdatingUser: true,
		});
		callApi(`profile/user/${id}/update`, 'post', {
			user: user,
		}).then((res) => {
			if (res.status === 'Success') {
				this.setState({
					user: res.data.user,
					isUpdatingUser: false,
				});
			} else {
				this.setState({
					isUpdatingUser: false,
				});
			}
		});
	};

	updateServicebooking = (id, booking) => {
		let servicebookings = this.state.servicebookings.map((item) => {
			if (item._id === id) {
				item.isUpdating = true;
			}
			return item;
		});
		this.setState(
			{
				servicebookings: servicebookings,
			},
			() => {
				callApi(`customer/${booking.customerId}/servicebooking/${id}/update`, 'post', {
					servicebooking: booking,
				}).then((res) => {
					if (res.status === 'Success') {
						let servicebookings = this.state.servicebookings.map((item) => {
							if (item._id === id) {
								return res.data.servicebooking;
							} else {
								return item;
							}
						});
						this.setState({
							servicebookings: servicebookings,
						});
					} else {
						let servicebookings = this.state.servicebookings.map((item) => {
							item.isUpdating = false;
							return item;
						});
						this.setState({
							servicebookings: servicebookings,
						});
					}
				});
			}
		);
	};

	updatePayment = (id, payment) => {
		let invoices = this.state.invoices.map((item) => {
			if (item._id === id) {
				item.isUpdating = true;
			}
			return item;
		});
		console.log("payment", payment);
		this.setState(
			{
				invoices: invoices,
			},
			() => {
				callApi(`customerinvoices/${id}/update`, 'post', {
					customerinvoice: payment,
				}).then((res) => {
					if (res.status === 'Success') {
						let invoices = this.state.invoices.map((item) => {
							if (item._id === id) {
								item.isUpdating = false;
								return res.data.customerinvoice;
							} else {
								return item;
							}
						});
						this.setState({
							invoices: invoices,
						});
					} else {
						let invoices = this.state.invoices.map((item) => {
							item.isUpdating = false;
							return item;
						});
						this.setState({
							invoices: invoices,
						});
					}
				});
			}
		);
	};

	updateServiceRequest = (id, serviceRequest) => {
		let servicerequests = this.state.servicerequests.map((item) => {
			if (item._id === id) {
				item.isUpdating = true;
			}
			return item;
		});
		console.log("ser", serviceRequest);
		this.setState(
			{
				servicerequests: servicerequests,
			},
			() => {
				callApi(`servicerequests/${id}/update`, 'post', {
					servicerequest: serviceRequest,
				}).then((res) => {
					if (res.status === 'Success') {
						let servicerequests = this.state.servicerequests.map((item) => {
							if (item._id === id) {
								item.isUpdating = false;
								return res.data.servicerequest;
							} else {
								return item;
							}
						});
						this.setState({
							servicerequests: servicerequests,
						});
					} else {
						let servicerequests = this.state.servicerequests.map((item) => {
							item.isUpdating = false;
							return item;
						});
						this.setState({
							servicerequests: servicerequests,
						});
					}
				});
			}
		);
	};

	updateCarDetails = (id, car) => {
		let cars = this.state.cars.map((item) => {
			if (item._id === id) {
				item.isUpdating = true;
			}
			return item;
		});
		this.setState(
			{
				cars: cars,
			},
			() => {
				callApi(`cars/${id}/update`, 'post', {
					car,
				}).then((res) => {
					if (res.status === 'Success') {
						let cars = this.state.cars.map((item) => {
							if (item._id === id) {
								return res.data.car;
							} else {
								console.log('Testing3');
								return item;
							}
						});
						this.setState({
							cars: cars,
						});
					} else {
						let cars = this.state.cars.map((item) => {
							item.isUpdating = false;
							return item;
						});
						this.setState({
							cars: cars,
						});
					}
				});
			}
		);
	};

	updateBikeDetails = (id, bike) => {
		let bikes = this.state.bikes.map((item) => {
			if (item._id === id) {
				item.isUpdating = true;
			}
			return item;
		});
		this.setState(
			{
				bikes: bikes,
			},
			() => {
				callApi(`bikes/${id}/update`, 'post', {
					bike,
				}).then((res) => {
					if (res.status === 'Success') {
						let bikes = this.state.bikes.map((item) => {
							if (item._id === id) {
								return res.data.bike;
							} else {
								return item;
							}
						});
						this.setState({
							bikes: bikes,
						});
					} else {
						let bikes = this.state.bikes.map((item) => {
							item.isUpdating = false;
							return item;
						});
						this.setState({
							bikes: bikes,
						});
					}
				});
			}
		);
	};

	/*
	* if (servicebooking) {
		if (servicebooking.block_name) {
			car.block_name = servicebooking.block_name;
		}
		if (servicebooking.block_id) {
			car.block_id = servicebooking.block_id;
		}
		if (servicebooking.parking_block) {
			car.parking_block = servicebooking.parking_block;
		}
		if (servicebooking.time_slot_name) {
			car.time_slot_name = servicebooking.time_slot_name;
		}
		if (servicebooking.next_payment_date) {
			car.next_payment_date = servicebooking.next_payment_date;
		}
		if (servicebooking.agencyId) {
			car.agencyId = servicebooking.agencyId;
		}
		if (servicebooking.agency_name) {
			car.agency_name = servicebooking.agency_name;
		}
		if (servicebooking.customer_name) {
			car.customer_name = servicebooking.customer_name;
		}
		if (servicebooking.customer_email) {
			car.customer_email = servicebooking.customer_email;
		}
		if (servicebooking.customerId) {
			car.customerId = servicebooking.customerId;
		}
		if (servicebooking.customer_phone) {
			car.customer_phone = servicebooking.customer_phone;
		}
		car.isPaymentSuccess = !!servicebooking.isPaymentSuccess;
	}
	* */

	addNewService = () => {
		this.setState({
			isAddNewServiceVisible: !this.state.isAddNewServiceVisible,
		});
	};

	handleServicebookingChange = (e) => {
		let id = e.target.value;
		console.log(id);
		let filterItem = this.state.servicebookings.filter((item) => item._id === id);
		//customer/:userid/servicebooking/:servicebookingId/details
		callApi(`customer/${filterItem[0].customerId}/servicebooking/${id}/details`, 'get').then((res) => {
			console.log(res);
			if (res.status === 'Success') {
				this.setState({
					newServicebooking: res.data.servicebooking,
					newServicepackage: res.data.servicepackage,
					newServicerequests: res.data.servicerequests,
				});
			}
		});
		callApi(`customer/agency/${filterItem[0].agencyId}/service-addon-list`, 'post', {
			start: moment().startOf('day').toISOString(),
			end: moment().add(30, 'days').startOf('day').toISOString(),
		}).then((res) => {
			console.log(res);
			if (res.status === 'Success') {
				this.setState({
					timeslots: res.data.timeslots,
				});
			}
		});
	};

	handleServiceAddon = (val) => {
		this.setState({
			selectedAddon: val,
		});
	};

	handleServiceTimeslot = (val) => {
		this.setState({
			selectedTimeSlot: val,
		});
	};

	submitRequestForService = () => {
		//customer/servicebooking/:servicebookingId/new-addon-service
		this.setState({
			isAddingNewService: true,
		});
		let servicebookingId = this.state.newServicebooking._id;
		let servicerequest = {};
		servicerequest.servicebookingId = servicebookingId;
		servicerequest.agencyId = this.state.newServicebooking.agencyId;
		if (this.state.newServicebooking.vehicle_type === 'car') {
			servicerequest.carId = this.state.newServicebooking.carId;
		}
		if (this.state.newServicebooking.vehicle_type === 'bike') {
			servicerequest.bikeId = this.state.newServicebooking.bikeId;
		}
		if (this.state.newServicebooking.isTempAssigned) {
			servicerequest.assigned_cleaner_id = this.state.newServicebooking.temp_assigned_cleaner_id;
			servicerequest.assigned_cleaner_name = this.state.newServicebooking.temp_assigned_cleaner_name;
		} else {
			servicerequest.assigned_cleaner_id = this.state.newServicebooking.assigned_cleaner_id;
			servicerequest.assigned_cleaner_name = this.state.newServicebooking.assigned_cleaner_name;
		}
		servicerequest.userId = this.state.newServicebooking.customerId;
		servicerequest.service_name = this.state.selectedAddon;
		let filterTimeSlots = this.state.timeslots.filter(
			(item) => item.time_slot_name === this.state.selectedTimeSlot
		);
		servicerequest.time_slot_name = filterTimeSlots[0].time_slot_name;
		servicerequest.time_slot_slug = filterTimeSlots[0].time_slot_slug;
		servicerequest.time_slot_start = filterTimeSlots[0].time_slot_start;
		servicerequest.time_slot_end = filterTimeSlots[0].time_slot_end;
		servicerequest.customer_name = this.state.newServicebooking.customer_name;
		servicerequest.isCompleted = false;
		servicerequest.status = 'ongoing';
		servicerequest.booking_date = new Date();
		servicerequest.servicing_date = new Date();
		callApi(`customer/servicebooking/${servicebookingId}/new-addon-service`, 'post', {
			servicerequest: servicerequest,
		}).then((res) => {
			console.log(res);
			this.setState({
				isAddingNewService: false,
			});
		});
	};
	render() {
		console.log('User', this.state.user);
		console.log('ServiceBookings', this.state.servicebookings);
		console.log('Vehicles', this.state.cars);
		console.log('Bikes', this.state.bikes);
		console.log('Invoices', this.state.invoices);
		console.log('ServiceReq', this.state.servicerequests);
		return (
			<Modal
				dialogClassName={'modal-dialog modal-dialog-scrollable modal-lg'}
				show={this.props.isVisible}
				onHide={this.props.close}
			>
				<Modal.Header closeButton>
					<Modal.Title>{this.state.user && this.state.user.name ? this.state.user.name : ''}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div style={{ minHeight: '230px' }}>
						<Card>
							<TabContainer defaultActiveKey={'user-details'}>
								<Card.Header>
									<Nav variant="pills">
										<Nav.Item>
											<Nav.Link eventKey="user-details">User Details</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="booking-details">Booking Details</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="vehicle-details">Vehicles</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="payment-details">Payments</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="schedule-requests">Shedule Request</Nav.Link>
										</Nav.Item>
									</Nav>
								</Card.Header>
								<Card.Body>
									<TabContent>
										<Tab.Pane eventKey="user-details" title="Details">
											<UserModalTable
												heading="User"
												updateUser={this.updateUser}
												isUpdatingUser={this.state.isUpdatingUser}
												item={this.state.user ? this.state.user : null}
												isLoading={this.state.isLoading}
											/>
										</Tab.Pane>
										<Tab.Pane eventKey="booking-details" title="ServiceBookings">
											{this.state.servicebookings &&
												this.state.servicebookings.map((item) => (
													<BookingDetailsModal
														heading="Servicebooking"
														updateServicebooking={this.updateServicebooking}
														item={item}
													/>
												))}
										</Tab.Pane>
										<Tab.Pane eventKey="vehicle-details" title="Vehicles">
											{this.state.cars &&
												this.state.cars.map((item) => (
													<CarDetailsModal
														heading="Car"
														updateCarDetails={this.updateCarDetails}
														item={item}
													/>
												))}

											{this.state.bikes &&
												this.state.bikes.map((item) => (
													<BikeDetailsModal
														heading="Bike"
														updateBikeDetails={this.updateBikeDetails}
														item={item}
													/>
												))}
										</Tab.Pane>
										<Tab.Pane eventKey="payment-details" title="Payments">
											{this.state.invoices &&
												this.state.invoices.map((item) => (
													<PaymentDetailsModal
														heading="Invoice"
														item={item}
														updatePayment={this.updatePayment}
													/>
												))}
										</Tab.Pane>
										<Tab.Pane eventKey="schedule-requests" title="Service Requests">
											{/* <Button onClick={() => this.addNewService()}>
												{!this.state.isAddNewServiceVisible
													? 'Add New Service Request'
													: 'Close'}
											</Button>
											{this.state.isAddNewServiceVisible ? (
												<div>
													<FormControl
														as={'select'}
														onChange={(e) => this.handleServicebookingChange(e)}
													>
														<option value=""></option>
														{this.state.servicebookings &&
															this.state.servicebookings.map((item) => (
																<option value={item._id}>{item.service_name}</option>
															))}
													</FormControl>
													{this.state.newServicepackage &&
														this.state.newServicepackage.addons ? (
														<FormControl
															as={'select'}
															value={this.state.selectedAddon}
															onChange={(e) => this.handleServiceAddon(e.target.value)}
														>
															<option value=""></option>
															{this.state.newServicepackage.addons.map((item) => (
																<option value={item.name}>{item.name}</option>
															))}
														</FormControl>
													) : null}
													{this.state.timeslots && this.state.timeslots.length > 0 ? (
														<FormControl
															as={'select'}
															value={this.state.selectedTimeSlot}
															onChange={(e) => this.handleServiceTimeslot(e.target.value)}
														>
															<option value=""></option>
															{this.state.timeslots.map((item) => (
																<option value={item.time_slot_name}>
																	{item.time_slot_name}
																</option>
															))}
														</FormControl>
													) : null}
													<Button
														disabled={this.state.isAddingNewService}
														onClick={() => this.submitRequestForService()}
													>
														Submit
													</Button>
												</div>
											) : null} */}
											{this.state.servicerequests &&
												this.state.servicerequests.map((item) => (
													<ScheduleDetailsModal
														heading="Schedule Details"
														item={item}
														updateServiceRequest={this.updateServiceRequest}
													/>
												))}
										</Tab.Pane>
									</TabContent>
								</Card.Body>
							</TabContainer>
						</Card>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={this.props.close}>
						Close
					</Button>
					<Button variant="primary">Save Changes</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default UserDetailsModal;
