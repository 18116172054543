import React, { Component } from "react";
import { Button, Card, Modal, Form } from 'react-bootstrap';
import moment from 'moment';

class AddServiceBookingModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            item: {
                orderId: "",
                date: moment(),
                billingName: "",
                total:"",
                paymentStatus:""
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
    }
    handleChange = (keyName, keyValue) => {
        let item = this.state.item;
        item[keyName] = keyValue;
        this.setState({
            item: item,
            date:'',
        });
    };
    submitHandler = () => {
        let orderId = this.state.item;
        let date = this.state.item;
        let total = this.state.item;
        let billingName = this.state.item;
        let paymentStatus = this.state.item;
        this.props.addItem(orderId);
        this.props.addItem(date);
        this.props.addItem(total);
        this.props.addItem(billingName);
        this.props.addItem(paymentStatus);
        this.props.close();
    };
    render() {
        return (
            <Modal
                dialogClassName={'modal-dialog modal-dialog-scrollable modal-md'}
                show={this.props.isVisible}
                onHide={this.props.close}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add a New Service Booking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="d-flex justify-content-center">
                            <Form onSubmit={() => this.submitHandler()}>
                                <Form.Group controlId="orderId">
                                    <Form.Label>Order ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Order ID here"
                                        value={this.state.item.orderId}
                                        onChange={(e) => this.handleChange("orderId", e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="date">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Enter Date here"
                                        value={this.state.item.date}
                                        onChange={(e) => this.handleChange("date", e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="billingName">
                                    <Form.Label>Billing Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Billing Name here"
                                        value={this.state.item.billingName}
                                        onChange={(e) => this.handleChange("billingName", e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="totalAmount">
                                    <Form.Label>Total Billing Amount(INR)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Amount here"
                                        value={this.state.item.total}
                                        onChange={(e) => this.handleChange("total", e.target.value)}
                                    />
                                </Form.Group>

                                {/*<Form.Group controlId="paymentStatus">*/}
                                {/*    < Form.Label >Payment Status</Form.Label>*/}
                                {/*    <Form.Control*/}
                                {/*        as="select"*/}
                                {/*        onChange={(e) => this.handleChange("paymentStatus", e.target.value)}*/}
                                {/*    >*/}
                                {/*        <option selected="true" disabled>--Select--</option>*/}
                                {/*        {this.props.item.isPaymentSuccess.map((item) => (*/}
                                {/*            <option value={item.value}>{item.label}</option>*/}
                                {/*        ))}*/}
                                {/*    </Form.Control>*/}
                                {/*</Form.Group>*/}
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        )
    }
}

export default AddServiceBookingModal;
