import React, { Component } from 'react';
import moment from 'moment';
import { FormControl } from 'react-bootstrap';
import _ from 'lodash';

class ListTableRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditModeVisible: false,
            saved: {},
            isActive: ""
        };
    }

    openEditMode = (item) => {
        this.setState({
            isEditModeVisible: true,
            saved: _.clone(item),
        });
    };

    closeEditMode = () => {
        this.setState({
            isEditModeVisible: false,
            saved: {},
        });
    };

    handleChange = (keyName, keyValue) => {
        let item = this.state.saved;
        item[keyName] = keyValue;
        this.setState({
            saved: item,
        });
    };

    saveData = () => {
        let item = this.state.saved;
        this.props.updateItem(this.props.item._id, item);
        this.closeEditMode();
    };

    deleteHandler = (item) => {
        console.log("item_to_br_deleted", item._id);
        this.props.deleteItem(item._id);
    }

    render() {
        if (this.state.isEditModeVisible) {
            return (
                <tr role="row" className="odd">
                    <td>{this.props.index + 1 + (this.props.page - 1) * this.props.size}</td>
                    <td>
                        <FormControl
                            onChange={(e) => this.handleChange('name', e.target.value)}
                            value={this.state.saved ? this.state.saved.name : ''}
                        />
                    </td>
                    <td>
                        <FormControl
                            as="select"
                            onChange={(e) => this.handleChange("isActive", e.target.value)}
                        >
                            {this.props.item.isActive ? <option selected="true" value="true">Active</option>
                                : <option value="true">Active</option>
                            }
                            {this.props.item.isActive ? <option value="false">In Active</option>
                                : <option selected="true" value="false">In Active</option>
                            }
                        </FormControl></td>
                    <td>
                        <button
                            disabled={this.props.item.isUpdating}
                            onClick={() => this.saveData()}
                            className="btn btn-light btn-rounded"
                        >
                            {this.props.item.isUpdating ?
                                "Saving" : "Save"}
                        </button>
                        <button
                            disabled={this.props.item.isUpdating}
                            onClick={() => this.closeEditMode()}
                            className="btn btn-light btn-rounded ml-3"
                        >
                            {'  '}
							Close{'  '}
                        </button>
                    </td>
                </tr>
            );
        } else {
            return (
                <tr role="row" className="odd">
                    <td>{this.props.index + 1 + (this.props.page - 1) * this.props.size}</td>
                    <td>{this.props.item.name}</td>
                    <td>{this.props.item.isActive ? "Active" : "In Active"}</td>
                    <td>
                        <button
                            onClick={() => this.openEditMode(this.props.item)}
                            className="btn btn-light btn-rounded"
                        >
                            Edit
                        </button>
                        <button
                            disabled={this.props.item.isDeletingItem}
                            onClick={() => this.deleteHandler(this.props.item)}
                            className="btn btn-danger btn-rounded ml-3"
                        >
                            Delete
                        </button>
                    </td>
                </tr>
            );
        }
    }
}

export default ListTableRow;
