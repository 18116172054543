import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import callApi from '../../../util/apiCaller';

class VendorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditable: false,
            item: {},
            isLoading: true,
            isUpdatingCommunity: false,
            updateObject: {},
            listItems: {},
            
        };
    }

    componentDidMount() {
        console.log("selected Id Ad On", this.props.apartmentId);
        if (this.props.apartmentId) {
            this.fetchPackages(this.props.apartmentId);
        }
    }

    fetchPackages = (id) => {
        this.setState(
            {
                isLoading: true,
            },
            () => {
                callApi(`servicepackages/${id}`, 'get').then((res) => {
                    console.log(res);
                    if (res.status === 'Success') {
                        console.log("response", res.data);
                        this.setState({
                            isLoading: false,
                            servicepackages: res.data
                        });
                    }
                });
            }
        );
    };

    toggleEdit = () => {
        this.setState({
            isEditable: !this.state.isEditable,
        });
    };

    handleChange = (KeyName,keyValue,i,id) => {
        let item = this.state.servicepackages;
        item.servicepackage['addons'][i][KeyName] = keyValue;
        this.setState({
            updateObject: item,
        });
        console.log('adons');
        console.log(item);
    };

    saveDetails = () => {
        let item = this.state.servicepackages.servicepackage;
        console.log('adons');
        console.log(item);
        this.updateCommunity(this.state.servicepackages.servicepackage._id, item);
    };

    updateCommunity = (id, servicepackage) => {
        this.setState({
            isUpdatingCommunity: true,
        });
        console.log('adonsupdate');
        console.log(servicepackage);
        callApi(`servicepackages/${id}/update`, 'post', {
            servicepackage,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    isUpdatingCommunity: false,
                });
                this.toggleEdit();
                this.fetchPackages(id);
            } else {
                this.setState({
                    isUpdatingCommunity: false,
                });
                this.toggleEdit();
            }
        });
    };

    handleAddAdOnService = () => {
        let item = this.state.servicepackages;
        item.servicepackage['addons'] = this.state.servicepackages.servicepackage.addons.concat([{ name:"",quantity:"" }])
        console.log(item);
        this.setState({
            servicepackages: item
        });
      };
      handleRemoveAdOn = idx => () => {
        let item = this.state.servicepackages;
        console.log(item);
        item.servicepackage['addons'] = this.state.servicepackages.servicepackage.addons.filter((s, sidx) => idx !== sidx);
       
        this.setState({
            servicepackages: item
        });
        console.log(this.state.servicepackages.servicepackage.addons);
      };  
        
    render() {
        //let locations = this.state.servicepackages.servicepackage.products_used.map(item => ({ value: item._id, label: item.name }));

        if (this.state.isEditable) {
            return (
                <Card>
                    <Card.Header>
                        <Row>
                            <Col xs={6}> {this.props.heading}</Col>
                            <Col xs={6}>
                                <div className={'text-right'}>
                                    <button
                                        type="button"
                                        onClick={this.handleAddAdOnService}
                                        className="btn btn-primary">
                                        Add New
                                    </button> &nbsp;
        							&nbsp; /  &nbsp;
        							&nbsp;
                                    <Button disabled={this.state.isUpdatingCommunity} onClick={() => this.saveDetails()}>
                                        {this.state.isUpdatingCommunity ? 'saving' : 'save'}
                                    </Button>
        							&nbsp;
        							&nbsp;
        							<Button onClick={() => this.toggleEdit()}>close</Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>
                    <ListGroup className="list-group-flush">

                    { this.state.listItems = this.state.servicepackages.servicepackage.addons.map((adon,i) =>
                        <ListGroupItem>
                            <Row>
                                <Col xs={6}><FormControl
                                        key={adon._id}
                                        onChange={(e) => this.handleChange('name',e.target.value,i,adon._id)}
                                        value={adon.name}
                                    /></Col>
                                <Col xs={4}><FormControl
                                        key={adon._id}
                                        onChange={(e) => this.handleChange('quantity',e.target.value,i,adon._id)}
                                        value={adon.quantity}
                                    /></Col>
                                <Col xs={2}> &nbsp;
                                        &nbsp;
                                        <button
                                        type="button"
                                        onClick={this.handleRemoveAdOn(i)}
                                        className="btn btn-secondary"
                                        style={{height: "90%"}}
                                        >-</button></Col>    
                            </Row>
                        </ListGroupItem>
                     )}  

                        
                    </ListGroup> 
                </Card>
            );
        } else {
            if (!this.state.isLoading) {
                return (
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={6}>{this.props.heading}</Col>
                                <Col xs={6}>
                                    <div className="text-right">
                                        <Button onClick={() => this.toggleEdit()}>edit</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        <ListGroup className="list-group-flush">
                        
                        { this.state.listItems = this.state.servicepackages.servicepackage.addons.map((adon) =>
                    <ListGroupItem>
                            <Row>
                                <Col xs={6}>{adon.name}</Col>
                                <Col xs={6}>{adon.quantity}</Col>
                            </Row>
                        </ListGroupItem>
                    )}
                       
                            
                        </ListGroup>
                    </Card>
                );
            }
            else {
                return (
                    <h1>Loading...</h1>
                )
            }
        }
    }
}

export default VendorDetails;
