import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row, Modal } from 'react-bootstrap';

import moment from 'moment';
import callApi from '../../../../util/apiCaller';

class EditInvoicesModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEditInvoiceEnable: false,
			selectedInvoiceId: "",
			isUpdatingInvoice: false,
			notes: "",
			updateAmount: "",
			isDeleteInvoiceEnable: false
		};
	}

	componentDidMount() {
	}

	openEditInvoice = (invoiceId, amount) => {
		this.setState({
			selectedInvoiceId: invoiceId,
			isEditInvoiceEnable: true,
			updateAmount: amount,
			notes:""
		})
	}

	closeEditInvoice = () => {
		this.setState({
			isEditInvoiceEnable: false
		})
	}

	updateInvoice = (updateObject,isFrom) => {
		let data = {
			"adminUserId": this.props.userDetails && this.props.userDetails._id,
			"adminEmail": this.props.userDetails && this.props.userDetails.email,
			"adminNotes": isFrom + "-" + this.state.notes,
			"update": updateObject
		};
		this.setState({
			isUpdatingInvoice: true,
		});
		callApi(`admin/invoice/${this.state.selectedInvoiceId}/update`, 'post', data)
			.then((res) => {
				if (res.status === 'Success') {
					alert("Invoice Updated.");
					this.props.fetchGarageBookings(true);
					this.closeEditInvoice();
					this.closeDeleteInvoice();
				}
				this.setState({
					isUpdatingInvoice: false,
				});
			})
			.catch(err => {
				this.setState({
					isUpdatingInvoice: false,
				});
			})
	}

	onSubmitEditRequest = () => {
		let updateObject = {
			"payment_amount": this.state.updateAmount
		};
		this.updateInvoice(updateObject,"edited");
	}

	onSubmitDeleteRequest = () => {
		let updateObject = {
			"isActive": false
		};
		this.updateInvoice(updateObject,"deleted");
	}

	openDeleteInvoice = (invoiceId, amount) => {
		this.setState({
			isDeleteInvoiceEnable: true,
			selectedInvoiceId: invoiceId,
			updateAmount: amount,
			notes:""
		})
	}

	closeDeleteInvoice = () => {
		this.setState({
			isDeleteInvoiceEnable: false
		})
	}

	render() {
		return (
			<>
				<Card>
					<Card.Header>
						<Row>
							<Col xs={12} className='text-right'>
								<button className='btn btn-warning mr-4' onClick={() => { this.openEditInvoice(this.props.invoice.id, this.props.invoice.amount) }}>Edit</button>
								<button className='btn btn-danger mr-4' onClick={() => { this.openDeleteInvoice(this.props.invoice.id, this.props.invoice.amount) }}>Delete</button>
							</Col>
						</Row>
					</Card.Header>
					<ListGroup className="list-group-flush">
						<ListGroupItem>
							<Row>
								<Col xs={5}>Invoice Month</Col>
								<Col xs={7}>{this.props.invoice && this.props.invoice.due_date ? moment(this.props.invoice.due_date).format("MMM, YYYY") : "--NA--"}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Invoice Amount</Col>
								<Col xs={7}>{this.props.invoice && this.props.invoice.amount ? this.props.invoice.amount : '--NA--'}</Col>
							</Row>
						</ListGroupItem>
						{this.props.invoice && this.props.invoice.notes &&this.props.invoice.notes.length > 0 ?
							<ListGroupItem>
								<Row>
									<Col xs={5}>Previous Notes</Col>
									<Col xs={7}>
										<ul>
											{this.props.invoice.notes.map(item => (
												<li>
													<p>{item.note}<span className='text-warning font-weight-bold'>({moment(item.date).format("DD MMM, YYYY")})</span></p>
												</li>
											))}
										</ul>
										<br /></Col>
								</Row>
							</ListGroupItem>
							: null}
					</ListGroup>
				</Card>
				<Modal
					dialogClassName={'modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered'}
					show={this.state.isEditInvoiceEnable}
					onHide={() => this.closeEditInvoice()}
				>
					<Modal.Header closeButton>
						<Modal.Title>Edit Invoice(s)</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							<form onSubmit={(e) => { e.preventDefault(); this.onSubmitEditRequest(); }}>
								<h5>Amount to be updated*</h5>
								<input type='text' className='form-control' required value={this.state.updateAmount} onChange={(e) => { this.setState({ "updateAmount": e.target.value }) }} />
								<br />
								<h5>Notes*</h5>
								<textarea className='form-control' placeholder='Enter here...' required onChange={(e) => {
									this.setState({
										notes: e.target.value
									})
								}}></textarea>
								<br />
								<button className='btn btn-primary w-50 btn-block mx-auto' disabled={this.state.isUpdatingInvoice}>{this.state.isUpdatingInvoice?"Submitting...":"Submit"}</button>
							</form>
						</div>
					</Modal.Body>
				</Modal>
				<Modal
					dialogClassName={'modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered'}
					show={this.state.isDeleteInvoiceEnable}
					onHide={() => this.closeDeleteInvoice()}
				>
					<Modal.Header closeButton>
						<Modal.Title>Delete Invoice(s)</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							<form onSubmit={(e) => { e.preventDefault(); this.onSubmitDeleteRequest(); }}>
								<h5>Are you sure you want to delete invoice?</h5>
								<h5>Notes*</h5>
								<textarea className='form-control' placeholder='Enter here...' required onChange={(e) => {
									this.setState({
										notes: e.target.value
									})
								}}></textarea>
								<br />
								<button className='btn btn-primary w-50 btn-block mx-auto' disabled={this.state.isUpdatingInvoice}>{this.state.isUpdatingInvoice?"Submitting...":"Submit"}</button>
							</form>
						</div>
					</Modal.Body>
				</Modal>
			</>
		);
	}
}

export default EditInvoicesModal;
