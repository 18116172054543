import React, { Component } from 'react';
import Loader from 'react-loader-spinner'

class Loading extends Component {
    render() {
        return (
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Loader
                    type="Rings"
                    color="#5664d2"
                    height={100}
                    width={100}
                />
            </div>

        );
    }
}

export default Loading;
