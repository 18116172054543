import React, { Component } from "react";
import { Button, Card, Modal, Form } from 'react-bootstrap';


class AddLocationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: {
                name: ""
            },
        }
    }
    handleChange = (keyName, keyValue) => {
        let item = this.state.item;
        item[keyName] = keyValue;
        this.setState({
            item: item,
        });
    };
    submitHandler = (e) => {
        e.preventDefault();
        let location = this.state.item;
        this.props.addItem(location);
        this.props.close();
    };
    render() {
        return (
            <Modal
                dialogClassName={'modal-dialog modal-dialog-scrollable modal-md'}
                show={this.props.isVisible}
                onHide={this.props.close}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="d-flex justify-content-center">
                            <Form onSubmit={(e) => this.submitHandler(e)}>
                                <Form.Group controlId="carModalName">
                                    <Form.Label>Location Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Location"
                                        value={this.state.item.name}
                                        onChange={(e) => this.handleChange("name", e.target.value)}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        )
    }
}

export default AddLocationModal;
