import React, { Component } from "react";
import { Button, Card, Modal, Form } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';

const components = {
    DropdownIndicator: null,
};

const createOption = (label) => ({
    label,
    value: label,
});


class AddApartmentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: {
                name: "",
                locationId: "",
                basements: [],
                type: "",
                isActive: true
            },
            inputValue: '',
            value: []
        }
    }

    handleChangeMultiSelect = (
        value
    ) => {
        this.setState({ value });
    };

    handleInputChange = (inputValue) => {
        this.setState({ inputValue });
    };

    handleKeyDown = (event) => {
        const { inputValue, value } = this.state;
        if (!inputValue) return;
        switch (event.key) {
            case 'Tab':
                this.setState({
                    inputValue: '',
                    value: [...value, createOption(inputValue)],
                });
                event.preventDefault();
        }
    };


    handleChange = (keyName, keyValue) => {
        let item = this.state.item;
        item[keyName] = keyValue;
        this.setState({
            item: item,
        });
    };
    submitHandler = () => {
        let apartment = this.state.item;
        apartment.basements = this.state.value.map(item => (
            item.value
        ));
        console.log(apartment);
        this.props.addItem(apartment);
        this.props.close();
    };
    render() {
        return (
            <Modal
                dialogClassName={'modal-dialog modal-dialog-scrollable modal-md'}
                show={this.props.isVisible}
                onHide={this.props.close}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Apartment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="d-flex justify-content-center">
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                            }}>
                                <Form.Group controlId="apartmentName">
                                    <Form.Label>Apartment Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Apartment"
                                        value={this.state.item.name}
                                        onChange={(e) => this.handleChange("name", e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="apartmentLocality">
                                    <Form.Label>Apartment Locality</Form.Label>
                                    <Form.Control
                                        as="select"
                                        onChange={(e) => this.handleChange("locationId", e.target.value)}
                                    >
                                        <option selected="true" disabled>--Select--</option>
                                        {this.props.locations.map((item) => (
                                            <option value={item._id}>{item.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="apartmentType">
                                    <Form.Label>Apartment Locality</Form.Label>
                                    <Form.Control
                                        as="select"
                                        onChange={(e) => this.handleChange("type", e.target.value)}
                                    >
                                        <option selected="true" disabled>--Select--</option>
                                        <option value={"community"}>community</option>
                                        <option value={"community"}>corporate</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="apartmentBasement">
                                    <Form.Label>Basements</Form.Label>
                                    <CreatableSelect
                                        components={components}
                                        inputValue={this.state.inputValue}
                                        isClearable
                                        isMulti
                                        menuIsOpen={false}
                                        onChange={this.handleChangeMultiSelect}
                                        onInputChange={this.handleInputChange}
                                        onKeyDown={this.handleKeyDown}
                                        placeholder="Enter Basement"
                                        value={this.state.value}
                                    />
                                </Form.Group>
                                <Form.Group controlId="apartmentAddress">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Apartment Address"
                                        value={this.state.item.address}
                                        onChange={(e) => this.handleChange("address", e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="apartmentFlats">
                                    <Form.Label>No of Flats</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter No of Flats"
                                        value={this.state.item.flat_count}
                                        onChange={(e) => this.handleChange("flat_count", e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="apartmentParking">
                                    <Form.Label>No of Parking Slots</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter No of Parking Slots"
                                        value={this.state.item.parking_slot_count}
                                        onChange={(e) => this.handleChange("parking_slot_count", e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="apartmentWashAreas">
                                    <Form.Label>No of Wash Areas</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter No of Parking Slots"
                                        value={this.state.item.wash_areas_count}
                                        onChange={(e) => this.handleChange("wash_areas_count", e.target.value)}
                                    />
                                </Form.Group>
                                <Button className="mb-4" variant="primary" onClick={() => {
                                    this.submitHandler()
                                }}>
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>

            </Modal >
        )
    }
}

export default AddApartmentModal;
