import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import moment from 'moment';
import Datepicker from '../../../../components/DatePicker';
import Loading from '../../../../components/Loading';

class ModalTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEditable: false,
            item: {}
        };
    }

    componentDidMount() {
        if (this.props.item) {
            this.setState({
                item: this.props.item
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.item, this.props.item)) {
            this.setState({
                item: this.props.item
            });
        }
    }

    toggleEdit = () => {
        this.setState({
            isEditable: !this.state.isEditable
        })
    };

    handleChange = (keyName, keyValue) => {
        let item = this.state.item;
        item[keyName] = keyValue;
        this.setState({
            item: item
        });
    };

    saveDetails = () => {
        let item = this.state.item;
        this.props.updatePayment(this.props.item._id, item);
        this.toggleEdit();
        console.log("item", item);
    };

    handleDate = (date) => {
        console.log("Date", date);
    }

    render() {
        if (this.state.isEditable) {
            return (
                <Card>
                    <Card.Header>
                        <Row>
                            <Col xs={6}> {this.props.heading}</Col>
                            <Col xs={6}>
                                <div className={'text-right'}>
                                    <Button
                                        disabled={this.state.isUpdatingUser}
                                        onClick={() => this.saveDetails()}>
                                        {this.state.item.isUpdating ? 'saving' : 'save'}
                                    </Button>
                                    &nbsp;
                                    &nbsp;
                                    <Button onClick={() => this.toggleEdit()}>close</Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>
                                    Status
                                </Col>
                                <Col xs={7}>
                                    <FormControl
                                        as="select"
                                        onChange={(e) => {
                                            this.handleChange("isPaid", e.target.value);
                                        }}
                                    >
                                        <option selected={true} value={this.state.item.isPaid} disabled >{this.state.item.isPaid ? "Paid" : "Unpaid"}</option>
                                        <option value={"true"}>Paid</option>
                                        <option value={"false"}>Unpaid</option>
                                    </FormControl>
                                </Col>
                            </Row>
                        </ListGroupItem>

                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>
                                    Payment Due Date
                                </Col>
                                <Col xs={7}>
                                    <Datepicker
                                        handleDateChange={(e) => {
                                            this.handleChange("due_date", moment(new Date(e)).format("MM DD YYYY"));
                                        }}
                                        value={this.state.item.due_date}
                                    />
                                </Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Payment Date</Col>
                                <Col xs={7}>
                                    {moment(this.state.item.payment_date).format('DD MMM YYYY')}
                                </Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>
                                    Billing Date
                                </Col>
                                <Col xs={7}>
                                    {moment(this.props.item.bill_date).format('DD MMM YYYY')}
                                </Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>
                                    Invoice Type
                                </Col>
                                <Col xs={7}>
                                    {this.state.item.invoice_type}
                                </Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>
                                    Vehicle Type
                                </Col>
                                <Col xs={7}>
                                    {this.state.item.vehicle_type}
                                </Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>
                                    Vehicle Model
                                </Col>
                                <Col xs={7}>
                                    {this.state.item.model_name}
                                </Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>
                                    Vehicle Reg.No
                                </Col>
                                <Col xs={7}>
                                    <FormControl
                                        onChange={(e) => this.handleChange('license_number', e.target.value)}
                                        value={this.state.item.license_number}
                                    />
                                </Col>
                            </Row>
                        </ListGroupItem>

                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>
                                    Amount
                                </Col>
                                <Col xs={7}>
                                    <FormControl
                                        onChange={(e) => this.handleChange('payment_amount', e.target.value)}
                                        value={this.state.item.payment_amount}
                                    />
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            )
        } else {
            return (
                <Card>
                    <Card.Header>
                        <Row>
                            <Col xs={6}>
                                {this.props.heading}
                            </Col>
                            {/* <Col xs={6}>
                                <div className="text-right">
                                    <Button onClick={() => this.toggleEdit()}>edit</Button>
                                </div>
                            </Col> */}
                        </Row>
                    </Card.Header>
                    {this.props.item.isUpdating ? <Loading></Loading> :
                        <ListGroup className="list-group-flush">
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Status
                                    </Col>
                                    <Col xs={7}>
                                        {this.props.item.isPaid ? "Paid" : "Unpaid"}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Payment Due Date
                                    </Col>
                                    <Col xs={7}>
                                        {moment(this.props.item.due_date).format('DD/MM/YY')}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Payment Date
                                    </Col>
                                    <Col xs={7}>
                                        {moment(this.props.item.payment_date).format('DD/MM/YY')}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Billing Date
                                    </Col>
                                    <Col xs={7}>
                                        {moment(this.props.item.bill_date).format('DD/MM/YY')}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Invoice Type
                                    </Col>
                                    <Col xs={7}>
                                        {this.props.item.invoice_type}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Amount
                                    </Col>
                                    <Col xs={7}>
                                        {this.props.item.payment_amount}
                                    </Col>
                                </Row>
                            </ListGroupItem>

                        </ListGroup>
                    }
                </Card>
            )
        }
    }
}


export default ModalTable;