import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import moment from 'moment';

class ListInvoices extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEditable: false,
			item: {},
		};
	}

	componentDidMount() {
		if (this.props.item) {
			this.setState({
				item: this.props.item,
			});
		}
	}

	render() {
		return (
			<Card>
				<Card.Header>
					<Row>
						<Col xs={6}>{this.props.heading}</Col>
						<Col xs={6} className='text-right'><button className='btn btn-primary' disabled={this.state.isRefreshingPayment} onClick={()=>{this.props.openEditInvoiceModal()}}>{"Edit / Delete"}</button></Col>
					</Row>
				</Card.Header>
				<ListGroup className="list-group-flush">
					<ListGroupItem>
						<Row>
							<Col xs={5}>Service Name</Col>
							<Col xs={7}>{this.state.item && this.state.item.service_name}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Booking Status</Col>
							<Col xs={7}>{this.state.isBookingCancelled && this.state.item.isBookingCancelled ? <p className='text-danger'>Cancelled</p> : <p className='text-success'>Active</p>}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Car Body Type</Col>
							<Col xs={7}>{this.state.item && this.state.item.car_body_type}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Model Name</Col>
							<Col xs={7}>{this.state.item && this.state.item.model_name ? this.state.item.model_name : '--NA--'}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Brand Name</Col>
							<Col xs={7}>{this.state.item && this.state.item.brand_name ? this.state.item.brand_name : ''}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>License Number</Col>
							<Col xs={7}>{this.state.item && this.state.item.license_number ? this.state.item.license_number : '--NA--'}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Community Name</Col>
							<Col xs={7}>{this.state.item && this.state.item.community_name ? this.state.item.community_name : '--NA--'}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Due Date</Col>
							<Col xs={7}>{this.state.item && this.state.item.recentInvoiceDueDate ? moment(this.state.item.recentInvoiceDueDate).format("LLL") : '--NA--'}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Recent Invoice Amount</Col>
							<Col xs={7}>₹ {this.state.item && this.state.item.recentInvoiceAmount}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Arrears Amount</Col>
							<Col xs={7}>₹ {this.state.item && this.state.item.arrears_amount ? this.state.item.arrears_amount : '--NA--'}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Arrears Months</Col>
							<Col xs={7}>{this.state.item && this.state.item.invoiceIds && this.state.item.invoiceIds.length > 0 ? this.state.item.invoiceIds.map(item => {
								return (
								<p>{moment(item.due_date).format("MMM, YYYY")} </p>
							)}) : '--NA--'}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Total Invoice Amount</Col>
							<Col xs={7}>₹ {this.state.item && this.state.item.total_invoice_amount ? this.state.item.total_invoice_amount : "--NA--"}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Booking Monthly Cost</Col>
							<Col xs={7}>₹ {this.state.item && this.state.item.booking_monthly_cost ? this.state.item.booking_monthly_cost : "--NA--"}</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Extra Addons</Col>
							<Col xs={7}>Comming soon...</Col>
						</Row>
					</ListGroupItem>
					<ListGroupItem>
						<Row>
							<Col xs={5}>Addon amount</Col>
							<Col xs={7}>Comming soon...</Col>
						</Row>
					</ListGroupItem>
				</ListGroup>
			</Card>
		);
	}
}

export default ListInvoices;
