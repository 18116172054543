import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			links: [
				// {
				//     icon: 'ri-building-4-line ',
				//     name: 'Staff',
				//     url: '/staff',
				//     isSubmenuPresent: true,
				//     submenus: [
				//         {
				//             name: 'Manager',
				//             url: '/staff/manager'
				//         },
				//         {
				//             name: 'Supervisor',
				//             url: '/staff/supervisor'
				//         },
				//         {
				//             name: 'Cleaner',
				//             url: '/staff/cleaner'
				//         }
				//     ],
				// },
				{
					icon: 'ri-dashboard-line',
					name: 'Dashboard',
					url: '/dashboard',
					isSubmenuPresent: false,
				},

				{
					icon: ' ri-file-list-line ',
					name: 'Bookings',
					url: '/customer/bookings',
					isSubmenuPresent: false,
					submenus: [
						// {
						// 	name: 'Bookings',
						// 	url: '/customer/bookings',
						// 	isSubmenuPresent: false,
						// },
						// {
						// 	name: 'Daily Transactions',
						// 	url: '/customer/payments',
						// },

						// {
						// 	name: 'Master data',
						// 	url: '/customer',
						// },
						// {
						// 	name: 'Car/Bike Status',
						// 	url: '/customer/tasks',
						// },
						// {
						// 	name: 'Grooming services/Ondemand',
						// 	url: '/customer/special-tasks',
						// }
					],
				},
				{
					icon: 'ri-profile-line',
					name: 'Vendors',
					url: '/vendors',
					//isSubmenuPresent: false,
					isSubmenuPresent: true,
					submenus: [
						{
							name: 'Vendors',
							url: '/vendors',
						},

						{
							name: 'Supervisors',
							url: '/vendors/supervisors',
						},

						{
							name: 'Cleaners',
							url: '/vendors/cleaners',
						}
					],
				},
				{
					icon: ' ri-wallet-line ',
					name: 'Packages',
					url: '/servicepackages',
					isSubmenuPresent: true,
					submenus: [
						{
							name: 'ServicePackages',
							url: '/servicepackages',
						},

						{
							name: 'Ad-On Services',
							url: '/adonservices',
						}
					],
				},
				{
					icon: 'ri-store-2-line',
					name: 'Refunds',
					url: '/refunds',
					isSubmenuPresent: false,
				},
				{
					icon: ' ri-wallet-line ',
					name: 'Vehicles',
					url: '#',
					isSubmenuPresent: true,
					submenus: [
						{
							name: 'Cars',
							url: '/vehicles/cars',
						},
						{
							name: 'Bikes',
							url: '/vehicles/bikes',
						},
					],
				},
				{
					icon: 'ri-find-replace-fill',
					name: 'Places',
					url: '/places',
					isSubmenuPresent: true,
					submenus: [
						{
							name: 'Locations',
							url: '/places/locations',
						},
						{
							name: 'Communities',
							url: '/places/communities',
						},
						{
							name: 'Blocks',
							url: '/places/blocks',
						},
					],
				},
				{
					icon: 'ri-dashboard-line',
					name: 'Profile',
					url: '/profile',
					isSubmenuPresent: false,
				},
			],
		};
	}

	render() {
		return (
			<div className="vertical-menu">
				<div data-simplebar className="h-100">
					<div id="sidebar-menu">
						<ul className="metismenu list-unstyled" id="side-menu">
							{this.state.links.map((link) =>
								link.isSubmenuPresent ? (
									<li>
										<a href={'#'} className="has-arrow waves-effect">
											<i className={link.icon} />
											<span>{link.name}</span>
										</a>
										<ul className="sub-menu" aria-expanded="false">
											{link.submenus.map((item) => (
												<li>
													<Link to={item.url}>{item.name}</Link>
												</li>
											))}
										</ul>
									</li>
								) : (
									<li>
										<Link to={link.url} className="waves-effect">
											<i className={link.icon} />
											{/*<span className="badge badge-pill badge-success float-right">3</span>*/}
											<span>{link.name}</span>
										</Link>
									</li>
								)
							)}
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

export default Sidebar;
