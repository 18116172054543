import React, { Component } from 'react'
import _ from "lodash";
import callApi from '../../util/apiCaller';
import  StaffTable  from "./component/StaffTable";
export default class ShowStaff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
            pageNum: 1,
            pageSize: 100,
            agencyId: '',
        }

    }
    componentDidMount() {
        console.log(this.props)
        if (this.props.agencyId) {
            this.setState({
                agencyId: this.props.agencyId,
            });
        }
        if (!_.isEqual(this.props.match.params.type, this.state.type)) {
            this.setState({
                type: this.props.match.params.type,
            }, () => this.fetchStaff(this.props.agencyId, this.state.type));
        }
    }

    componentDidUpdate(prevProps, prevSate) {
        if (!_.isEqual(prevSate.type, this.props.match.params.type)) {
            this.setState({
                type: this.props.match.params.type,
            }, () => this.fetchStaff(this.props.agencyId, this.state.type));
        }
    }
    fetchStaff = (agencyId, staffType) => {
        callApi(`owner/agency/${agencyId}/${staffType}-list`, 'post', {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
        }).then((res) => {
            console.log(res)
            if(res.status === "Success"){
                this.setState({
                    [this.state.type]:res.data[`${this.state.type}s`]
                }, console.log(this.state))
            }
        })
    }
    render() {
        return (
            <div>
                    <StaffTable 
                        name={this.state.type}
                        headings={['S. No.', 'Name', 'Email','Phone', 'Location', 'Apartment']}
                        rows={this.state[`${this.state.type}`]}
                        />
            </div>
        )
    }
}
