import React, { Component } from 'react';
import ListTableHeader from "./ListTableHeader";
import ListTableRow from "./ListTableRow";
import ListTablePagination from "../../../components/ListTablePagination";
import Loading from '../../../components/Loading';

class ListTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSidebarEnabled: true,
        };
    }

    getPages = (count, pagesize) => {
        if (count > 0) {
            return Math.ceil(count / pagesize);
        } else {
            return 0;
        }
    };

    getPageStart = (pageNum = 1, pageSize = 50, count = 0) => {
        if (parseInt(count) > 0) {
            return (parseInt(pageNum) - 1) * parseInt(pageSize) + 1;
        } else {
            return 0;
        }
    };

    getPageEnd = (pageNum = 1, pageSize = 50, count = 0) => {
        if (parseInt(count) > 0) {
            if ((count - ((pageNum - 1) * pageSize)) < pageSize) {
                return count;
            } else {
                return pageNum * pageSize;
            }
        } else {
            return 0;
        }
    };

    render() {
        return (
            <div className="table-responsive">
                <div id="DataTables_Table_0_wrapper"
                    className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="dataTables_length" id="DataTables_Table_0_length">
                                <label>Show &nbsp;
                                    <select
                                        value={this.props.pageSize}
                                        onChange={(e) => this.props.changePageSize(e.target.value)}
                                        name="DataTables_Table_0_length" aria-controls="DataTables_Table_0"
                                        className="custom-select custom-select-sm form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select> &nbsp;entries
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                <label>Search :
                            <input
                                        onChange={(e) => this.props.handleQuery(e.target.value)}
                                        value={this.props.query}
                                        type="search" className="form-control form-control-sm" placeholder="Search . . ."
                                        aria-controls="DataTables_Table_0" /></label></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <table
                                className="table table-centered datatable dt-responsive nowrap dataTable no-footer dtr-inline collapsed"
                                style={{
                                    borderCollapse: 'collapse',
                                    borderSpacing: '0px',
                                    width: '100%'
                                }} id="DataTables_Table_0" role="grid"
                                aria-describedby="DataTables_Table_0_info">
                                <ListTableHeader />
                                {
                                    this.props.isFetchingItems ? <Loading /> : <tbody>
                                        {this.props.items && this.props.items.map((item, index) => (
                                            <ListTableRow
                                                handleDetails={this.props.handleDetails}
                                                index={index}
                                                page={this.props.pageNum}
                                                size={this.props.pageSize}
                                                deleteItem={this.props.deleteItem}
                                                item={item}
                                            />
                                        ))}
                                    </tbody>
                                }
                            </table>
                        </div>
                    </div>
                    <ListTablePagination
                        changePageNum={this.props.changePageNum}
                        page={this.props.pageNum}
                        size={this.props.pageSize}
                        count={this.props.totalCount}
                    />
                </div>
            </div>
        );
    }
}

export default ListTable;
