import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import callApi from '../../../util/apiCaller';

class VendorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditable: false,
            item: {},
            isLoading: true,
            isUpdatingCommunity: false,
            updateObject: {},
            listItems: {},
            
        };
    }

    componentDidMount() {
        console.log("selected Id description", this.props.apartmentId);
        if (this.props.apartmentId) {
            this.fetchPackages(this.props.apartmentId);
        }
    }

    fetchPackages = (id) => {
        this.setState(
            {
                isLoading: true,
            },
            () => {
                callApi(`servicepackages/${id}`, 'get').then((res) => {
                    console.log(res);
                    if (res.status === 'Success') {
                        console.log("response", res.data);
                        this.setState({
                            isLoading: false,
                            servicepackages: res.data
                        });
                    }
                });
            }
        );
    };

    toggleEdit = () => {
        this.setState({
            isEditable: !this.state.isEditable,
        });
    };

    handleChange = (keyValue,i) => {
        let item = this.state.servicepackages;
        item.servicepackage['description'][i] = keyValue;
        this.setState({
            updateObject: item,
        });
        console.log(item);
    };

    saveDetails = () => {
        let item = this.state.servicepackages.servicepackage;
        console.log(item);
        this.updateCommunity(this.state.servicepackages.servicepackage._id, item);
    };

    updateCommunity = (id, servicepackage) => {
        this.setState({
            isUpdatingCommunity: true,
        });
        console.log(servicepackage);
        callApi(`servicepackages/${id}/update`, 'post', {
            servicepackage,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    isUpdatingCommunity: false,
                });
                this.toggleEdit();
                this.fetchPackages(id);
            } else {
                this.setState({
                    isUpdatingCommunity: false,
                });
                this.toggleEdit();
            }
        });
    };

    handleAddDescription = () => {
        let item = this.state.servicepackages;
        item.servicepackage['description'] = this.state.servicepackages.servicepackage.description.concat("")
        console.log(item);
        this.setState({
            servicepackages: item
        });
      };

    handleRemoveDescription = idx => () => {
        let item = this.state.servicepackages;
        console.log(item);
        item.servicepackage['description'] = this.state.servicepackages.servicepackage.description.filter((s, sidx) => idx !== sidx);
       
        this.setState({
            servicepackages: item
        });
        console.log(this.state.servicepackages.servicepackage.description);
      };

    render() {
        //let locations = this.state.servicepackages.servicepackage.products_used.map(item => ({ value: item._id, label: item.name }));

        if (this.state.isEditable) {
            return (
                <Card>
                    <Card.Header>
                        <Row>
                            <Col xs={6}> {this.props.heading}</Col>
                            <Col xs={6}>
                                <div className={'text-right'}>
                                      <button
                                        type="button"
                                        onClick={this.handleAddDescription}
                                        className="btn btn-primary">
                                        Add New
                                    </button> &nbsp;
        							&nbsp; /  &nbsp;
        							&nbsp;

                                    <Button disabled={this.state.isUpdatingCommunity} onClick={() => this.saveDetails()}>
                                        {this.state.isUpdatingCommunity ? 'saving' : 'save'}
                                    </Button>
        							&nbsp;
        							&nbsp;
        							<Button onClick={() => this.toggleEdit()}>close</Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>
                    <ListGroup className="list-group-flush">
                   
                        <ListGroupItem>
                            <Row>
                                <Col xs={12}>
                                
                                { this.state.listItems = this.state.servicepackages.servicepackage.description.map((descript,i) =>
                                    // <FormControl
                                    //     onChange={(e) => this.handleChange(e.target.value,i)}
                                    //     value={descript}
                                    // />
                                    <div style={{width: "100%",display: "inline-flex"}}>
                                        <input
                                    type="text"
                                    placeholder={`Description #${i + 1}`}
                                    value={descript}
                                    onChange={(e) => this.handleChange(e.target.value,i)}
                                    className="form-control"
                                    />
                                    &nbsp;
        							&nbsp;
                                    <button
                                    type="button"
                                    onClick={this.handleRemoveDescription(i)}
                                    className="btn btn-secondary"
                                    style={{height: "90%"}}
                                    >-</button>
                                    </div>
                                    
                                    )}
                                </Col>
                            </Row>
                        </ListGroupItem>
                        
                    </ListGroup> 
                </Card>
            );
        } else {
            if (!this.state.isLoading) {
                return (
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={6}>{this.props.heading}</Col>
                                <Col xs={6}>
                                    <div className="text-right">
                                        <Button onClick={() => this.toggleEdit()}>edit</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        <ListGroup className="list-group-flush">
                        { this.state.listItems = this.state.servicepackages.servicepackage.description.map((descript) =>
                    <ListGroupItem>
                            <Row>
                                <Col xs={12}>{descript}</Col>
                            </Row>
                        </ListGroupItem>
                    )}
                       
                            
                        </ListGroup>
                    </Card>
                );
            }
            else {
                return (
                    <h1>Loading...</h1>
                )
            }
        }
    }
}

export default VendorDetails;
