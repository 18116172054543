import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import moment from 'moment';
import Datepicker from '../../../components/DatePicker';
import Loading from '../../../components/Loading';

class ModalTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEditable: false,
            item: {}
        };
    }

    componentDidMount() {
        if (this.props.item) {
            this.setState({
                item: this.props.item
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.item, this.props.item)) {
            this.setState({
                item: this.props.item
            });
        }
    }

    toggleEdit = () => {
        this.setState({
            isEditable: !this.state.isEditable
        })
    };

    handleChange = (keyName, keyValue) => {
        let item = this.state.item;
        item[keyName] = keyValue;
        this.setState({
            item: item
        });
        console.log(this.state.item);
    };

    saveDetails = () => {
        let item = this.state.item;
        //item['isPaid'] = true;

        this.props.updateRefundPayments(this.props.item._id, item.isPaid);
        this.toggleEdit();
        console.log("item", item);
    };

    handleDate = (date) => {
        console.log("Date", date);
    }

    render() {
        if (this.state.isEditable) {    
            return (
                <Card>
                    <Card.Header>
                        <Row>
                            <Col xs={6}>
                                {this.props.heading}
                            </Col>
                            <Col xs={6}>
								<div className={'text-right'}>
									<Button disabled={this.props.item.isUpdating} onClick={() => this.saveDetails()}>
										{this.props.item.isUpdating ? 'saving' : 'update'}
									</Button>
									&nbsp;
									&nbsp;
									<Button onClick={() => this.toggleEdit()}>close</Button>
								</div>
							</Col>
                            
                        </Row>
                    </Card.Header>
                   
                      <ListGroup className="list-group-flush">
                            
                            {/* <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Payment Due Date
                            </Col>
                                    <Col xs={7}>
                                        {moment(this.state.item.due_date).format('DD MMM YYYY')}
                                    </Col>
                                </Row>
                            </ListGroupItem> */}
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Payment Date
                            </Col>
                                    <Col xs={7}>
                                        {moment(this.state.item.payment_date).format('DD MMM YYYY')}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Billing Date
                            </Col>
                                    <Col xs={7}>
                                        {moment(this.state.item.bill_date).format('DD MMM YYYY')}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Invoice Type
                            </Col>
                                    <Col xs={7}>
                                        {this.state.item.invoice_type}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Razorpay Order Id
                            </Col>
                                    <Col xs={7}>
                                        {this.state.item.razorpay_order_id}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Service Name
                            </Col>
                                    <Col xs={7}>
                                        {this.state.item.service_name}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Amount
                            </Col>
                                    <Col xs={7}>
                                        {this.state.item.payment_amount}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Status
                                    </Col>
                                    <Col xs={7}>
                                    <FormControl
                         as="select"
                         onChange={(e) => this.handleChange('isPaid', e.target.value)}
                         value={this.state.item.isPaid}
                     >
                         <option value=""></option>
                         <option value="paid">paid</option>
                         <option value="unpaid">unpaid</option>  
                     </FormControl>

                                  
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            
                           

                        </ListGroup> 
                  
                </Card>
            )
        }else{
            return (
                <Card>
                    <Card.Header>
                        <Row>
                            <Col xs={6}>
                                {this.props.heading}
                            </Col>
                            <Col xs={6}>
								<div className="text-right">
									<Button onClick={() => this.toggleEdit()}>Edit Status</Button>
								</div>
							</Col>
                        </Row>
                    </Card.Header>
                   
                      <ListGroup className="list-group-flush">
                            
                            {/* <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Payment Due Date
                            </Col>
                                    <Col xs={7}>
                                        {moment(this.state.item.due_date).format('DD MMM YYYY')}
                                    </Col>
                                </Row>
                            </ListGroupItem> */}
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Payment Date
                            </Col>
                                    <Col xs={7}>
                                        {moment(this.state.item.payment_date).format('DD MMM YYYY')}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Billing Date
                            </Col>
                                    <Col xs={7}>
                                        {moment(this.state.item.bill_date).format('DD MMM YYYY')}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Invoice Type
                            </Col>
                                    <Col xs={7}>
                                        {this.state.item.invoice_type}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Razorpay Order Id
                            </Col>
                                    <Col xs={7}>
                                        {this.state.item.razorpay_order_id}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Service Name
                            </Col>
                                    <Col xs={7}>
                                        {this.state.item.service_name}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Amount
                            </Col>
                                    <Col xs={7}>
                                        {this.state.item.payment_amount}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Status
                            </Col>
                                    <Col xs={7}>
                                    {this.state.item.isPaid ? 'paid' : 'unpaid'}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            
                            

                        </ListGroup> 
                  
                </Card>
            ) 
        }
    }
}


export default ModalTable;