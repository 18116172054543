import React, { Component } from 'react';
import callApi from '../../util/apiCaller';
import ListTable from './components/ListTable';
import _, { filter } from 'lodash';
import { Button } from 'react-bootstrap';
import AddVendorModal from './components/AddVendorModal';
import VendorDetailsModal from './components/VendorDetailsModal'
import downloadApi from "../../util/apiDownloader";
import moment from "moment";
import Select from 'react-select';


class VendorsDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,
            pageSize: 25,
            totalCount: 0,
            items: [],
            locations: [],
            isAddVendorModalVisible: false,
            isAddingVendor: false,
            isDetailsModalVisible: false,
            selectedItemId: '',
            isDownloadingData: false,
            filter_locations: [],
            filter_status: '',
            query: '',
            filter_agencyIds: [{}],
            agenciesTypes: []
        };
        this.debounceSearch = _.debounce(this.handleSearch, 500);
    }

    componentDidMount() {
        let filters = this.getFilters();
        this.fetchApartments();
        this.fetchLocations();
        this.fetchAgencies();
    }

    fetchApartments = () => {
        let filters = this.getFilters();
        console.log(filters);
        callApi(`agencys/list`, 'post', {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            selected_filter: filters || {},
            qtext: this.state.query
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    items: res.data["agencys"],
                    totalCount: res.data.count
                }, () => {
                    console.log("fetched_agencys", this.state.items);
                })
            }
            else {
                console.log("error")
            }
        })
    };

    fetchLocations = () => {
        callApi(`locations/list`, 'post', {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    locations: res.data["locations"]
                })
            }
            else {
                console.log("error")
            }
        })
    };

    deleteItem = (id) => {
        console.log(id);
        let items = this.state.items.map((item) => {
            if (item._id === id) {
                item.isDeletingItem = true;
            }
            return item;
        });
        this.setState(
            {
                items: items,
            },
            () => {
                callApi(`agencys/${id}/remove`, 'post', {})
                    .then((res) => {
                        if (res.status === 'Success') {
                            this.fetchApartments();
                        }
                    });
            }
        );
    };

    getFilters = () => {
        let input = {};
        if (this.state.filter_locations.length > 0) {
            input.locationId = this.state.filter_locations.map(item => (item.value))
        }
        
        if (this.state.filter_agencyIds && this.state.filter_agencyIds.value != "") {
            input.name = this.state.filter_agencyIds.value;
            //console.log(this.state.filter_agencyIds);
		}
        if (this.state.filter_status && this.state.filter_status.value != "") {
            input.isActive = this.state.filter_status.value;
        }
        return input;
    };

    addItem = (item) => {
        this.setState({ isAddingVendor: true });
        callApi(`agencys/new`, 'post', {
            ...item
        }).then((res) => {
            console.log("response from api", res.data);
            if (res.status === 'Success') {
                this.setState({ isAddingVendor: false });
                this.fetchApartments()
            }
        });
    };

    updateItem = (id, item) => {
        let items = this.state.items.map((item) => {
            if (item._id === id) {
                item.isUpdating = true;
            }
            return item;
        });
        this.setState(
            {
                items: items,
            },
            () => {
                console.log("item_before_api_call", item);
                callApi(`agencys/${id}/update`, 'post', {
                    ...item,
                }).then((res) => {
                    if (res.status === 'Success') {
                        let items = this.state.items.map((item) => {
                            if (item._id === id) {
                                return res.data.agencys;
                            } else {
                                return item;
                            }
                        });
                        this.setState({
                            items: items,
                        });
                    }
                });
            }
        );
    };

    addVendorHandler = () => {
        this.setState({
            isAddVendorModalVisible: true
        })
    }

    closeModal = () => {
        this.setState({
            isDetailsModalVisible: false,
            selectedItemId: '',
            isAddVendorModalVisible: false,
        });
    };

    downloadData = () => {
        console.log('Download pressed');
        this.setState({
            isDownloadingData: true,
        });
        let filters = this.getFilters();
        downloadApi(`vendors-${moment().format('DD-MM-YYYY-HH:mm')}.csv`, `admin/vendor/dump`, 'POST', filters).then(
            (blob) => {
                this.setState({
                    isDownloadingData: false,
                });
                console.log(JSON.stringify(blob));
            }
        );
    };

    handleDetails = (id) => {
        this.setState({
            selectedItemId: id,
            isDetailsModalVisible: true,
        });
    };

    changePageNum = (num) => {
        this.setState(
            {
                pageNum: num,
            },
            () => {
                let filters = this.getFilters();
                this.fetchApartments(filters);
            }
        );
    };

    handleChange = (keyName, keyValue) => {
        let item = {};
        item[keyName] = keyValue;
        this.setState(item);
    };

    handleQuery = (val) => {
        // val = _.kebabCase(val);
        this.setState(
            {
                query: val,
            },
            () => {
                this.debounceSearch();
            }
        );
    };

    handleSearch = () => {
        let filters = this.getFilters();
        this.fetchApartments(filters);
    };

    fetchAgencies = () => {
		callApi(`agencys/list`, 'post', {
			pageNum: 1,
			pageSize: 100,
		}).then((res) => {
			if (res.status === 'Success') {
                let agenciesTypes = [];
                let agenciesTypesArr = [{}]
                const distinct = (value,index,self)=>{
                    return self.indexOf(value)===index;
                }
                agenciesType: res.data.agencys.map((item,i) => {
                    //console.log(item.name);
                    
                     agenciesTypes[i] = (
                         { "value": item.name, "label": item.name }
                     )
                            
                });
                const result = [];
                    const map = new Map();
                    for (const item of agenciesTypes) {
                        if(!map.has(item.value)){
                            map.set(item.value, true);    // set any value to Map
                            result.push({
                                value: item.value,
                                label: item.value
                            });
                        }
                    }
                //console.log(result);
                
				this.setState({
					agencies: result
                })
                //console.log(this.state.agencies)
			}
			else {
				console.log("error")
			}
		})
    };
    
    render() {
        let locations = this.state.locations.map(item => ({ value: item._id, label: item.name }));
        //let agencies = this.state.agencies.map(item => ({ value: item._id, label: item.name }));
        //console.log(agencies)

        let status = [
            { value: "", label: "All" },
            { value: "true", label: "Active" },
            { value: "false", label: "In Active" }
        ]
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="mb-0">Vendor List</h4>
                            <div className="page-title-right">
                                <Button className="mr-3" type="button" data-toggle="collapse" data-target="#multiCollapseExample1" aria-expanded="false" aria-controls="multiCollapseExample2"><i className="fa fa-filter"></i></Button>
                                <Button className="mr-3" onClick={() => this.addVendorHandler()} disabled={this.state.isAddingVendor}>
                                    <i className="fa fa-plus"></i> {this.state.isAddingVendor ? "Adding" : "Add"}
                                </Button>
                                <Button onClick={() => this.downloadData()} disabled={this.state.isDownloadingData}>
                                    {this.state.isDownloadingData ? "Downloading" : "Download Data"}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="collapse multi-collapse" id="multiCollapseExample1">
                    <div className="card card-body">
                        <h5>Apply Filters</h5>
                        <div className="container mt-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Select Vendor Type</label>
                                    
                                    <Select
                                        onChange={(value) => this.handleChange("filter_agencyIds", value)}
                                        options={this.state.agencies}
                                        defaultValue={this.state.agencies}
                                    />
                               
                                    
                                </div>

                                <div className="col-md-6">
                                    <label>Select Location</label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        onChange={(value) => this.handleChange("filter_agencyIds", value)}
                                        isMulti
                                        options={locations}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>Select Status</label>
                                    <Select
                                        onChange={(value) => this.handleChange("filter_status", value)}
                                        options={status}
                                        defaultValue={status[0]}
                                    />
                                </div>
                            </div>
                        </div>
                        <Button className="w-25 mx-auto mt-3" onClick={() => this.fetchApartments()}>
                            Apply
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <ListTable
                                    changePageNum={this.changePageNum}
                                    handleQuery={this.handleQuery}
                                    updateItem={this.updateItem}
                                    deleteItem={this.deleteItem}
                                    handleDetails={this.handleDetails}
                                    query={this.state.query}
                                    items={this.state.items}
                                    locations={this.state.locations}
                                    pageSize={this.state.pageSize}
                                    totalCount={this.state.totalCount}
                                    pageNum={this.state.pageNum}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isAddVendorModalVisible ? (
                    <AddVendorModal
                        isVisible={this.state.isAddVendorModalVisible}
                        close={this.closeModal}
                        addItem={this.addItem}
                        locations={this.state.locations}
                    />
                ) : null}
                {this.state.isDetailsModalVisible ? (
                    <VendorDetailsModal
                        isVisible={this.state.isDetailsModalVisible}
                        selectedItemId={this.state.selectedItemId}
                        close={this.closeModal}
                        fetchApartments={this.fetchApartments}
                    />
                ) : null}
            </div>
        );
    }
}

export default VendorsDetail;
