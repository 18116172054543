import React, { Component } from 'react';
import moment from 'moment';
import { FormControl } from 'react-bootstrap';
import _ from 'lodash';

class ListTableRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEditModeVisible: false,
			saved: {},
		};
	}

	openEditMode = (item) => {
		console.log(item);
		this.setState({
			isEditModeVisible: true,
			saved: _.clone(item),
		});
	};

	closeEditMode = () => {
		this.setState({
			isEditModeVisible: false,
			saved: {},
		});
	};

	handleChange = (keyName, keyValue) => {
		let item = this.state.saved;
		if (keyName === 'carbrand') {
			let filterItem = this.props.carbrands.filter((item) => item.name === keyValue);
			item['carbrand'] = filterItem[0].name;
			item['carbrand_slug'] = filterItem[0].slug;
			item['carbrandId'] = filterItem[0]._id;
		} else {
			item[keyName] = keyValue;
		}
		this.setState({
			saved: item,
		});
	};

	saveData = () => {
		let item = this.state.saved;
		this.props.updateItem(this.props.item._id, item);
		this.closeEditMode();
	};

	render() {
		//console.log('brands', this.props.carbrands);
		if (this.state.isEditModeVisible) {
			return (
				<tr role="row" className="odd">
					<td>{this.props.index + 1 + (this.props.page - 1) * this.props.size}</td>
					<td>
						<FormControl
							onChange={(e) => this.handleChange('name', e.target.value)}
							value={this.state.saved ? this.state.saved.name : ''}
						/>
					</td>
					<td>
						<FormControl
							as="select"
							onChange={(e) => this.handleChange('carbrand', e.target.value)}
							value={this.state.saved ? this.state.saved.carbrand : ''}
						>
							<option value=""></option>
							{this.props.carbrands.map((item) => (
								<option value={item.name}>{item.name}</option>
							))}
						</FormControl>
					</td>
					<td>
						<FormControl
							as="select"
							onChange={(e) => this.handleChange('car_body_type', e.target.value)}
							value={this.state.saved ? this.state.saved.car_body_type : ''}
						>
							<option value=""></option>
							{this.props.car_body_types &&
								this.props.car_body_types.map((item) => (
									<option value={item.value}>{item.label}</option>
								))}
						</FormControl>
					</td>
					<td>
						<button
							disabled={this.props.item.isUpdating}
							onClick={() => this.saveData()}
							className="btn btn-light btn-rounded"
						>
							{'  '}
							Save{'  '}
						</button>
						<button
							disabled={this.props.item.isUpdating}
							onClick={() => this.closeEditMode()}
							className="btn btn-light btn-rounded"
						>
							{'  '}
							Close{'  '}
						</button>
					</td>
				</tr>
			);
		} else {
			return (
				<tr role="row" className="odd">
					<td>{this.props.index + 1 + (this.props.page - 1) * this.props.size}</td>
					<td>{this.props.item.name}</td>
					<td>{this.props.item.carbrand}</td>
					<td>{this.props.item.car_body_type}</td>
					<td>
						<button
							onClick={() => this.openEditMode(this.props.item)}
							className="btn btn-light btn-rounded"
						>
							{' '}
							Edit{' '}
						</button>
					</td>
				</tr>
			);
		}
	}
}

export default ListTableRow;
