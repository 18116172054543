import React, { Component } from "react";
import { Button, Card, Modal, Form } from 'react-bootstrap';
import moment from 'moment';

class AddCarModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: {
                name: "",
                carbrandId: "",
                car_body_type: ""
            }
        }
    }
    handleChange = (keyName, keyValue) => {
        let item = this.state.item;
        item[keyName] = keyValue;
        this.setState({
            item: item,
        });
    };

    submitHandler = () => {
        let car_model = this.state.item;
        this.props.addItem(car_model);
        this.props.close();
    };


    render() {
        return (
            <Modal
                dialogClassName={'modal-dialog modal-dialog-scrollable modal-md'}
                show={this.props.isVisible}
                onHide={this.props.close}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add a New Car Model</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="d-flex justify-content-center">
                            <Form onSubmit={() => this.submitHandler()}>
                                <Form.Group controlId="carModalName">
                                    <Form.Label>Car Modal Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Modal Name"
                                        value={this.state.item.name}
                                        onChange={(e) => this.handleChange("name", e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="carBrand">
                                    <Form.Label>Car Brand</Form.Label>
                                    <Form.Control
                                        as="select"
                                        onChange={(e) => this.handleChange("carbrandId", e.target.value)}
                                    >
                                        <option selected="true" disabled>--Select--</option>
                                        {this.props.carbrands.map((item) => (
                                            <option value={item._id}>{item.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="carBodyTypes">
                                    < Form.Label > Car Body Type</Form.Label>
                                    <Form.Control
                                        as="select"
                                        onChange={(e) => this.handleChange("car_body_type", e.target.value)}
                                    >
                                        <option selected="true" disabled>--Select--</option>
                                        {this.props.car_body_types.map((item) => (
                                            <option value={item.value}>{item.label}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        )
    }
}

export default AddCarModal;
