import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import moment from 'moment';
import callApi from '../../../util/apiCaller';
import Loader from 'react-loader-spinner';

class ModalTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEditable: false,
			item: {},
			brands: [],
			models: [],
			brand_wise_filterd_models: [],
			locations: [],
			apartments: [],
			blocks: [],
			time_slot_names: [],
		};
	}

	componentDidMount() {
		if (this.props.item) {
			this.setState({
				item: this.props.item,
			});
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!_.isEqual(prevProps.item, this.props.item)) {
			this.setState({
				item: this.props.item,
			});
		}
	}

	toggleEdit = () => {
		this.setState({
			isEditable: !this.state.isEditable,
		});
		this.fetchModelsAndBrandsByVehicleType();
		this.fetchLocations();
		this.fetchBlocksByApartmentId(this.props.item.apartment_id);
		this.fetchTimeslotsByAgencyId(this.props.item.agencyId);
	};

	handleChange = (keyName, keyValue) => {
		let item = this.state.item;
		item[keyName] = keyValue;
		this.setState({
			item: item,
		});
		if (keyName === 'brand_name') {
			console.log(keyName, keyValue);
			let vehicle_models = this.state.models.filter((item) => {
				if (item.carbrand === keyValue) {
					return item;
				}
			});
			this.setState({
				brand_wise_filterd_models: vehicle_models
			})
			// Making model empty on change brand
			this.handleChange("model_name", "");
		}
		if (keyName === 'location_id') {
			this.setState({
				apartments: [],
				blocks: [],
			})
			this.fetchApartmentsByLocationId(keyValue);
			// Making apartment_id
			this.handleChange("apartment_id", "");
			this.handleChange("apartment_name", "");
		}
		if (keyName === 'apartment_id') {
			this.fetchBlocksByApartmentId(keyValue);
			this.handleChange("block_id", "");
			this.handleChange("block_name", "");
		}
	};

	fetchModelsAndBrandsByVehicleType = () => {
		if (_.isEmpty(this.state.brands) || _.isEmpty(this.state.models)) {
			if (this.props.item.vehicle_type === 'car') {
				let filters = {
					pageNum: 1,
					pgeSize: 10000,
				}
				callApi(`carbrands/list`, 'post', filters).then((res) => {
					if (res.status === 'Success') {
						this.setState({
							brands: res.data.carbrands,
						});
					}
				});
				callApi(`carmodels/list`, 'post', filters).then((res) => {
					console.log("HII", res.data.carmodels);
					if (res.status === 'Success') {
						this.setState({
							models: res.data.carmodels,
						});
					}
				});

			}
			if (this.props.item.vehicle_type === 'bike') {
				let filters = {
					pageNum: 1,
					pgeSize: 10000,
				}
				callApi(`bikebrands/list`, 'post', filters).then((res) => {
					if (res.status === 'Success') {
						this.setState({
							brands: res.data.bikebrands,
						});
					}
				});
				callApi(`bikemodels/list`, 'post', filters).then((res) => {
					if (res.status === 'Success') {
						this.setState({
							models: res.data.bikemodels,
						});
					}
				});
			}
		}
	}

	fetchLocations = () => {
		if (_.isEmpty(this.state.locations)) {
			let filters = {
				pageNum: 1,
				pgeSize: 10000,
			}
			callApi(`locations/list`, 'post', filters).then((res) => {
				if (res.status === 'Success') {
					this.setState({
						locations: res.data.locations,
					}, () => {
						let location_id = '';
						this.state.locations.map((item) => {
							if (item.name === this.props.item.location_name) {
								location_id = item._id;
							}
						})
						callApi(`locations/locality/${location_id}/apartment-list`, 'post', filters).then((res) => {
							if (res.status === 'Success') {
								console.log("apartments", res.data);
								this.setState({
									apartments: res.data.apartments,
								});
							}
						});
					});
				}
			});
		}
	}

	fetchApartmentsByLocationId = (location_id) => {
		let filters = {
			pageNum: 1,
			pgeSize: 10000,
		}
		callApi(`locations/locality/${location_id}/apartment-list`, 'post', filters).then((res) => {
			if (res.status === 'Success') {
				console.log("apartments", res.data);
				this.setState({
					apartments: res.data.apartments,
				});
			}
		});
	}

	fetchBlocksByApartmentId = (apartment_id) => {
		let filters = {
			pageNum: 1,
			pgeSize: 10000,
		}
		callApi(`locations/apartment/${apartment_id}/block-list`, 'post', filters).then((res) => {
			if (res.status === 'Success') {
				console.log("blocks", res.data);
				this.setState({
					blocks: res.data.blocks,
				});
			}
		});
	}

	fetchTimeslotsByAgencyId = (apartment_id) => {
		let filters = {};
		callApi(`timeslots/agency/${apartment_id}`, 'post', filters).then((res) => {
			console.log("fetchTimeslotsByAgencyId", res.data);
			if (res.status === 'Success') {
				console.log("blocks", res.data);
				this.setState({
					time_slot_names: res.data.timeslots,
				});
			}
		});
	}



	saveDetails = () => {
		let item = this.state.item;
		this.props.updateServicebooking(this.props.item._id, item);
		this.toggleEdit();
	};

	render() {
		if (this.state.isEditable) {
			return (
				<Card>
					<Card.Header>
						<Row>
							<Col xs={6}> {this.props.heading}</Col>
							<Col xs={6}>
								<div className={'text-right'}>
									<Button disabled={this.props.item.isUpdating} onClick={() => this.saveDetails()}>
										{this.props.item.isUpdating ? 'saving' : 'save'}
									</Button>
									&nbsp;
									&nbsp;
									<Button onClick={() => this.toggleEdit()}>close</Button>
								</div>
							</Col>
						</Row>
					</Card.Header>
					{
						this.props.item.isUpdating ? <Loader></Loader> :
							<ListGroup className="list-group-flush">
								<ListGroupItem>
									<Row>
										<Col xs={5}>Booking Number</Col>
										<Col xs={7}>{this.state.item.booking_num}</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Payment Status</Col>
										<Col xs={7}>{this.state.item.isPaymentSuccess ? 'Paid' : 'Unpaid'}</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Vehicle Details</Col>
										<Col xs={7}>
											<Col xs={7}>{this.state.item.vehicle_type}</Col>
											<Col xs={5}>Brand</Col>
											<FormControl
												as="select"
												onChange={(e) => {
													this.handleChange("brand_name", e.target.value);
												}}
											>
												<option selected="true" disabled>{this.state.item.brand_name}</option>
												{this.state.brands.map((item) => (
													<option value={item.name}>{item.name}</option>
												))}
											</FormControl>
											<Col xs={5}>model</Col>
											<FormControl
												as="select"
												onChange={(e) => this.handleChange("model_name", e.target.value)}
											>
												<option selected="true" disabled>{this.state.item.model_name}</option>
												{this.state.brand_wise_filterd_models.map((item) => (
													<option value={item.name}>{item.name}</option>
												))}
											</FormControl>
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>License Number</Col>
										<Col xs={7}>
											<FormControl
												onChange={(e) => this.handleChange('license_number', e.target.value)}
												value={this.state.item.license_number}
											/>
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Location Name</Col>
										<Col xs={7}>
											<FormControl
												as="select"
												onChange={(e) => {
													this.handleChange("location_id", e.target.value);
													this.state.locations.filter((item) => {
														if (item._id === e.target.value) {
															this.handleChange("location_name", item.name);
														}
													})
												}}
											>
												<option selected="true" disabled>{this.state.item.location_name}</option>
												{this.state.locations.map((item) => (
													<option value={item._id}>{item.name}</option>
												))}
											</FormControl>
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Community Name</Col>
										<Col xs={7}>
											<FormControl
												as="select"
												onChange={(e) => {
													this.handleChange("apartment_id", e.target.value);
													this.state.apartments.filter((item) => {
														if (item._id === e.target.value) {
															this.handleChange("apartment_name", item.name);
														}
													})
												}}
											>
												<option selected="true" disabled>{this.state.item.apartment_name}</option>
												{this.state.apartments.map((item) => (
													<option value={item._id}>{item.name}</option>
												))}
											</FormControl>
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Block Name</Col>
										<Col xs={7}>
											<FormControl
												as="select"
												onChange={(e) => {
													this.handleChange("block_id", e.target.value);
													this.state.blocks.filter((item) => {
														if (item._id === e.target.value) {
															this.handleChange("block_name", item.name);
														}
													})
												}}
											>
												<option selected="true" disabled>{this.state.item.block_name}</option>
												{this.state.blocks.map((item) => (
													<option value={item._id}>{item.name}</option>
												))}
											</FormControl>
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Flat Number</Col>
										<Col xs={7}>
											<FormControl
												onChange={(e) => this.handleChange('flat_num', e.target.value)}
												value={this.state.item.flat_num}
											/>
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Parking Slot</Col>
										<Col xs={7}>
											<FormControl
												onChange={(e) => this.handleChange('parking_block', e.target.value)}
												value={this.state.item.parking_block}
											/>
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Time Slot</Col>
										<Col xs={7}>
											<FormControl
												as="select"
												onChange={(e) => this.handleChange('time_slot_name', e.target.value)}
												value={this.state.item.time_slot_name}
											>
												<option selected="true" disabled>{this.state.item.time_slot_name}</option>
												{this.state.time_slot_names.map((item) => (
													<option value={item.name}>{item.name}</option>
												))}
											</FormControl>
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Service Start Date</Col>
										<Col xs={7}>{moment(this.state.item.service_booking_date).format('DD MMM YYYY')}</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Next Payment Date</Col>
										<Col xs={7}>{moment(this.state.item.next_payment_date).format('DD MMM YYYY')}</Col>
									</Row>
								</ListGroupItem>
							</ListGroup>
					}
				</Card>
			);
		} else {
			return (
				<Card>
					<Card.Header>
						<Row>
							<Col xs={6}>{this.props.heading}</Col>
							{/* <Col xs={6}>
								<div className="text-right">
									<Button onClick={() => this.toggleEdit()}>edit</Button>
								</div>
							</Col> */}
						</Row>
					</Card.Header>
					<ListGroup className="list-group-flush">
						<ListGroupItem>
							<Row>
								<Col xs={5}>Booking Number</Col>
								<Col xs={7}>{this.state.item.booking_num}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Payment Status</Col>
								<Col xs={7}>
									{this.state.item.isPaymentSuccess ? 'Paid' : 'Unpaid'}
									{/* {this.state.item.isPaymentFailed ? 'Failed' : ''} */}
								</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Vehicle Details</Col>
								<Col xs={7}>
									{_.startCase(this.state.item.vehicle_type)},{'  '}
									{_.startCase(this.state.item.brand_name)},{'  '}
									{_.startCase(this.state.item.model_name)}
								</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>License Number</Col>
								<Col xs={7}>{this.state.item.license_number}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Location Name</Col>
								<Col xs={7}>{this.state.item.location_name}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Community Name</Col>
								<Col xs={7}>{this.state.item.apartment_name}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Block Name</Col>
								<Col xs={7}>{this.state.item.block_name}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Flat Number</Col>
								<Col xs={7}>{this.state.item.flat_num}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Parking Slot</Col>
								<Col xs={7}>{this.state.item.parking_block}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Time Slot</Col>
								<Col xs={7}>{this.state.item.time_slot_name}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Service Start Date</Col>
								<Col xs={7}>
									{moment(new Date(this.state.item.service_booking_date)).format('DD MMM YYYY')}
								</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Next Payment Date</Col>
								<Col xs={7}>
									{moment(new Date(this.state.item.next_payment_date)).format('DD MMM YYYY')}
								</Col>
							</Row>
						</ListGroupItem>
					</ListGroup>
				</Card>
			);
		}
	}
}

export default ModalTable;
