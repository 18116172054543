import React, { Component } from 'react';
import { Button, Card, FormControl, Modal, Nav, Tab, TabContainer, TabContent, Tabs } from 'react-bootstrap';
import callApi from '../../../util/apiCaller';
import ServicePackageVendorDetails from './ServicePackageVendorDetails';
import AdOnDetails from './AdOnServiceDetails';
import AdOnBookingDetails from './ServicePackageTimeSlotDetails';

class ServicePackageDetailsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSidebarEnabled: true,
            selectedItem: {},
            isLoading: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedItemId !== this.props.selectedItemId) {
            if (this.props.selectedItemId) {
                this.fetchItem(this.props.selectedItemId);
            }
        }
    }

    render() {
        return (
            <Modal
                dialogClassName={'modal-dialog modal-dialog-scrollable modal-lg'}
                show={this.props.isVisible}
                onHide={this.props.close}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.user && this.state.user.name ? this.state.user.name : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ minHeight: '230px' }}>
                        <Card>
                            <TabContainer defaultActiveKey={'Vendor-details'}>
                                <Card.Header>
                                    <Nav variant="pills">
                                        <Nav.Item>
                                            <Nav.Link eventKey="Vendor-details">Vendor Details</Nav.Link>
                                        </Nav.Item>
                                        { <Nav.Item>
                                            <Nav.Link eventKey="adons-details">Ad-On Services Details</Nav.Link>
                                        </Nav.Item> }
                                        { <Nav.Item>
                                            <Nav.Link eventKey="booking-details">Booking Slots</Nav.Link>
                                        </Nav.Item> }
                                        
                                    </Nav>
                                </Card.Header>
                                <Card.Body>
                                    <TabContent>
                                        <Tab.Pane eventKey="Vendor-details" title="Vendor Details">
                                            <ServicePackageVendorDetails
                                                fetchApartments={this.props.fetchPackages}
                                                heading="Ad-On Of Agency"
                                                apartmentId={this.props.selectedItemId}
                                            />
                                        </Tab.Pane>
                                        {<Tab.Pane eventKey="adons-details" title="Ad-On Service Details">
                                            <AdOnDetails
                                                fetchApartments={this.props.fetchPackages}
                                                heading="Ad-On Services"
                                                apartmentId={this.props.selectedItemId}
                                            />
                                        </Tab.Pane> }
                                        {<Tab.Pane eventKey="booking-details" title="Ad-On Booking Slots">
                                            <AdOnBookingDetails
                                                fetchApartments={this.props.fetchPackages}
                                                heading="Booking Slots"
                                                apartmentId={this.props.selectedItemId}
                                            />
                                        </Tab.Pane> }
                                        
                                    </TabContent>
                                </Card.Body>
                            </TabContainer>
                        </Card>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.close}>
                        Close
					</Button>
                    {/* <Button variant="primary">Save Changes</Button> */}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ServicePackageDetailsModal;
