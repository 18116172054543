import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { Button, Card, FormControl, Modal, Nav, Tab, TabContainer, TabContent, Tabs, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import callApi from '../../../../util/apiCaller';
import Calendar from "react-calendar"
import Loading from '../../../../components/Loading';

class GroomingAddonModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div>
                <Card>
                    {/* <Card.Header>
                        Grooming / Addon Services
                    </Card.Header> */}
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Service Name</Col>
                                <Col xs={7}>{this.props.item.name}</Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Status</Col>
                                <Col xs={7}>
                                    {!this.props.item.isBlocked ?
                                        this.props.checkIfServiceRequests(
                                            this.props.item.name,
                                            this.props.item._id
                                                ? this.props.item._id
                                                : null,
                                        ) == 'schedule' ? (
                                            <p>not availed yet.</p>
                                        ) : !this.props.item.isBlocked &&
                                            this.props.checkIfServiceRequests(
                                                this.props.item.name,
                                                this.props.item._id
                                                    ? this.props.item._id
                                                    : null,
                                            ) == 'completed' &&
                                            this.props.serviceRequest[0] &&
                                            this.props.serviceRequest.length > 0 ? (
                                            <p>
                                                Completed on{' '}
                                                {moment(this.props.serviceRequest[0].due_date).format(
                                                    'MMM DD, YYYY',
                                                )}
                                                .
                                            </p>
                                        ) : !this.props.item.isBlocked &&
                                            this.props.checkIfServiceRequests(
                                                this.props.item.name,
                                                this.props.item._id
                                                    ? this.props.item._id
                                                    : null,
                                            ) == 'scheduled' &&
                                            this.props.serviceRequest[0] &&
                                            this.props.serviceRequest.length > 0 ? (
                                            <p>
                                                Rescheduled for{' '}
                                                {moment(this.props.serviceRequest[0].due_date).format(
                                                    'MMM DD, YYYY',
                                                )}
                                                .
                                            </p>
                                        ) : !this.props.item.isBlocked &&
                                            this.props.checkIfServiceRequests(
                                                this.props.item.name,
                                                this.props.item._id
                                                    ? this.props.item._id
                                                    : null,
                                            ) == 'reschedule' &&
                                            this.props.serviceRequest[0] &&
                                            this.props.serviceRequest.length > 0 ? (
                                            <p>
                                                Scheduled for{' '}
                                                {moment(this.props.serviceRequest[0].due_date).format(
                                                    'MMM DD, YYYY',
                                                )}
                                                .
                                            </p>
                                        ) : null : "blocked"}

                                </Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Action</Col>
                                <Col xs={7}>
                                    <p className='text-warning font-weight-bold'>Coming soon ...</p>
                                    {/* <button className='btn btn-primary'>{this.props.checkIfServiceRequests(this.props.item.name)}</button> */}
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </div>
        )
    }
}

export default GroomingAddonModal;