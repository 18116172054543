import React, { Component } from 'react';
import DatePicker from 'react-date-picker';

class Datepicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: new Date(this.props.value),
        }
    }

    handleChange = (value) => {
        this.setState({
            value
        })
        this.props.handleDateChange(value);
    }

    render() {
        return (
            <div>
                <DatePicker
                    onChange={(e) => {
                        if (e != null) {
                            this.handleChange(e)
                        } else {
                            this.setState({
                                value: ''
                            })
                            this.handleChange(['', ''])
                        }
                    }}
                    value={this.state.value}
                />
            </div>
        );
    }
}


export default Datepicker;
