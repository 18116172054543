import React, { Component } from 'react';
import callApi from '../../../util/apiCaller';
import ListTable from './components/ListTable';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import AddCarModal from './components/AddCarModal';
import downloadApi from "../../../util/apiDownloader";
import moment from "moment";


class CarModels extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageNum: 1,
			pageSize: 25,
			totalCount: 0,
			items: [],
			carbrands: [],
			car_body_types: [],
			isAddCarModalVisible: false
		};
	}

	componentDidMount() {
		this.fetchCars();
		this.fetchCarTypes();
		this.fetchCarbrands();
	}

	fetchCars = () => {
		callApi('carmodels/list', 'post', {
			pageNum: this.state.pageNum,
			pageSize: this.state.pageSize,
		}).then((res) => {
			this.setState({
				items: res.data.carmodels,
			});
		});
	};

	fetchCarbrands = () => {
		callApi('carbrands/list', 'post', {
			pageNum: 1,
			pageSize: 10000,
		}).then((res) => {
			this.setState({
				carbrands: res.data.carbrands,
			});
		});
	};

	fetchCarTypes = () => {
		callApi('cars/car-body-list', 'get').then((res) => {
			this.setState({
				car_body_types: res.data.car_body_types,
			});
		});
	};

	deleteItem = (id) => {
		let items = this.state.items.map((item) => {
			if (item._id === id) {
				item.isDeletingItem = true;
			}
			return item;
		});
		this.setState(
			{
				items: items,
			},
			() => {
				callApi(`carmodels/${id}/remove`, 'post', {}).then((res) => {
					if (res.status === 'Success') {
						let items = this.state.items.filter((item) => item._id !== id);
						this.setState({
							items: items,
							totalCount: this.state.totalCount - 1,
						});
					}
				});
			}
		);
	};

	addItem = (item) => {
		callApi(`admin/carmodel/add`, 'post', {
			...item
		}).then((res) => {
			if (res.status === 'Success') {
				let items = this.state.items;
				items.unshift(res.data.carModel);
				this.setState({
					items: items,
				});
			}
		});
	};

	updateItem = (id, item) => {
		let items = this.state.items.map((item) => {
			if (item._id === id) {
				item.isUpdating = true;
			}
			return item;
		});
		this.setState(
			{
				items: items,
			},
			() => {
				callApi(`carmodels/${id}/update`, 'post', {
					carmodel: item,
				}).then((res) => {
					if (res.status === 'Success') {
						let items = this.state.items.map((item) => {
							if (item._id === id) {
								return res.data.carmodel;
							} else {
								return item;
							}
						});
						this.setState({
							items: items,
						});
					}
				});
			}
		);
	};

	addCarHandler = () => {
		this.setState({
			isAddCarModalVisible: true
		})
	}

	closeModal = () => {
		this.setState({
			isAddCarModalVisible: false,
		});
	};

	getFilters = () => {
		let input = {}; input.query = this.state.query;
		input.selected_filter = this.state.selectedTab;
		input.pageNum = this.state.pageNum;
		input.pageSize = this.state.pageSize;
		return input;
	};

	downloadData = () => {
		console.log('Download pressed');
		this.setState({
			isDownloadingData: true,
		});
		let filters = this.getFilters();
		downloadApi(`Cars-${moment().format('DD-MM-YYYY-HH:mm')}.csv`, `/admin/carmodels/dump`, 'POST', filters).then(
			(blob) => {
				this.setState({
					isDownloadingData: false,
				});
				console.log(JSON.stringify(blob));
			}
		);
	};

	render() {
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box d-flex align-items-center justify-content-between">
							<h4 className="mb-0">Car Models</h4>
							<div className="page-title-right">
								<Button onClick={() => this.addCarHandler()}>
									<i className="fa fa-plus"></i> Add
								</Button>
								&nbsp;
								&nbsp;
								<Button disabled={this.state.isDownloadingData}
										onClick={() => this.downloadData()}>
									{this.state.isDownloadingData ? 'Downloading' : 'Download Data'}
								</Button>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-body">
								<ListTable
									carbrands={this.state.carbrands}
									car_body_types={this.state.car_body_types}
									changePageNum={this.changePageNum}
									handleQuery={this.handleQuery}
									handleDetails={this.handleDetails}
									updateItem={this.updateItem}
									deleteItem={this.deleteItem}
									changePageSize={this.changePageSize}
									query={this.state.query}
									items={this.state.items}
									pageSize={this.state.pageSize}
									totalCount={this.state.totalCount}
									pageNum={this.state.pageNum}
								/>
							</div>
						</div>
					</div>
				</div>
				{this.state.isAddCarModalVisible ? (
					<AddCarModal
						isVisible={this.state.isAddCarModalVisible}
						close={this.closeModal}
						addItem={this.addItem}
						carbrands={this.state.carbrands}
						car_body_types={this.state.car_body_types}
					/>
				) : null}
			</div>
		);
	}
}

export default CarModels;
