import React, { Component } from 'react';
import callApi from '../../../util/apiCaller';
import ListTable from './components/ListTable';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import AddLocationModal from './components/AddLocationModal';
import downloadApi from "../../../util/apiDownloader";
import moment from "moment";


class LocationsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,
            pageSize: 25,
            totalCount: 0,
            items: [],
            isAddLocationModalVisible: false,
            isAddingLocation: false
        };
    }

    componentDidMount() {
        this.fetchLocations();
    }

    fetchLocations = () => {
        callApi(`locations/list`, 'post', {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    items: res.data["locations"]
                })
            }
            else {
                console.log("error")
            }
        })
    };

    deleteItem = (id) => {
        let items = this.state.items.map((item) => {
            if (item._id === id) {
                item.isDeletingItem = true;
            }
            return item;
        });
        this.setState(
            {
                items: items,
            },
            () => {
                callApi(`admin/location/${id}/delete`, 'post', {})
                    .then((res) => {
                        if (res.status === 'Success') {
                            let items = this.state.items.filter((item) => item._id !== id);
                            this.setState({
                                items: items,
                                totalCount: this.state.totalCount - 1,
                            });
                        }
                    });
            }
        );
    };

    addItem = (item) => {
        this.setState({ isAddingLocation: true });
        callApi(`admin/location/add`, 'post', {
            ...item
        }).then((res) => {
            if (res.status === 'Success') {
                let items = this.state.items;
                items.unshift(res.data.location);
                this.setState({
                    items: items,
                    totalCount: this.state.totalCount + 1,
                });
                this.setState({ isAddingLocation: false });
            }
        });
    };

    updateItem = (id, item) => {
        let items = this.state.items.map((item) => {
            if (item._id === id) {
                item.isUpdating = true;
            }
            return item;
        });
        this.setState(
            {
                items: items,
            },
            () => {
                callApi(`admin/location/${id}/update`, 'post', {
                    ...item,
                }).then((res) => {
                    if (res.status === 'Success') {
                        let items = this.state.items.map((item) => {
                            if (item._id === id) {
                                return res.data.location;
                            } else {
                                return item;
                            }
                        });
                        this.setState({
                            items: items,
                        });
                    }
                });
            }
        );
    };

    addLocationHandler = () => {
        this.setState({
            isAddLocationModalVisible: true
        })
    }

    closeModal = () => {
        this.setState({
            isAddLocationModalVisible: false,
        });
    };

    getFilters = () => {
        let input = {}; input.query = this.state.query;
        input.selected_filter = this.state.selectedTab;
        input.pageNum = this.state.pageNum;
        input.pageSize = this.state.pageSize;
        return input;
    };

    downloadData = () => {
        console.log('Download pressed');
        this.setState({
            isDownloadingData: true,
        });
        let filters = this.getFilters();
        downloadApi(`Locations-${moment().format('DD-MM-YYYY-HH:mm')}.csv`, `/admin/locations/dump`, 'POST', filters).then(
            (blob) => {
                this.setState({
                    isDownloadingData: false,
                });
                console.log(JSON.stringify(blob));
            }
        );
    };

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="mb-0">Locations List</h4>
                            <div className="page-title-right">
                                <Button onClick={() => this.addLocationHandler()} disabled={this.state.isAddingLocation}>
                                    <i className="fa fa-plus"></i> {this.state.isAddingLocation ? "Adding" : "Add"}
                                </Button>
                                &nbsp;
                                &nbsp;
                                <Button disabled={this.state.isDownloadingData}
                                        onClick={() => this.downloadData()}>
                                    {this.state.isDownloadingData ? 'Downloading' : 'Download Data'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <ListTable
                                    updateItem={this.updateItem}
                                    deleteItem={this.deleteItem}
                                    query={this.state.query}
                                    items={this.state.items}
                                    pageSize={this.state.pageSize}
                                    totalCount={this.state.totalCount}
                                    pageNum={this.state.pageNum}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isAddLocationModalVisible ? (
                    <AddLocationModal
                        isVisible={this.state.isAddLocationModalVisible}
                        close={this.closeModal}
                        addItem={this.addItem}
                    />
                ) : null}
            </div>
        );
    }
}

export default LocationsDetails;
