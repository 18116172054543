import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import moment from 'moment';

class ListAddress extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEditable: false,
			item: {},
		};
	}

	componentDidMount() {
		if (this.props.item) {
			this.setState({
				item: this.props.item,
			});
		}
	}

	render() {
			return (
				<Card>
					<Card.Header>
						<Row>
							<Col xs={6}>{this.props.heading}</Col>
						</Row>
					</Card.Header>
					<ListGroup className="list-group-flush">
                    <ListGroupItem>
							<Row>
								<Col xs={5}>Address name</Col>
								<Col xs={7}>{this.state.item && this.state.item.address_name  ? this.state.item.address_name : '--NA--'}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Location</Col>
								<Col xs={7}>{this.state.item && this.state.item.location_id && this.state.item.location_id.name ? this.state.item.location_id.name: '--NA--'}</Col>
							</Row>
						</ListGroupItem>
                        <ListGroupItem>
							<Row>
								<Col xs={5}>Community</Col>
								<Col xs={7}>{this.state.item && this.state.item.community_id && this.state.item.community_id.name ? this.state.item.community_id.name : '--NA--'}</Col>
							</Row>
						</ListGroupItem>
                        <ListGroupItem>
							<Row>
								<Col xs={5}>Block</Col>
								<Col xs={7}>{this.state.item  && this.state.item.block_id && this.state.item.block_id.name ? this.state.item.block_id.name : '--NA--'}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Flat No</Col>
								<Col xs={7}>{this.state.item && this.state.item.flat_no ? this.state.item.flat_no : '--NA--'}</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem>
							<Row>
								<Col xs={5}>Parking Slot</Col>
								<Col xs={7}>{this.state.item && this.state.item.parking_slot ? this.state.item.parking_slot : '--NA--'}</Col>
							</Row>
						</ListGroupItem>
                        <ListGroupItem>
							<Row>
								<Col xs={5}>Pillar No</Col>
								<Col xs={7}>{this.state.item && this.state.item.pillar_no ? this.state.item.pillar_no : '--NA--'}</Col>
							</Row>
						</ListGroupItem>
                        <ListGroupItem>
							<Row>
								<Col xs={5}>Basement</Col>
								<Col xs={7}>{this.state.item && this.state.item.basement ? this.state.item.basement : '--NA--'}</Col>
							</Row>
						</ListGroupItem>
					</ListGroup>
				</Card>
			);
		}
}

export default ListAddress;
