import React, { Component } from "react";
import { Button, Card, Modal, Form } from 'react-bootstrap';
import callApi from '../../../util/apiCaller';

class AddBikeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: {
                name: ""
            }
        }
    }

    componentDidMount() {
        
        this.setState({
            agencyId: this.props.agencyId,
            item: this.props.timeslotId
        });
        
        console.log(this.state.item);
        
    }

    handleChange = (keyName, keyValue) => {
        let item = this.state.item;
        item[keyName] = keyValue;
                
        this.setState({
            item: item,
        });
    };

    addSlotItem = (item) => {
        
        console.log(item);
		/*callApi(`servicepackages/new`, 'post', {
			servicepackage: item,
		}).then((res) => {
			if (res.status === 'Success') {
				this.fetchPackages();
			}
		});*/
        };
    
    saveDetails = (id) => {
        let slot_model = this.state.item;
        slot_model['agencyId'] = this.props.agencyId;
        callApi(`timeslots/${id}/update`, 'post', {
			timeslot: slot_model,
		}).then((res) => {
			if (res.status === 'Success') {
				this.props.close();
			}
        });
        
        console.log(slot_model); 
    };

    submitHandler = () => {
        let slot_model = this.state.item;
        this.addSlotItem(slot_model);
        //this.props.slotclose();
    };
    render() {
        return (
            <Modal
                dialogClassName={'modal-dialog modal-dialog-scrollable modal-md'}
                show={this.props.isVisible}
                onHide={this.props.close}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Time Slot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="d-flex justify-content-center">
                        
                        <Form >
                                <Form.Group controlId="bikeModalName1">
                                    <Form.Label>Display name</Form.Label>
                                    <Form.Control
                                            type="text"
                                            placeholder="Enter Display Name"
                                            value={this.state.item.display_name}
                                            onChange={(e) => this.handleChange("display_name", e.target.value)}
                                        />
                                </Form.Group>
                                <Form.Group controlId="bikeModalName2">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                            type="text"
                                            placeholder="Enter Name"
                                            value={this.state.item.name}
                                            onChange={(e) => this.handleChange("name", e.target.value)}
                                        />
                                </Form.Group>
                                <Form.Group controlId="bikeModalName3">
                                    <Form.Label>Start</Form.Label>
                                    <Form.Control
                                            type="text"
                                            placeholder="Enter Start"
                                            value={this.state.item.start}
                                            onChange={(e) => this.handleChange("start", e.target.value)}
                                        />
                                </Form.Group>
                                <Form.Group controlId="bikeModalName4">
                                    <Form.Label>End</Form.Label>
                                    <Form.Control
                                            type="text"
                                            placeholder="Enter End"
                                            value={this.state.item.end}
                                            onChange={(e) => this.handleChange("end", e.target.value)}
                                        />
                                </Form.Group>
                                                                
                                <Form.Group controlId="bikeModalName4">
                                    <Form.Label>&nbsp;</Form.Label>
                                    <Form.Control
                                            type="hidden"
                                            placeholder="Enter End"
                                            value={this.state.item.agencyId}
                                            onChange={(e) => this.handleChange("agencyId", e.target.value)}
                                        />
                                </Form.Group>

                                <Button disabled={this.state.isUpdatingCommunity} onClick={() => this.saveDetails(this.state.item._id)}>
                                        {this.state.isUpdatingCommunity ? 'saving' : 'Update'}
                                 </Button>

                                 {/* <Button variant="primary" type="submit">
                                    Submit
                                </Button>  */}
                            </Form>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        )
    }
}

export default AddBikeModal;
