import React, { Component } from 'react';
import callApi from '../../util/apiCaller';
import downloadApi from "../../util/apiDownloader";
import moment from "moment";
import ListTable from './components/ListTable';
import _, { filter } from 'lodash';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import AddServicePackageModal from './components/AddServicePackage';
import PackageDetailsModal from './components/ServicePackageDetails'

class ServicePackageAll extends Component {
	constructor(props) {
		super(props);
		this.state = {
            pageNum: 1,
            pageSize: 25,
            totalCount: 0,
			packages: [],
            locations: [],
            apartments: [],
            count: 0,
            name: "",
            quantity: 0,
            cost:0,
            car_type:"",
            descriptions: [],
            product_used: [],
            adons:[{quantity:"",name: ""}],
            car_type_prices:[{car_type:"",cost: ""}],
            servicelist:[],
            car_body_types:[{}],
            agencies:[{}],
		}
	}

	componentDidMount() {
        this.fetchPackages();
        this.fetchDataApartments();
        this.fetchLocations();
        this.fetchServices();
        this.fetchCarTypes();
        this.fetchAgency();
	}

    fetchCarTypes = () => {
		callApi('cars/car-body-list', 'get').then((res) => {
			this.setState({
				car_body_types: res.data.car_body_types,
			});
		});
    };

    fetchPackages = () => {
        let filters = this.getFilters();
        console.log(this.state.query);
        callApi(`servicepackages/list`, 'post', {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            selected_filter: filters || {},
            qtext: this.state.query
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    items: res.data["servicepackages"],
                    totalCount: res.data.count
                }, () => {
                    console.log("fetched_packages", this.state.items);
                })
            }
            else {
                console.log("error")
            }
        })
	};
	
	// fetchPackages = () => {
	// 	callApi('servicepackages/list', 'post', {
	// 		pageNum: 1,
	// 		pageSizee: 100
	// 	}).then((res) => {
	// 		this.setState({
	// 			packages: res.data['servicepackages'],
	// 			count: res.data.count,
	// 		})
	// 	});

	// }

	onSelect = () => {

	}

	getFilters = () => {
		let input = {}; input.query = this.state.query;
		input.selected_filter = this.state.selectedTab;
		input.pageNum = this.state.pageNum;
		input.pageSize = this.state.pageSize;
		return input;
	};

	downloadData = () => {
		console.log('Download pressed');
		this.setState({
			isDownloadingData: true,
		});
		let filters = this.getFilters();
		downloadApi(`Cars-${moment().format('DD-MM-YYYY-HH:mm')}.csv`, `/admin/carmodels/dump`, 'POST', filters).then(
			(blob) => {
				this.setState({
					isDownloadingData: false,
				});
				console.log(JSON.stringify(blob));
			}
		);
	};

    fetchDataApartments = () => {
        callApi(`apartments/list`, 'post', {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    apartments: res.data["apartments"]
                })
                //console.log(this.state.apartments)
                
            }
            else {
                console.log("error")
            }
        })
    };

    fetchServices = () => {
        callApi(`servicelists/list`, 'post', {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    servicelist: res.data["servicelists"]
                })
                console.log(this.state.servicelist);
            }
            else {
                console.log("error")
            }
        })
    };

    

    fetchAgency = () => {
        let filters = this.getFilters();
        console.log(this.state.query);
        callApi(`agencys/list`, 'post', {
            pageNum: 0,
            pageSize: 10000,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    agencies: res.data["agencys"],
                    totalCount: res.data.count
                }, () => {
                    console.log("fetched_agencies", this.state.agencies);
                })
            }
            else {
                console.log("error")
            }
        })
    };

	fetchLocations = () => {
        callApi(`locations/list`, 'post', {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    locations: res.data["locations"]
                })
            }
            else {
                console.log("error")
            }
        })
    };

	handleDetails = (id) => {
        this.setState({
            selectedItemId: id,
            isDetailsModalVisible: true,
        });
    };

    changePageNum = (num) => {
        this.setState(
            {
                pageNum: num,
            },
            () => {
                let filters = this.getFilters();
                this.fetchPackages(filters);
            }
        );
    };

    handleChange = (keyName, keyValue) => {
        let item = {};
        item[keyName] = keyValue;
        this.setState(item);
    };

    handleQuery = (val) => {
        // val = _.kebabCase(val);
        this.setState(
            {
                query: val,
            },
            () => {
                this.debounceSearch();
            }
        );
    };

    handleSearch = () => {
        this.fetchPackages();
    };

    addServicePackageHandler = () => {
        this.setState({
            isAddServicePackageModalVisible: true
        })
    }

    closeModal = () => {
        this.setState({
            isDetailsModalVisible: false,
            selectedItemId: '',
            isAddServicePackageModalVisible: false,
        });
        this.fetchPackages();
    };
    addItem = (item) => {
		callApi(`admin/servicepackage/add`, 'post',item).then((res) => {
			if (res.status === 'Success') {
				this.fetchPackages();
			}
		});
    };
    
    deleteItem = (id) => {
		let items = this.state.items.map((item) => {
			if (item._id === id) {
				item.isDeletingItem = true;
			}
			return item;
		});
		this.setState(
			{
				items: items,
			},
			() => {
				callApi(`servicepackages/${id}/remove`, 'post', {}).then((res) => {
					if (res.status === 'Success') {
						this.fetchPackages();
					}
				});
			}
		);
	};

	render() {
        let locations = this.state.locations.map(item => ({ value: item._id, label: item.name }));
        let status = [
            { value: "", label: "All" },
            { value: "true", label: "Active" },
            { value: "false", label: "In Active" }
        ]
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="mb-0">Packages List</h4>
                            <div className="page-title-right">
                                {/* <Button className="mr-3" type="button" data-toggle="collapse" data-target="#multiCollapseExample1" aria-expanded="false" aria-controls="multiCollapseExample2"><i className="fa fa-filter"></i></Button> */}
                                <Button className="mr-3" onClick={() => this.addServicePackageHandler()} disabled={this.state.isAddingServicePackage}>
                                    <i className="fa fa-plus"></i> {this.state.isAddingServicePackage ? "Adding" : "Add"}
                                </Button> 
                                {/* <Button onClick={() => this.downloadData()} disabled={this.state.isDownloadingData}>
                                    {this.state.isDownloadingData ? "Downloading" : "Download Data"}
                                </Button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="collapse multi-collapse" id="multiCollapseExample1">
                    <div className="card card-body">
                        <h5>Apply Filters</h5>
                        <div className="container mt-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Select Location</label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        onChange={(value) => this.handleChange("filter_locations", value)}
                                        isMulti
                                        options={locations}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>Select Status</label>
                                    <Select
                                        onChange={(value) => this.handleChange("filter_status", value)}
                                        options={status}
                                        defaultValue={status[0]}
                                    />
                                </div>
                            </div>
                        </div>
                        <Button className="w-25 mx-auto mt-3" onClick={() => this.fetchApartments()}>
                            Apply
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <ListTable
                                    changePageNum={this.changePageNum}
                                    handleQuery={this.handleQuery}
                                    updateItem={this.updateItem}
                                    deleteItem={this.deleteItem}
                                    handleDetails={this.handleDetails}
                                    query={this.state.query}
                                    items={this.state.items}
                                    locations={this.state.locations}
                                    pageSize={this.state.pageSize}
                                    totalCount={this.state.totalCount}
                                    pageNum={this.state.pageNum}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isAddServicePackageModalVisible ? (
                    <AddServicePackageModal
                        isVisible={this.state.isAddServicePackageModalVisible}
                        close={this.closeModal}
                        addItem={this.addItem}
                        locations={this.state.locations}
                        apartments={this.state.apartments}
                        descriptions={this.state.descriptions}
                        product_used={this.state.product_used}
                        adons = {this.state.adons}
                        car_type_prices={this.state.car_type_prices}
                        servicelist={this.state.servicelist}
                        car_body_types={this.state.car_body_types}
                        agencies={this.state.agencies}
                    />
                ) : null}
                {this.state.isDetailsModalVisible ? (
                    <PackageDetailsModal
                        isVisible={this.state.isDetailsModalVisible}
                        selectedItemId={this.state.selectedItemId}
                        close={this.closeModal}
                        fetchApartments={this.fetchPackages}
                    />
                ) : null}
            </div>
        );
    }
}

export default ServicePackageAll;