import React, { Component } from 'react';

class ListTableHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSidebarEnabled: true
        };
    }


    toggleSidebar = () => {
        this.setState({
            isSidebarEnabled: !this.state.isSidebarEnabled
        });
        if (this.state.isSidebarEnabled) {
            document.body.className = 'vertical-collpsed';
        } else {
            document.body.className = 'sidebar-enable';
        }
    };

    render() {
        return (
            <thead className="thead-light">
                <tr role="row">
                    <th style={{ width: '28px' }} className="sorting_disabled" rowSpan={1}
                        colSpan={1} aria-label="&nbsp;">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input"
                                id="ordercheck" />
                            <label className="custom-control-label"
                                htmlFor="ordercheck">&nbsp;</label>
                        </div>
                    </th>
                    
                    <th className="sorting" tabIndex={0}
                        aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1}
                        style={{ width: '69px' }}
                        aria-label="Date: activate to sort column ascending">Date
                </th>
                    <th className="sorting" tabIndex={0}
                        aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1}
                        style={{ width: '83px' }}
                        aria-label="Billing Name: activate to sort column ascending">Customer
                        Name
                </th>
                    <th className="sorting" tabIndex={0}
                        aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1}
                        style={{ width: '83px' }}
                        aria-label="Community Name: activate to sort column ascending">Service Booking
                </th>
                   
                   
                 
                    <th className="sorting" tabIndex={0}
                        aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1}
                        style={{ width: '83px' }}
                        aria-label="Vehicle Type: activate to sort column ascending">Refund Amount
                </th>
                    <th className="sorting" tabIndex={0}
                        aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1}
                        style={{ width: '32px' }}
                        aria-label="Total: activate to sort column ascending">Refund Date
                </th>
                    {/* <th className="sorting" tabIndex={0}
                        aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1}
                        style={{ width: '104px' }}
                        aria-label="Payment Status: activate to sort column ascending">Payment
                        Status
                </th> */}
                    <th className="sorting" tabIndex={0}
                        aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1}
                        style={{ width: '80px' }}
                        aria-label="Invoice: activate to sort column ascending">Actions
                </th>
                    <th style={{ width: '56px', display: 'none' }}
                        className="sorting_disabled" rowSpan={1} colSpan={1}
                        aria-label="Action">Action
                </th>
                </tr>
            </thead>
        );
    }
}

export default ListTableHeader;
