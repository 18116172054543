import React, { Component } from 'react'
import _ from 'lodash'
export default class StaffTable extends Component {
    constructor(props) {
		super(props);

	}
	render() {
		return (
			<div className="card">
				<div className="card-body">
		<h4 className="card-title">{_.toUpper(this.props.name)}</h4>
					<p className="card-title-desc">To add an entry navigate from SideBar </p>
					<div className="table-responsive">
						<table className="table mb-0">
							<thead>
								<tr>
									{this.props.headings && this.props.headings.map((item, index) => (
										<th>{item}</th>
									))}
								</tr>
							</thead>
							<tbody>
								{this.props.rows && this.props.rows.map((item, index) => (
									<tr>
										<th scope="row">{index + 1}</th>
										<td>{item.name}</td>
                                        <td>{item.email}</td>
                                        <td>{item.phone}</td>
                                        <td>{item.location_name}</td>
                                        <td>{item.apartment_name}</td>
										
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

		);
	}
}
