import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import ServicebookingsPage from './modules/Bookings/Servicebookings/ServicebookingsPage';
import Footer from './components/Footer';
import ServicePackageAll from './modules/ServicePackages/ServicePackageAll';
import AdOnServices from './modules/AdOnServices/AdOnServices';
import _ from 'lodash';
import ServicePackageAdd from './modules/ServicePackages/ServicePackageAdd';
import ServicePackageAddAgency from './modules/ServicePackages/ServicePackageAddAgency';
import ShowStaff from './modules/Staff/ShowStaff';
import AddStaff from './modules/Staff/AddStaff';
import CarModels from './modules/Vehicles/CarModels/CarModels';
import BikeModels from './modules/Vehicles/BikeModels/BikeModels';
//import _  from 'lodash';
import LoginPage from './modules/App/LoginPage/LoginPage';
import ProfilePage from './modules/App/ProfilePage/ProfilePage';
import DashboardPage from './modules/App/Dashboard/DashboardPage';
import callApi from './util/apiCaller';
import AddPlaces from './modules/Places/AddPlaces';
import ShowPlaces from './modules/Places/ShowPlaces';
import LocationsDetails from './modules/Places/LocationsDetail/LocationsDetails';
import ApartmentsDetail from './modules/Places/ApartmentsDetail/ApartmentsDetail'
import BlocksDetail from './modules/Places/BlocksDetail/BlocksDetail'
import Customer from './modules/Customer/Customer';
import VendorsList from './modules/Vendors/VendorDetail';
import Supervisors from './modules/Vendors/Supervisors/Supervisors';
import Cleaners from './modules/Vendors/Cleaners/Cleaners';
import Refunds from './modules/Refunds/Refunds';
import Payments from './modules/Payments/Payment';
import SpecialTasks from './modules/Customer/SpecialTasks';
import ServiceBookingDetails from './modules/Bookings/Servicebookings/ServiceBookingDetails';

const PrivateRoute = ({ Component: component, isAuthenticated: isAuthenticated, ...rest }) => (
    <Route {...rest} render={(props) => (
        isAuthenticated
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/login',
                state: { _refurl: props.location },
            }} />
    )} />
);

class App extends Component {

    constructor() {
        super();
        this.state = {
            isAuthenticated: false
        }
    }

    componentDidMount() {
        let token = window.localStorage.getItem('token');
        if (token && token !== '') {
            this.setState({
                isLoading: true
            });
            this.verifyToken(token, (err, user) => {
                if (err) {
                    window.localStorage.removeItem('token');
                } else {
                    this.isAuthenticated = true;
                    let model = {
                        user: user
                    };
                    if (!_.isEmpty(model.user)) {
                        this.setState({
                            isMounted: true,
                            isLoading: false,
                            isAuthenticated: true,
                            userid: model.user._id,
                            token: token,
                            user: model.user,
                        });
                    }
                }
            });
        }
    }

    verifyToken = (token, next) => {
        callApi(`auth/verify`, 'post', {
            token: token
        }).then(res => {
            if (res.data.isUserFound) {
                let user = res.data.user;
                return next(null, user);
            } else {
                return next('Unauthorized');
            }
        });
    };

    logoutUser = () => {
        window.localStorage.removeItem('token');
        this.isAuthenticated = false;
        window.location = '/';
    };

    render() {
        return (
            <div id="layout-wrapper">
                <Router>
                    {/* {this.state.isAuthenticated ? */}
                        <Header
                            logoutUser={this.logoutUser}
                            user={this.state.user}
                            isAuthenticated = {this.state.isAuthenticated}
                        /> 
                        {/* : null
                    } */}
                    {this.state.isAuthenticated ?
                        <Sidebar /> : null
                    }
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <Switch>
                                    <Route
                                        exact path="/"
                                        render={props => <LoginPage
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />
                                    <Route
                                        exact path="/login"
                                        render={props => <LoginPage
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />

                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/dashboard"
                                        component={props => <DashboardPage
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />

                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/refunds"
                                        component={props => <Refunds
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />

                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/servicepackages"
                                        component={props => <ServicePackageAll
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />
                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/adonservices"
                                        component={props => <AdOnServices
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />
                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/servicepackageadd"
                                        component={props => <ServicePackageAdd
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />
                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/servicepackageaddagency"
                                        component={props => <ServicePackageAddAgency
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />
                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/customer/bookings"
                                        component={props => <ServicebookingsPage
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />
                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/customer/bookings/details"
                                        component={props => <ServiceBookingDetails
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.state.user}
                                        />}
                                    />
                                    {/* <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/customer/payments"
                                        component={props => <Payments
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    /> */}
                                    {/* <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/customer/special-tasks"
                                        component={props => <SpecialTasks
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    /> */}

                                    {/* <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/vendors"
                                        component={props => <VendorsList
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    /> */}

                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/vendors"
                                        component={props => <VendorsList
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />

                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact
                                        path="/vendors/supervisors"
                                        component={props => <Supervisors
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />
                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/vendors/cleaners"
                                        component={props => <Cleaners
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />

                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/staff"
                                        component={props => <AddStaff
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />
                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/staff/:type"
                                        component={props => <ShowStaff
                                            {...props}
                                            agencyId="5efc5e6adf429d30402574bf"
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />
                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact
                                        path="/vehicles/cars"
                                        component={props => <CarModels
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />
                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/vehicles/bikes"
                                        component={props => <BikeModels
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />
                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/places"
                                        component={props => <AddPlaces
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />
                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/places/locations"
                                        component={props => <LocationsDetails
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />
                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/places/communities"
                                        component={props => <ApartmentsDetail
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />
                                    <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        exact path="/places/blocks"
                                        component={props => <BlocksDetail
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    />

                                    {/*<PrivateRoute*/}
                                    {/*    isAuthenticated={this.state.isAuthenticated}*/}
                                    {/*    path="/places/:spot"*/}
                                    {/*    component={props => <ShowPlaces*/}
                                    {/*        {...props}*/}
                                    {/*        isAuthenticated={this.state.isAuthenticated}*/}
                                    {/*        isLoggingIn={this.state.isLoggingIn}*/}
                                    {/*        loginUser={this.loginUser}*/}
                                    {/*    />}*/}
                                    {/*/>*/}
                                    {/* <PrivateRoute
                                        isAuthenticated={this.state.isAuthenticated}
                                        path="/customer"
                                        component={props => <Customer
                                            {...props}
                                            isAuthenticated={this.state.isAuthenticated}
                                            isLoggingIn={this.state.isLoggingIn}
                                            loginUser={this.loginUser}
                                        />}
                                    /> */}

                                </Switch>
                            </div>
                        </div>
                    </div>
                    {this.state.isAuthenticated ?
                        <Footer /> :
                        null
                    }
                </Router>
            </div>
        );
    }
}

export default App;
