import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, Form, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import callApi from '../../../util/apiCaller';
import AddTimeSlotModal from './AddTimeSlotModal';

class VendorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditable: false,
            item: {},
            isLoading: true,
            isUpdatingCommunity: false,
            updateObject: {},
            listItems: {},
            pageNum: 1,
            pageSize: 25,
            totalCount: 0,
            query: '',
            apartmentList: {},
            apartments: {},
            timeslotsList:[],
            isAddTimeSlotModalVisible: false,
        };
    }

    componentDidMount() {
        console.log("selected Id", this.props.apartmentId);
        if (this.props.apartmentId) {
            this.fetchPackages(this.props.apartmentId);
        }
        
    }
    fetchTimeSlots = (agencyId) =>{
        //let filters = {"agencyId":"5efc5e6adf429d30402574bf"};
        //console.log(filters);
        console.log(agencyId)
        callApi(`timeslots/agency/list`, 'post', {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            agencyId: agencyId
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    timeslotsList: res.data['timeslots'],
                    totalCount: res.data.count
                }, () => {
                    console.log("fetched_timeslots", this.state.timeslotsList);
                })
            }
            else {
                console.log("error")
            }
        })
        
    }
    fetchApartments = () => {
        let filters = '';
        console.log(filters);
        callApi(`apartments/list`, 'post', {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            selected_filter: filters || {},
            query: this.state.query
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    apartmentList: res.data,
                    totalCount: res.data.count
                }, () => {
                    console.log("fetched_apartments", this.state.apartmentList);
                })
            }
            else {
                console.log("error")
            }
        })
    };

    fetchPackages = (id) => {
        this.setState(
            {
                isLoading: true,
            },
            () => {
                callApi(`servicepackages/${id}`, 'get').then((res) => {
                    console.log(res);
                    if (res.status === 'Success') {
                        console.log("response", res.data);
                        this.setState({
                            isLoading: false,
                            servicepackages: res.data
                        });
                        this.fetchTimeSlots(this.state.servicepackages.servicepackage.agencyId)
                    }
                });
            }
        );
    };

    

    toggleEdit = () => {
        this.setState({
            isEditable: !this.state.isEditable,
        });
    };

    handleChange = (keyName,keyValue,i) => {
        let item = this.state.timeslotsList;
        console.log(item)
        item[i][keyName] = keyValue;
        this.setState({
            updateObject: item,
        });
       
        console.log(this.state.updateObject);
    };

    saveDetails = () => {
        let item = this.state.servicepackages.servicepackage;
        console.log('aprtments');
        console.log(item);
        this.updateCommunity(this.state.servicepackages.servicepackage._id, item);
    };

    saveDetailsNew = () => {
        let slot_model = this.state.item;
        slot_model['agencyId'] = this.state.servicepackages.servicepackage.agencyId;
        callApi(`timeslots/new`, 'post', {
			timeslot: slot_model,
		}).then((res) => {
			if (res.status === 'Success') {
				this.setState({
                    isUpdatingCommunity: false,
                });
                this.toggleEdit();
			}
        });
        
        console.log(slot_model); 
    };

    updateCommunity = (id, servicepackage) => {
        this.setState({
            isUpdatingCommunity: true,
        });
        console.log(servicepackage);
        callApi(`servicepackages/${id}/update`, 'post', {
            servicepackage,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    isUpdatingCommunity: false,
                });
                this.toggleEdit();
                this.fetchPackages(id);
            } else {
                this.setState({
                    isUpdatingCommunity: false,
                });
                this.toggleEdit();
            }
        });
    };

    handleRemoveSlot = idx => () => {
        let item = this.state.timeslot;
        console.log(item);
        item = this.state.timeslotsList.filter((s, sidx) => idx !== sidx);
               
        this.setState({
            timeslotsList: item
        });
       
      };
      handleAddSlot = () => {
        let item = this.state.timeslot;
        item = this.state.timeslotsList.concat([{ display_name:"",name:"",start:"",end:"", agencyId:this.state.servicepackages.servicepackage.agencyId }])
        console.log(item);
        this.setState({
            timeslotsList: item
        });
      };

      
      handleChangeSlot = (keyName, keyValue) => {
        let item = this.state.item;
        item[keyName] = keyValue;
                
        this.setState({
            item: item,
        });
    };

      addSlotItem = (item) => {
        console.log(item);
		/*callApi(`servicepackages/new`, 'post', {
			servicepackage: item,
		}).then((res) => {
			if (res.status === 'Success') {
				this.fetchPackages();
			}
		});*/
        };
        slotclose = () => {
            this.setState({
                isAddTimeSlotModalVisible: false,
            });
        };

        EditItem(timeslot){
            this.setState({
                isAddTimeSlotModalVisible: true,
                editableItems: timeslot
            });
            
        }

        DeleteItem = (id) => {
            let items = this.state.timeslotsList.map((item) => {
                if (item._id === id) {
                    item.isDeletingItem = true;
                }
                return item;
            });
            this.setState(
                {
                    items: items,
                },
                () => {
                    callApi(`timeslots/${id}/remove`, 'post', {}).then((res) => {
                        if (res.status === 'Success') {
                            this.fetchTimeSlots(this.state.servicepackages.servicepackage.agencyId);
                        }
                    });
                }
            );
        };
    render() {
        //let locations = this.state.servicepackages.servicepackage.products_used.map(item => ({ value: item._id, label: item.name }));

        let model = (this.state.isAddTimeSlotModalVisible) ? (
            <AddTimeSlotModal
                isVisible={this.state.isAddTimeSlotModalVisible}
                close={this.slotclose}
                addItem={this.addSlotItem}
                agencyId= {this.state.servicepackages.servicepackage.agencyId}
                timeslotId= {this.state.editableItems}
            />
        ) : null
        
        
             
        if (this.state.isEditable) {
            return (
                

                <Card>
                    <Card.Header>
                        <Row>
                            <Col xs={6}> {this.props.heading}</Col>
                            <Col xs={6}>
                                <div className={'text-right'}>
                                {model}
                                    {/* <button
                                        type="button"
                                        onClick={this.addSlotHandler}
                                        className="btn btn-primary">
                                        Add New
                                    </button> &nbsp;
        							&nbsp; /  &nbsp;
        							&nbsp; */}
                                    <Button disabled={this.state.isUpdatingCommunity} onClick={() => this.saveDetailsNew()}>
                                        {this.state.isUpdatingCommunity ? 'saving' : 'save'}
                                    </Button>
        							&nbsp;
        							&nbsp;
        							<Button onClick={() => this.toggleEdit()}>close</Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>
                    <ListGroup className="list-group-flush">
                    <Form >
                                <Form.Group controlId="bikeModalName1">
                                    <Form.Label>Display name</Form.Label>
                                    <Form.Control
                                            type="text"
                                            placeholder="Enter Display Name"
                                            value={this.state.item.display_name}
                                            onChange={(e) => this.handleChangeSlot("display_name", e.target.value)}
                                        />
                                </Form.Group>
                                <Form.Group controlId="bikeModalName2">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                            type="text"
                                            placeholder="Enter Name"
                                            value={this.state.item.name}
                                            onChange={(e) => this.handleChangeSlot("name", e.target.value)}
                                        />
                                </Form.Group>
                                <Form.Group controlId="bikeModalName3">
                                    <Form.Label>Start</Form.Label>
                                    <Form.Control
                                            type="text"
                                            placeholder="Enter Start"
                                            value={this.state.item.start}
                                            onChange={(e) => this.handleChangeSlot("start", e.target.value)}
                                        />
                                </Form.Group>
                                <Form.Group controlId="bikeModalName4">
                                    <Form.Label>End</Form.Label>
                                    <Form.Control
                                            type="text"
                                            placeholder="Enter End"
                                            value={this.state.item.end}
                                            onChange={(e) => this.handleChangeSlot("end", e.target.value)}
                                        />
                                </Form.Group>
                                                                
                                <Form.Group controlId="bikeModalName5">
                                    <Form.Label>&nbsp;</Form.Label>
                                    <Form.Control
                                            type="hidden"
                                            placeholder="Enter End"
                                            value={this.state.item.agencyId}
                                            onChange={(e) => this.handleChangeSlot("agencyId", e.target.value)}
                                        />
                                </Form.Group>
{/* 
                                <Button disabled={this.state.isUpdatingCommunity} onClick={() => this.saveDetailsNew()}>
                                        {this.state.isUpdatingCommunity ? 'saving' : 'save'}
                                 </Button> */}

                                 {/* <Button variant="primary" type="submit">
                                    Submit
                                </Button>  */}
                            </Form>
                    </ListGroup> 
                </Card>
                
            );
        } else {
            if (!this.state.isLoading) {
                return (
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={6}>{this.props.heading}</Col>
                                <Col xs={6}>
                                    <div className="text-right">
                                            <Button onClick={() => this.toggleEdit()}>New</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        <ListGroup className="list-group-flush">
                        <ListGroupItem>
                            <Row>
                                <Col xs={2}><b>Display Name</b></Col> 
                                <Col xs={2}><b>Name</b> </Col> 
                                <Col xs={2}><b>Start</b></Col>
                                <Col xs={2}><b>End</b></Col>
                                <Col xs={4}><b>Actions</b></Col>
                            </Row>
                        </ListGroupItem>
                         {model}
                         { this.state.listItems = this.state.timeslotsList.map((timeslot, index) => 
                        <ListGroupItem>
                            <Row>
                                <Col xs={2}>{timeslot.display_name}</Col> 
                                <Col xs={2}>{timeslot.name}</Col> 
                                <Col xs={2}>{timeslot.start}</Col>
                                <Col xs={2}>{timeslot.end}</Col>
                                <Col xs={4}><Button onClick={() => this.EditItem(timeslot)}>Edit</Button>&nbsp;<Button onClick={() => this.DeleteItem(timeslot._id)}>Delete</Button></Col>
                            </Row>
                        </ListGroupItem>
                    )} 
                       
                            
                        </ListGroup>
                    </Card>
                );
            }
            else {
                return (
                    <h1>Loading...</h1>
                )
            }
        }
        
    }
}

export default VendorDetails;
