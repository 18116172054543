import React, { Component } from 'react';

class ListTableHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSidebarEnabled: true,
		};
	}

	toggleSidebar = () => {
		this.setState({
			isSidebarEnabled: !this.state.isSidebarEnabled,
		});
		if (this.state.isSidebarEnabled) {
			document.body.className = 'vertical-collpsed';
		} else {
			document.body.className = 'sidebar-enable';
		}
	};

	render() {
		return (
			<thead className="thead-light" margin>
				<tr role="row">
					<th>#</th>
					<th>Vendor Name</th>
					<th>Supervisor Name</th>
					<th>Name</th>
					<th>Email</th>
					<th>Phone</th>
					<th>Blocks</th>
					<th>Bikes</th>
					<th>Cars</th>
					<th>Actions</th>
				</tr>
			</thead>
		);
	}
}

export default ListTableHeader;
