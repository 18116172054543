import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { Button, Card, FormControl, Modal, Nav, Tab, TabContainer, TabContent, Tabs, Col, Row } from 'react-bootstrap';
import callApi from '../../../../util/apiCaller';
import Calendar from "react-calendar"
import Loading from '../../../../components/Loading';

class CleanlinessCheck extends Component {

    constructor(props) {
        super(props);
        this.state = {
            start: moment().startOf('month').toISOString(),
            end: moment().endOf('month').toISOString(),
            date: new Date(),
            weekOffDates: [],
            holdDates: [],
            completedDates: [],
            vehicleNotAvailableDates: [],
            inCompletedDates: [],
            isfetchingLeaveStatus: false,
            weekOff: 0,
            tasks: [],
            isModalEnable: false,
            selectedTask: {},
            isCompleted: false,
            isIncomplete: false,
            isNoVehicleAvailable: false
        };
    }

    componentDidMount() {
        if (this.props.userId && this.props.selectedBookingId) {
            this.fetchLeaveStatusforMonth(
                this.props.userId,
                this.state.start,
                this.state.end,
                this.props.selectedBookingId,
            );
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedBookingId!=this.props.selectedBookingId){
            this.fetchLeaveStatusforMonth(
                this.props.userId,
                this.state.start,
                this.state.end,
                this.props.selectedBookingId,
            );
        }
    }

    fetchLeaveStatusforMonth = (
        customerId,
        monthStart,
        monthEnd,
        servicebookingId,
    ) => {
        this.setState({
            isfetchingLeaveStatus: true,
        });
        callApi(`customer/${customerId}/leave-status`, 'post', {
            start: monthStart,
            end: monthEnd,
            servicebookingId: servicebookingId,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    weekOff: res.data.weekOff,
                    isfetchingLeaveStatus: false,
                    tasks: res.data.tasks,
                }, () => {
                    this.sortDatesWithCategories();
                });
            } else {
                this.setState({
                    isfetchingLeaveStatus: false,
                });
            }
        });
    };

    sortDatesWithCategories = () => {
        let start = this.state.start;
        let end = this.state.end;
        let weekOffDates = [];
        let holdDates = [];
        let completedDates = [];
        let vehicleNotAvailableDates = [];
        let inCompletedDates = [];
        if (start && end) {
            let temp_taskes = this.state.tasks && this.state.tasks.map(item => {
                let new_item = item;
                new_item.due_date = moment(new Date(item.due_date)).startOf('days').format("DD-MM-YYYY");
                return new_item;
            })
            for (let m = moment(start); m.isBefore(end); m.add(1, 'days')) {
                if (_.toString(m.day()) === _.toString(this.state.weekOff)) {
                    weekOffDates.push(m.format("DD-MM-YYYY"));
                }
                else if (_.isEmpty(_.find(temp_taskes, { due_date: m.format("DD-MM-YYYY") })) && m <= (moment() || this.props.cancelledOn ? (moment(this.props.cancelledOn)) : null) && !this.state.isfetchingLeaveStatus && m >= moment(this.props.service_start_date)) {
                    holdDates.push(m.format("DD-MM-YYYY"));
                }
            }
        }
        if (this.state.tasks && this.state.tasks.length > 0) {
            for (let i = 0; i < this.state.tasks.length; i++) {
                if (this.state.tasks[i]) {
                    if (this.state.tasks[i].isCompleted) {
                        completedDates.push(this.state.tasks[i].due_date);
                    }
                    else if (this.state.tasks[i].isNoVehicleAvailable) {
                        vehicleNotAvailableDates.push(this.state.tasks[i].due_date);
                    }
                    else {
                        inCompletedDates.push(this.state.tasks[i].due_date);
                    }
                }
            }
        }
        this.setState({
            weekOffDates,
            holdDates,
            completedDates,
            vehicleNotAvailableDates,
            inCompletedDates
        })
    }

    onMonthChange = (month) => {
        let start = moment(month).startOf('month').toISOString();
        let end = moment(month).endOf('month').toISOString();
        this.setState(
            {
                start,
                end,
            },
            () => {
                this.fetchLeaveStatusforMonth(
                    this.props.userId,
                    this.state.start,
                    this.state.end,
                    this.props.selectedBookingId,
                );
            },
        );
    };

    openModal = (selectedDate) => {
        let tasks = this.state.tasks;
        let selectedTask = {};
        selectedTask = tasks ? tasks.filter(item => (item.due_date == moment(selectedDate).format("DD-MM-YYYY")))[0] : {};
        this.setState({
            isIncomplete: false,
            isCompleted: false,
            isNoVehicleAvailable: false,
            isModalEnable: true,
            selectedTask
        })
    }

    closeModal = () => {
        this.setState({
            isModalEnable: false
        })
    }

    render() {
        return (
            <div>
                {/* {this.state.isfetchingLeaveStatus ?
                    <Loading />
                    : */}
                <>
                            <Row style={{margin:'3rem 0rem'}}>
                                <Col></Col>
                                <Col>
                                    <Calendar
                                        value={this.state.date}
                                        tileClassName={({ date, view }) => {
                                            if (this.state.weekOffDates.filter(x => x === moment(date).format("DD-MM-YYYY")).length > 0) {
                                                return 'weekoff_styles'
                                            }
                                            else if (this.state.holdDates.filter(x => x === moment(date).format("DD-MM-YYYY")).length > 0) {
                                                return 'hold_styles'
                                            }
                                            else if (this.state.completedDates.filter(x => x === moment(date).format("DD-MM-YYYY")).length > 0) {
                                                return 'completed_styles'
                                            }
                                            else if (this.state.vehicleNotAvailableDates.filter(x => x === moment(date).format("DD-MM-YYYY")).length > 0) {
                                                return 'noVehicle_styles'
                                            }
                                            else if (this.state.inCompletedDates.filter(x => x === moment(date).format("DD-MM-YYYY")).length > 0) {
                                                return 'incompleted_styles'
                                            }
                                        }}
                                        onActiveStartDateChange={({ activeStartDate }) => this.onMonthChange(activeStartDate)}
                                        onClickDay={(date) => { 
                                            // if(this.state.tasks && this.state.tasks.filter(item => (item.due_date == moment(date).format("DD-MM-YYYY"))).length>0){
                                            //     this.openModal(date);
                                            // }
                                         }}
                                    />
                                    <div
                                        style={{
                                            marginTop: ".9rem",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                className="booking_details_sqare"
                                                style={{ backgroundColor: '#95d904' }}
                                            ></div>
                                            <span className="span_12">cleaned</span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                className="booking_details_sqare"
                                                style={{ backgroundColor: '#e50019' }}
                                            ></div>
                                            <span className="span_12">not cleaned</span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                className="booking_details_sqare"
                                                style={{ backgroundColor: '#15cdff' }}
                                            ></div>
                                            <span className="span_12">no vehicle</span>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            marginTop: ".9rem",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-around",
                                        }}
                                    >
                                        {/* <div
                   style={{
                     display: "flex",
                     flexDirection: "row",
                     alignItems: "center",
                   }}
                 >
                   <div
                     className="booking_details_sqare"
                     style={{ backgroundColor: '#ffd400' }}
                   ></div>
                   <span className="span_12">hold</span>
                 </div> */}
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                className="booking_details_sqare"
                                                style={{ backgroundColor: '#6d6d6d' }}
                                            ></div>
                                            <span className="span_12">weekly off</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col></Col>
                            </Row>
                    <Modal
                        dialogClassName={'modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered'}
                        show={this.state.isModalEnable}
                        onHide={() => this.closeModal()}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Change task status</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <h5>Previous Notes</h5>
                                <ul>
                                    <li>
                                        <p>Changed by bhanu <span className='text-warning font-weight-bold'>(02-12-2021)</span></p>
                                    </li>
                                </ul>
                                <br />
                                {this.state.selectedTask && !_.isEmpty(this.state.selectedTask) && this.state.selectedTask.isCompleted
                                    ?
                                    <div>
                                        <Row>
                                            <Col>
                                                <button type="button" class={this.state.isIncomplete ? "btn btn-danger btn-block" : "btn btn-outline-danger btn-block"} onClick={()=>{
                                                    this.setState({
                                                    isCompleted:false,
                                                    isIncomplete:true,
                                                    isNoVehicleAvailable:false
                                                })
                                                }}>Incomplete</button>
                                            </Col>
                                            <Col>
                                                <button type="button" class={this.state.isNoVehicleAvailable ? "btn btn-info btn-block" : "btn btn-outline-info btn-block"} onClick={()=>{
                                                    this.setState({
                                                    isCompleted:false,
                                                    isIncomplete:false,
                                                    isNoVehicleAvailable:true
                                                })
                                                }}>Vehicle not available</button>
                                            </Col>
                                        </Row>
                                    </div>
                                    :
                                    this.state.selectedTask && !_.isEmpty(this.state.selectedTask) && this.state.selectedTask.isNoVehicleAvailable ?
                                        <div>
                                            <Row>
                                                <Col>
                                                    <button type="button" class={this.state.isCompleted ? "btn btn-success btn-block" : "btn btn-outline-success btn-block"} onClick={()=>{
                                                    this.setState({
                                                    isCompleted:true,
                                                    isIncomplete:false,
                                                    isNoVehicleAvailable:false
                                                })
                                                }}>Completed</button>
                                                </Col>
                                                <Col>
                                                    <button type="button" class={this.state.isIncomplete ? "btn btn-danger btn-block" : "btn btn-outline-danger btn-block"}onClick={()=>{
                                                    this.setState({
                                                    isCompleted:false,
                                                    isIncomplete:true,
                                                    isNoVehicleAvailable:false
                                                })
                                                }}>Incomplete</button>
                                                </Col>
                                            </Row>
                                        </div>
                                        :
                                        <div>
                                            <Row>
                                                <Col>
                                                    <button type="button" class={this.state.isCompleted ? "btn btn-success btn-block" : "btn btn-outline-success btn-block"}
                                                    onClick={()=>{
                                                        this.setState({
                                                        isCompleted:true,
                                                        isIncomplete:false,
                                                        isNoVehicleAvailable:false
                                                    })
                                                    }}>Completed</button>
                                                </Col>
                                            </Row>
                                        </div>
                                }
                                <br />
                                <h5>Notes*</h5>
                                <textarea className='form-control' placeholder='Enter here...'></textarea>
                                <br />
                                <button className='btn btn-primary w-50 btn-block mx-auto'>Submit</button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
                {/* } */}
            </div>
        )
    }
}

export default CleanlinessCheck;