import React, { Component } from 'react';
import moment from 'moment';

class ListTableRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSidebarEnabled: true
        };
    }


    toggleSidebar = () => {
        this.setState({
            isSidebarEnabled: !this.state.isSidebarEnabled
        });
        if (this.state.isSidebarEnabled) {
            document.body.className = 'vertical-collpsed';
        } else {
            document.body.className = 'sidebar-enable';
        }
    };

    render() {
        
        return (
             <tr role="row" className="odd">
                <td>
                    {this.props.index + 1 + (this.props.page - 1) * this.props.size}
                </td>
                <td>{this.props.item.name}</td>
                <td>{this.props.item.email}</td>
                <td>{this.props.item.phone}</td>
                <td>{this.props.item.otp}</td>
                <td>{this.props.item.type}</td>
                
                <td style={{ display: 'none' }}>
                    <a href="javascript:void(0);" className="mr-3 text-primary" data-toggle="tooltip" data-placement="top" title data-original-title="Edit"><i className="mdi mdi-pencil font-size-18" /></a>
                    <a
                        disable={this.props.item.isDeletingItem}
                        onClick={() => this.props.deleteItem(this.props.item._id)}
                        className="text-danger">
                        <i className="mdi mdi-trash-can font-size-18" />
                    </a>
                </td>
            </tr>
        );
    }
}

export default ListTableRow;
