import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row, Modal } from 'react-bootstrap';
import moment from 'moment';
import callApi from '../../../../util/apiCaller';
import Loader from 'react-loader-spinner';
import Datepicker from '../../../../components/DatePicker';
import CleanlinessCheck from './CleanlinessCheck';
import Loading from '../../../../components/Loading';

class ModalTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEditable: false,
			item: {},
			brands: [],
			models: [],
			brand_wise_filterd_models: [],
			locations: [],
			apartments: [],
			blocks: [],
			time_slot_names: [],
			isCancellingService: false,
			vehicle: {},
			notes: "",
			isReActivatingService: false,
			modalType: ""
		};
	}

	componentDidMount() {
		if (this.props.item && this.props.timeSlot && this.props.vehicle) {
			this.setState({
				item: this.props.item,
				timeSlot: this.props.timeSlot,
				vehicle: this.props.vehicle
			});
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!_.isEqual(prevProps.item, this.props.item)) {
			this.setState({
				item: this.props.item,
			});
		}
	}

	toggleEdit = () => {
		this.setState({
			isEditable: !this.state.isEditable,
		});
		this.fetchModelsAndBrandsByVehicleType();
		this.fetchLocations();
		this.fetchBlocksByApartmentId(this.props.item.apartment_id);
		this.fetchTimeslotsByAgencyId(this.props.item.agencyId);

		this.setState({
			cancel_reasons: ["No", "Reason 1", "Reason 2"]
		});

	};

	handleChange = (keyName, keyValue) => {
		let item = this.state.item;
		item[keyName] = keyValue;
		this.setState({
			item: item,
		});
		if (keyName === 'brand_name') {
			console.log(keyName, keyValue);
			let vehicle_models = this.state.models.filter((item) => {
				if (item.carbrand === keyValue) {
					return item;
				}
			});
			this.setState({
				brand_wise_filterd_models: vehicle_models
			})
			// Making model empty on change brand
			this.handleChange("model_name", "");
			this.handleChange("car_body_type", "");
		}
		if (keyName === 'model_name') {
			console.log(keyName, keyValue);
			let vehicle_models = this.state.models.filter((item) => {
				if (item.name === keyValue) {
					this.handleChange("car_body_type", item.car_body_type);
					console.log(item.car_body_type);
				}
			})
		}
		if (keyName === 'location_id') {
			this.setState({
				apartments: [],
				blocks: [],
			})
			this.fetchApartmentsByLocationId(keyValue);
			// Making apartment_id
			this.handleChange("apartment_id", "");
			this.handleChange("apartment_name", "");
		}
		if (keyName === 'apartment_id') {
			this.fetchBlocksByApartmentId(keyValue);
			this.handleChange("block_id", "");
			this.handleChange("block_name", "");
		}
	};

	fetchModelsAndBrandsByVehicleType = () => {
		if (_.isEmpty(this.state.brands) || _.isEmpty(this.state.models)) {
			if (this.props.item.vehicle_type === 'car') {
				let filters = {
					pageNum: 1,
					pgeSize: 10000,
				}
				callApi(`carbrands/list`, 'post', filters).then((res) => {
					if (res.status === 'Success') {
						this.setState({
							brands: res.data.carbrands,
						});
					}
				});
				callApi(`carmodels/list`, 'post', filters).then((res) => {
					console.log("HII", res.data.carmodels);
					if (res.status === 'Success') {
						this.setState({
							models: res.data.carmodels,
						});
					}
				});

			}
			if (this.props.item.vehicle_type === 'bike') {
				let filters = {
					pageNum: 1,
					pgeSize: 10000,
				}
				callApi(`bikebrands/list`, 'post', filters).then((res) => {
					if (res.status === 'Success') {
						this.setState({
							brands: res.data.bikebrands,
						});
					}
				});
				callApi(`bikemodels/list`, 'post', filters).then((res) => {
					if (res.status === 'Success') {
						this.setState({
							models: res.data.bikemodels,
						});
					}
				});
			}
		}
	}

	fetchLocations = () => {
		if (_.isEmpty(this.state.locations)) {
			let filters = {
				pageNum: 1,
				pgeSize: 10000,
			}
			callApi(`locations/list`, 'post', filters).then((res) => {
				if (res.status === 'Success') {
					this.setState({
						locations: res.data.locations,
					}, () => {
						let location_id = '';
						this.state.locations.map((item) => {
							if (item.name === this.props.item.location_name) {
								location_id = item._id;
							}
						})
						callApi(`locations/locality/${location_id}/apartment-list`, 'post', filters).then((res) => {
							if (res.status === 'Success') {
								console.log("apartments", res.data);
								this.setState({
									apartments: res.data.apartments,
								});
							}
						});
					});
				}
			});
		}
	}

	fetchApartmentsByLocationId = (location_id) => {
		let filters = {
			pageNum: 1,
			pgeSize: 10000,
		}
		callApi(`locations/locality/${location_id}/apartment-list`, 'post', filters).then((res) => {
			if (res.status === 'Success') {
				console.log("apartments", res.data);
				this.setState({
					apartments: res.data.apartments,
				});
			}
		});
	}

	fetchBlocksByApartmentId = (apartment_id) => {
		let filters = {
			pageNum: 1,
			pgeSize: 10000,
		}
		callApi(`locations/apartment/${apartment_id}/block-list`, 'post', filters).then((res) => {
			if (res.status === 'Success') {
				console.log("blocks", res.data);
				this.setState({
					blocks: res.data.blocks,
				});
			}
		});
	}

	fetchTimeslotsByAgencyId = (apartment_id) => {
		let filters = {};
		callApi(`timeslots/agency/${apartment_id}`, 'post', filters).then((res) => {
			console.log("fetchTimeslotsByAgencyId", res.data);
			if (res.status === 'Success') {
				console.log("blocks", res.data);
				this.setState({
					time_slot_names: res.data.timeslots,
				});
			}
		});
	}

	saveDetails = () => {
		let item = this.state.item;
		if (item.cancellation_reason != "No") {
			item.isCancelled = true;
		}
		this.props.updateServicebooking(this.props.item._id, item);
		console.log("item", item)
		this.toggleEdit();
	};

	cancelServiceBooking = () => {
		let servicebooking = {};
		servicebooking.isCancelled = true;
		servicebooking.adminUserId = this.props.userDetails && this.props.userDetails._id;
		servicebooking.adminEmailId = this.props.userDetails && this.props.userDetails.email;
		servicebooking.adminNotes = "Cancelled - " + this.state.notes;
		this.setState({
			isCancellingService: true,
		});
		callApi(
			`v2/customer/${this.state.item.customerId}/servicebooking/${this.state.item._id}/cancel-service-booking`,
			'post',
			{
				servicebooking: servicebooking,
			},
		).then((res) => {
			if (res.status === 'Success') {
				let item = this.state.item;
				item.isCancelled = true;
				this.setState({
					isCancellingService: false,
					item
				});
				alert("service cancelled.");
				this.closeCancelServiceModalInvoice();
			} else {
				alert("some internal issue.")
				this.setState({
					isCancellingService: false,
				});
			}
		});
	};

	reActivateServiceBooking = () => {
		let servicebooking = {};
		servicebooking.adminUserId = this.props.userDetails && this.props.userDetails._id;
		servicebooking.adminEmailId = this.props.userDetails && this.props.userDetails.email;
		servicebooking.adminNotes = "Reactivated - " + this.state.notes;
		this.setState({
			isReActivatingService: true,
		});
		callApi(
			`admin/servicebooking/${this.state.item._id}/re-activate`,
			'post',
			servicebooking,
		).then((res) => {
			if (res.status === 'Success') {
				let item = this.state.item;
				item.isCancelled = false;
				this.setState({
					isReActivatingService: false,
					item
				});
				alert("service reactivated.");
				this.closeCancelServiceModalInvoice();
			} else {
				alert("some internal issue.")
				this.setState({
					isReActivatingService: false,
				});
			}
		});
	};

	openCancelServiceModalInvoice = (modal) => {
		this.setState({
			isCancelServiceModalEnable: true,
			notes: "",
			modalType: modal
		})
	}

	closeCancelServiceModalInvoice = () => {
		this.setState({
			isCancelServiceModalEnable: false
		})
	}

	render() {
		if (this.state.isEditable) {
			return (
				<Card>
					<Card.Header>
						<Row>
							<Col xs={6}> {this.props.heading}</Col>
							<Col xs={6}>
								<div className={'text-right'}>
									<Button disabled={this.props.item.isUpdating} onClick={() => this.saveDetails()}>
										{this.props.item.isUpdating ? 'saving' : 'save'}
									</Button>
									&nbsp;
									&nbsp;
									<Button onClick={() => this.toggleEdit()}>close</Button>
								</div>
							</Col>
						</Row>
					</Card.Header>
					{
						this.props.item.isUpdating ? <Loader></Loader> :
							<ListGroup className="list-group-flush">
								<ListGroupItem>
									<Row>
										<Col xs={5}>Booking Number</Col>
										<Col xs={7}>{this.state.item.booking_num}</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Payment Status</Col>
										<Col xs={7}>{this.state.item.isPaymentSuccess ? 'Paid' : 'Unpaid'}</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Vehicle Details</Col>
										<Col xs={7}>
											<Col xs={7}>{this.state.item.vehicle_type}</Col>
											<Col xs={5}>Brand</Col>
											<FormControl
												as="select"
												onChange={(e) => {
													this.handleChange("brand_name", e.target.value);
												}}
											>
												<option selected="true" disabled>{this.state.item.brand_name}</option>
												{this.state.brands.map((item) => (
													<option value={item.name}>{item.name}</option>
												))}
											</FormControl>
											<Col xs={5}>model</Col>
											<FormControl
												as="select"
												onChange={(e) => this.handleChange("model_name", e.target.value)}
											>
												<option selected="true" disabled>{this.state.item.model_name}</option>
												{this.state.brand_wise_filterd_models.map((item) => (
													<option value={item.name}>{item.name}</option>
												))}
											</FormControl>
										</Col>

									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>License Number</Col>
										<Col xs={7}>
											<FormControl
												onChange={(e) => this.handleChange('license_number', e.target.value)}
												value={this.state.item.license_number}
											/>
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Location Name</Col>
										<Col xs={7}>
											{/* <FormControl
												as="select"
												onChange={(e) => {
													this.handleChange("location_id", e.target.value);
													this.state.locations.filter((item) => {
														if (item._id === e.target.value) {
															this.handleChange("location_name", item.name);
														}
													})
												}}
											>
												<option selected="true" disabled>{this.state.item.location_name}</option>
												{this.state.locations.map((item) => (
													<option value={item._id}>{item.name}</option>
												))}
											</FormControl> */}
											{this.state.item.location_name}
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Community Name</Col>
										<Col xs={7}>
											{/* <FormControl
												as="select"
												onChange={(e) => {
													this.handleChange("apartment_id", e.target.value);
													this.state.apartments.filter((item) => {
														if (item._id === e.target.value) {
															this.handleChange("apartment_name", item.name);
														}
													})
												}}
											>
												<option selected="true" disabled>{this.state.item.apartment_name}</option>
												{this.state.apartments.map((item) => (
													<option value={item._id}>{item.name}</option>
												))}
											</FormControl> */}
											{this.state.item.apartment_name}
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Block Name</Col>
										<Col xs={7}>
											<FormControl
												as="select"
												onChange={(e) => {
													this.handleChange("block_id", e.target.value);
													this.state.blocks.filter((item) => {
														if (item._id === e.target.value) {
															this.handleChange("block_name", item.name);
														}
													})
												}}
											>
												<option selected="true" disabled>{this.state.item.block_name}</option>
												{this.state.blocks.map((item) => (
													<option value={item._id}>{item.name}</option>
												))}
											</FormControl>
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Flat Number</Col>
										<Col xs={7}>
											<FormControl
												onChange={(e) => this.handleChange('flat_num', e.target.value)}
												value={this.state.item.flat_num}
											/>
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Parking Slot</Col>
										<Col xs={7}>
											<FormControl
												onChange={(e) => this.handleChange('parking_block', e.target.value)}
												value={this.state.item.parking_block}
											/>
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Time Slot</Col>
										<Col xs={7}>
											<FormControl
												as="select"
												onChange={(e) => this.handleChange('time_slot_name', e.target.value)}
												value={this.state.item.time_slot_name}
											>
												<option selected="true" disabled>{this.state.item.time_slot_name}</option>
												{this.state.time_slot_names.map((item) => (
													<option value={item.name}>{item.name}</option>
												))}
											</FormControl>
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Service Start Date</Col>
										<Col xs={7}>
											{moment(this.state.item.service_booking_date).format('DD MMM YYYY')}
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Next Payment Date</Col>
										<Col xs={7}>
											{moment(this.state.item.next_payment_date).format('DD MMM YYYY')}
											<Datepicker
												handleDateChange={(e) => {
													this.handleChange("next_payment_date", moment(new Date(e)).format("MM DD YYYY"));
												}}
												value={this.state.item.next_payment_date}
											/>
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row>
										<Col xs={5}>Do you want to Cancel/Hold? If yes, provide the reason?</Col>
										<Col xs={7}>
											<FormControl
												// as="select"
												onChange={(e) => this.handleChange('cancellation_reason', e.target.value)}
												value={this.state.item.cancellation_reason}
											>
												{/* <option selected="true" disabled>{this.state.item.cancellation_reason}</option>
												{this.state.cancel_reasons.map((item) => (
													<option value={item}>{item}</option>
												))} */}
											</FormControl>
										</Col>
									</Row>
								</ListGroupItem>
							</ListGroup>
					}
				</Card>
			);
		} else {
			return (
				<>
					<Card>
						{this.state.item.serviceType === "on-demand" ?
							<div style={{ width: '100%', backgroundColor: 'orange', textAlign: 'center', height: 20 }}>
								<p style={{ fontWeight: 'bold' }}>On Demand</p>
							</div>
							: null}
						{this.state.item.isCancelled ? <div style={{ width: '100%', backgroundColor: 'red', textAlign: 'center', height: 20 }}>
							<p style={{ fontWeight: 'bold', color: "white" }}>Cancelled</p>
						</div> : null}
						<Card.Header>
							<Row>
								<Col xs={6}>{this.props.heading}</Col>
								{/* <Col xs={6}>
								<div className="text-right">
									<Button onClick={() => this.toggleEdit()}>edit</Button>
								</div>
							</Col> */}
							</Row>
						</Card.Header>
						<ListGroup className="list-group-flush">
							<ListGroupItem>
								<Row>
									<Col xs={5}>Booking Number</Col>
									<Col xs={7}>{this.state.item.booking_num}</Col>
								</Row>
							</ListGroupItem>
							<ListGroupItem>
								<Row>
									<Col xs={5}>Service Name</Col>
									<Col xs={7}>{this.state.item && this.state.item.servicepackageId && this.state.item.servicepackageId.name ? this.state.item.servicepackageId.name : ''}</Col>
								</Row>
							</ListGroupItem>
							<ListGroupItem>
								<Row>
									<Col xs={5}>Payment Status</Col>
									<Col xs={7}>
										{this.state.item.isPaymentSuccess ? 'Paid' : 'Unpaid'}
										{/* {this.state.item.isPaymentFailed ? 'Failed' : ''} */}
									</Col>
								</Row>
							</ListGroupItem>
							<ListGroupItem>
								<Row>
									<Col xs={5}>Vehicle Details</Col>
									<Col xs={7}>
										{_.startCase(this.state.vehicle.vehicle_type)},{'  '}
										{_.startCase(this.state.vehicle && this.state.vehicle.brand_id && this.state.vehicle.brand_id.name ? this.state.vehicle.brand_id.name : "--NA--")},{'  '}
										{_.startCase(this.state.vehicle && this.state.vehicle.model_id && this.state.vehicle.model_id.name ? this.state.vehicle.model_id.name : "--NA--")}
									</Col>
								</Row>
							</ListGroupItem>
							<ListGroupItem>
								<Row>
									<Col xs={5}>License Number</Col>
									<Col xs={7}>{this.state.item && this.state.vehicle.license_number ? this.state.vehicle.license_number : ''}</Col>
								</Row>
							</ListGroupItem>
							<ListGroupItem>
								<Row>
									<Col xs={5}>Location Name</Col>
									<Col xs={7}>{this.state.item && this.state.item.addressId && this.state.item.addressId.location_id && this.state.item.addressId.location_id.name ? this.state.item.addressId.location_id.name : ''}</Col>
								</Row>
							</ListGroupItem>
							<ListGroupItem>
								<Row>
									<Col xs={5}>Community Name</Col>
									<Col xs={7}>{this.state.item && this.state.item.addressId && this.state.item.addressId.community_id && this.state.item.addressId.community_id.name ? this.state.item.addressId.community_id.name : ''}</Col>
								</Row>
							</ListGroupItem>
							<ListGroupItem>
								<Row>
									<Col xs={5}>Block Name</Col>
									<Col xs={7}>{this.state.item && this.state.item.addressId && this.state.item.addressId.block_id && this.state.item.addressId.block_id.name ? this.state.item.addressId.block_id.name : ''}</Col>
								</Row>
							</ListGroupItem>
							<ListGroupItem>
								<Row>
									<Col xs={5}>Flat Number</Col>
									<Col xs={7}>{this.state.item && this.state.item.addressId && this.state.item.addressId.flat_no ? this.state.item.addressId.flat_no : ''}</Col>
								</Row>
							</ListGroupItem>
							<ListGroupItem>
								<Row>
									<Col xs={5}>Parking Slot</Col>
									<Col xs={7}>{this.state.item && this.state.item.addressId && this.state.item.addressId.parking_slot ? this.state.item.addressId.parking_slot : ''}</Col>
								</Row>
							</ListGroupItem>
							<ListGroupItem>
								<Row>
									<Col xs={5}>Basement No</Col>
									<Col xs={7}>{this.state.item && this.state.item.addressId && this.state.item.addressId.basement ? this.state.item.addressId.basement : ''}</Col>
								</Row>
							</ListGroupItem>
							<ListGroupItem>
								<Row>
									<Col xs={5}>Time Slot</Col>
									<Col xs={7}>{this.state.item && this.state.timeSlot && this.state.timeSlot.time_slot_name ? this.state.timeSlot.time_slot_name : ''}</Col>
								</Row>
							</ListGroupItem>
							<ListGroupItem>
								<Row>
									<Col xs={5}>Service Start Date</Col>
									<Col xs={7}>
										{this.state.item && this.state.item.service_booking_date ? moment(new Date(this.state.item.service_booking_date)).format('DD MMM YYYY') : ''}
									</Col>
								</Row>
							</ListGroupItem>
							{/* {this.state.item && this.state.item.serviceType && this.state.item.serviceType == "cleaning" ?
								<ListGroupItem>
									<Row>
										<Col xs={5}>Package Duration</Col>
										<Col xs={7}>
											{this.state.item && this.state.item.package_duration}
										</Col>
									</Row>
								</ListGroupItem>
								:
								null
							} */}
							{this.state.item && this.state.item.serviceType && this.state.item.serviceType == "cleaning" && this.state.item.isHold?
								<ListGroupItem>
									<Row>
										<Col xs={5}>Service stop date</Col>
										<Col xs={7}>
											{moment(this.state.item && this.state.item.service_stop_date).format("DD MMM, YYYY")}
										</Col>
									</Row>
								</ListGroupItem>
								:
								null
							}
							{this.state.item && this.state.item.serviceType && this.state.item.serviceType != "cleaning"?
								<ListGroupItem>
									<Row>
										<Col xs={5}>Status</Col>
										<Col xs={7}>
											{this.state.item && this.state.item.status}
										</Col>
									</Row>
								</ListGroupItem>
								:
								null
							}
							{this.state.item.isCancelled ?
								<ListGroupItem>
									<Row>
										<Col xs={5}>Cancellation Reason</Col>
										<Col xs={7}>
											{this.state.item && this.state.item.cancellation_reason ? this.state.item.cancellation_reason : "--NA--"}
										</Col>
									</Row>
								</ListGroupItem>
								:
								null
							}
							{/* <ListGroupItem>
								<Row>
									<Col xs={5}>Next Payment Date</Col>
									<Col xs={7}>
										{this.state.item && this.state.item.next_payment_date ? moment(new Date(this.state.item.next_payment_date)).format('DD MMM YYYY') : ''}
									</Col>
								</Row>
							</ListGroupItem> */}
							<ListGroupItem>
								<b>Actions</b>
								<br />
								<br />
								<Row className='text-center'>
									{this.state.item && this.state.item.serviceType && this.state.item.serviceType == "cleaning" ?
										<>
											<Col xs={3}><button className='btn btn-primary btn-block' onClick={() => { this.props.openCleanlinessCheckModal(this.state.item && this.state.item._id) }}>Cleanliness Check</button></Col>
											{!this.state.item.isHold ?
												<Col xs={3}><button className='btn btn-danger btn-block' onClick={() => { this.props.stopService(this.state.item && this.state.item._id)}} disabled={this.props.isStopingService}>{this.props.isStopingService?"Stopping...":"Stop subscription"}</button></Col>
												:
												<Col xs={3}><button className='btn btn-primary' onClick={() => { this.props.reactivateService(this.state.item && this.state.item._id) }} disabled={this.props.isReactivatingService}>{this.props.isReactivatingService?"Reactivating...":"Re-activate subscription"}</button></Col>
											}
										</>
										:
										null
									}
									{this.state.item.isPaymentSuccess && !this.state.item.isCancelled && this.state.item.serviceType != "cleaning" ?
										<Col xs={3}>
											<button className="btn btn-danger btn-block" onClick={() => this.openCancelServiceModalInvoice("Cancel")} disabled={this.state.isCancellingService}>{this.state.isCancellingService ? 'Cancelling...' : 'Cancel booking'}</button>
										</Col>
										: null
									}
								</Row>
							</ListGroupItem>
						</ListGroup>
					</Card>

					<Modal
						dialogClassName={'modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered'}
						show={this.state.isCancelServiceModalEnable}
						onHide={() => this.closeCancelServiceModalInvoice()}
					>
						<Modal.Header closeButton>
							<Modal.Title>{this.state.modalType} Service</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div>
								{this.state.item && this.state.item.notes && this.state.item.notes.length > 0 ?
									<>
										<h5>Previous Notes</h5>
										<ul>
											{this.state.item.notes.map(noteItem => (
												<li>
													<p>{noteItem.note} <span className='text-warning font-weight-bold'>({moment(noteItem.date).format("DD MMM, YYYY")})</span></p>
												</li>
											))}
										</ul>
										<br />
									</>
									: null
								}
								<form onSubmit={(e) => { e.preventDefault(); this.state.modalType == "Cancel" ? this.cancelServiceBooking() : this.reActivateServiceBooking(); }}>
									<h5>Notes*</h5>
									<textarea className='form-control' placeholder='Enter here...' required onChange={(e) => {
										this.setState({
											notes: e.target.value
										})
									}}></textarea>
									<br />
									<button className='btn btn-primary w-50 btn-block mx-auto' disabled={this.state.isCancellingService || this.state.isReActivatingService}>{this.state.modalType == "Reactivate" ? this.state.isReActivatingService ? "Reactivating ..." : "Reactivate service" : this.state.isCancellingService ? 'Cancelling...' : 'Cancel service'}</button>
								</form>
							</div>
						</Modal.Body>
					</Modal>
				</>
			);
		}
	}
}

export default ModalTable;
