import React, { Component } from 'react';
import callApi from '../../../util/apiCaller';
import ListTable from './components/ListTable';
import _, { filter } from 'lodash';
import { Button } from 'react-bootstrap';
import AddApartmentModal from './components/AddApartmentModal';
import ApartmentDetailsModal from './components/ApartmentDetailsModal'
import downloadApi from "../../../util/apiDownloader";
import moment from "moment";
import Select from 'react-select';


class ApartmentsDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,
            pageSize: 25,
            totalCount: 0,
            items: [],
            locations: [],
            isAddApartmentModalVisible: false,
            isAddingApartment: false,
            isDetailsModalVisible: false,
            selectedItemId: '',
            isDownloadingData: false,
            filter_locations: [],
            filter_status: '',
            query: ''
        };
        this.debounceSearch = _.debounce(this.handleSearch, 500);
    }

    componentDidMount() {
        this.fetchApartments();
        this.fetchLocations();
    }

    fetchApartments = () => {
        let filters = this.getFilters();
        console.log(filters);
        callApi(`admin/communites/list`, 'post', {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            selected_filter: filters || {},
            query: this.state.query
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    items: res.data["communites"],
                    totalCount: res.data.count
                }, () => {
                    console.log("fetched_apartments", this.state.items);
                })
            }
            else {
                console.log("error")
            }
        })
    };

    fetchLocations = () => {
        callApi(`locations/list`, 'post', {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    locations: res.data["locations"]
                })
            }
            else {
                console.log("error")
            }
        })
    };

    deleteItem = (id) => {
        let items = this.state.items.map((item) => {
            if (item.apartmentId === id) {
                item.isDeletingItem = true;
            }
            return item;
        });
        this.setState(
            {
                items: items,
            },
            () => {
                callApi(`admin/apartment/${id}/delete`, 'post', {})
                    .then((res) => {
                        if (res.status === 'Success') {
                            this.fetchApartments();
                        }
                    });
            }
        );
    };

    getFilters = () => {
        let input = {};
        if (this.state.filter_locations.length > 0) {
            input.locationId = this.state.filter_locations.map(item => (item.value))
        }
        if (this.state.filter_status && this.state.filter_status.value != "") {
            input.isActive = this.state.filter_status.value;
        }
        return input;
    };

    addItem = (item) => {
        this.setState({ isAddingApartment: true });
        callApi(`admin/apartment/add`, 'post', {
            ...item
        }).then((res) => {
            console.log("response from api", res.data);
            if (res.status === 'Success') {
                this.setState({ isAddingApartment: false });
                this.fetchApartments()
            }
        });
    };

    updateItem = (id, item) => {
        let items = this.state.items.map((item) => {
            if (item._id === id) {
                item.isUpdating = true;
            }
            return item;
        });
        this.setState(
            {
                items: items,
            },
            () => {
                console.log("item_before_api_call", item);
                callApi(`admin/apartment/${id}/update`, 'post', {
                    ...item,
                }).then((res) => {
                    if (res.status === 'Success') {
                        let items = this.state.items.map((item) => {
                            if (item._id === id) {
                                return res.data.apartment;
                            } else {
                                return item;
                            }
                        });
                        this.setState({
                            items: items,
                        });
                    }
                });
            }
        );
    };

    addApartmentHandler = () => {
        this.setState({
            isAddApartmentModalVisible: true
        })
    }

    closeModal = () => {
        this.setState({
            isDetailsModalVisible: false,
            selectedItemId: '',
            isAddApartmentModalVisible: false,
        });
    };

    downloadData = () => {
        console.log('Download pressed');
        this.setState({
            isDownloadingData: true,
        });
        let filters = this.getFilters();
        downloadApi(`communities-${moment().format('DD-MM-YYYY-HH:mm')}.csv`, `admin/communites/dump`, 'POST', filters).then(
            (blob) => {
                this.setState({
                    isDownloadingData: false,
                });
                console.log(JSON.stringify(blob));
            }
        );
    };

    handleDetails = (id) => {
        this.setState({
            selectedItemId: id,
            isDetailsModalVisible: true,
        });
    };

    changePageNum = (num) => {
        this.setState(
            {
                pageNum: num,
            },
            () => {
                let filters = this.getFilters();
                this.fetchApartments(filters);
            }
        );
    };

    handleChange = (keyName, keyValue) => {
        let item = {};
        item[keyName] = keyValue;
        this.setState(item);
    };

    handleQuery = (val) => {
        // val = _.kebabCase(val);
        this.setState(
            {
                query: val,
            },
            () => {
                this.debounceSearch();
            }
        );
    };

    handleSearch = () => {
        this.fetchApartments();
    };

    render() {
        let locations = this.state.locations.map(item => ({ value: item._id, label: item.name }));
        let status = [
            { value: "", label: "All" },
            { value: "true", label: "Active" },
            { value: "false", label: "In Active" }
        ]
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="mb-0">Communities List</h4>
                            <div className="page-title-right">
                                <Button className="mr-3" type="button" data-toggle="collapse" data-target="#multiCollapseExample1" aria-expanded="false" aria-controls="multiCollapseExample2"><i className="fa fa-filter"></i></Button>
                                <Button className="mr-3" onClick={() => this.addApartmentHandler()} disabled={this.state.isAddingApartment}>
                                    <i className="fa fa-plus"></i> {this.state.isAddingApartment ? "Adding" : "Add"}
                                </Button>
                                <Button onClick={() => this.downloadData()} disabled={this.state.isDownloadingData}>
                                    {this.state.isDownloadingData ? "Downloading" : "Download Data"}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="collapse multi-collapse" id="multiCollapseExample1">
                    <div className="card card-body">
                        <h5>Apply Filters</h5>
                        <div className="container mt-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Select Location</label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        onChange={(value) => this.handleChange("filter_locations", value)}
                                        isMulti
                                        options={locations}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>Select Status</label>
                                    <Select
                                        onChange={(value) => this.handleChange("filter_status", value)}
                                        options={status}
                                        defaultValue={status[0]}
                                    />
                                </div>
                            </div>
                        </div>
                        <Button className="w-25 mx-auto mt-3" onClick={() => this.fetchApartments()}>
                            Apply
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <ListTable
                                    changePageNum={this.changePageNum}
                                    handleQuery={this.handleQuery}
                                    updateItem={this.updateItem}
                                    deleteItem={this.deleteItem}
                                    handleDetails={this.handleDetails}
                                    query={this.state.query}
                                    items={this.state.items}
                                    locations={this.state.locations}
                                    pageSize={this.state.pageSize}
                                    totalCount={this.state.totalCount}
                                    pageNum={this.state.pageNum}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isAddApartmentModalVisible ? (
                    <AddApartmentModal
                        isVisible={this.state.isAddApartmentModalVisible}
                        close={this.closeModal}
                        addItem={this.addItem}
                        locations={this.state.locations}
                    />
                ) : null}
                {this.state.isDetailsModalVisible ? (
                    <ApartmentDetailsModal
                        isVisible={this.state.isDetailsModalVisible}
                        selectedItemId={this.state.selectedItemId}
                        close={this.closeModal}
                        fetchApartments={this.fetchApartments}
                    />
                ) : null}
            </div>
        );
    }
}

export default ApartmentsDetail;
