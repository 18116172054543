import React, { Component } from 'react';
import callApi from '../../util/apiCaller';
import downloadApi from "../../util/apiDownloader";
import moment from "moment";
import ListTable from './components/ListTable';
import _, { filter } from 'lodash';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import AddVendorModal from './components/ListTable';
import PackageDetailsModal from './components/ServicePackageDetails'

class ServicePackageAll extends Component {
	constructor(props) {
		super(props);
		this.state = {
            pageNum: 1,
            pageSize: 25,
            totalCount: 0,
			packages: [],
			locations: [],
			count: 0,
		}
	}

	componentDidMount() {
		this.fetchPackages();
	}

    fetchPackages = () => {
        let filters = this.getFilters();
        console.log(this.state.query);
        callApi(`servicepackages/list`, 'post', {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            selected_filter: filters || {},
            qtext: this.state.query
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    items: res.data["servicepackages"],
                    totalCount: res.data.count
                }, () => {
                    console.log("fetched_packages", this.state.items);
                })
            }
            else {
                console.log("error")
            }
        })
	};
	
	// fetchPackages = () => {
	// 	callApi('servicepackages/list', 'post', {
	// 		pageNum: 1,
	// 		pageSizee: 100
	// 	}).then((res) => {
	// 		this.setState({
	// 			packages: res.data['servicepackages'],
	// 			count: res.data.count,
	// 		})
	// 	});

	// }

	onSelect = () => {

	}

	getFilters = () => {
		let input = {}; input.query = this.state.query;
		input.selected_filter = this.state.selectedTab;
		input.pageNum = this.state.pageNum;
		input.pageSize = this.state.pageSize;
		return input;
	};

	downloadData = () => {
		console.log('Download pressed');
		this.setState({
			isDownloadingData: true,
		});
		let filters = this.getFilters();
		downloadApi(`Cars-${moment().format('DD-MM-YYYY-HH:mm')}.csv`, `/admin/carmodels/dump`, 'POST', filters).then(
			(blob) => {
				this.setState({
					isDownloadingData: false,
				});
				console.log(JSON.stringify(blob));
			}
		);
	};

	fetchLocations = () => {
        callApi(`locations/list`, 'post', {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
        }).then((res) => {
            if (res.status === 'Success') {
                this.setState({
                    locations: res.data["locations"]
                })
            }
            else {
                console.log("error")
            }
        })
    };

	handleDetails = (id) => {
        this.setState({
            selectedItemId: id,
            isDetailsModalVisible: true,
        });
    };

    changePageNum = (num) => {
        this.setState(
            {
                pageNum: num,
            },
            () => {
                let filters = this.getFilters();
                this.fetchPackages(filters);
            }
        );
    };

    handleChange = (keyName, keyValue) => {
        let item = {};
        item[keyName] = keyValue;
        this.setState(item);
    };

    handleQuery = (val) => {
        // val = _.kebabCase(val);
        this.setState(
            {
                query: val,
            },
            () => {
                this.debounceSearch();
            }
        );
    };

    handleSearch = () => {
        this.fetchPackages();
    };

    closeModal = () => {
        this.setState({
            isDetailsModalVisible: false,
            selectedItemId: '',
            isAddVendorModalVisible: false,
        });
        this.fetchPackages();
    };

	render() {
        let locations = this.state.locations.map(item => ({ value: item._id, label: item.name }));
        let status = [
            { value: "", label: "All" },
            { value: "true", label: "Active" },
            { value: "false", label: "In Active" }
        ]
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="mb-0">Ad-On Services List</h4>
                            <div className="page-title-right">
                                {/* <Button className="mr-3" type="button" data-toggle="collapse" data-target="#multiCollapseExample1" aria-expanded="false" aria-controls="multiCollapseExample2"><i className="fa fa-filter"></i></Button> 
                                <Button className="mr-3" onClick={() => this.addVendorHandler()} disabled={this.state.isAddingVendor}>
                                    <i className="fa fa-plus"></i> {this.state.isAddingVendor ? "Adding" : "Add"}
                                </Button> */}
                                {/* <Button onClick={() => this.downloadData()} disabled={this.state.isDownloadingData}>
                                    {this.state.isDownloadingData ? "Downloading" : "Download Data"}
                                </Button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="collapse multi-collapse" id="multiCollapseExample1">
                    <div className="card card-body">
                        <h5>Apply Filters</h5>
                        <div className="container mt-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Select Location</label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        onChange={(value) => this.handleChange("filter_locations", value)}
                                        isMulti
                                        options={locations}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>Select Status</label>
                                    <Select
                                        onChange={(value) => this.handleChange("filter_status", value)}
                                        options={status}
                                        defaultValue={status[0]}
                                    />
                                </div>
                            </div>
                        </div>
                        <Button className="w-25 mx-auto mt-3" onClick={() => this.fetchApartments()}>
                            Apply
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <ListTable
                                    changePageNum={this.changePageNum}
                                    handleQuery={this.handleQuery}
                                    updateItem={this.updateItem}
                                    deleteItem={this.deleteItem}
                                    handleDetails={this.handleDetails}
                                    query={this.state.query}
                                    items={this.state.items}
                                    locations={this.state.locations}
                                    pageSize={this.state.pageSize}
                                    totalCount={this.state.totalCount}
                                    pageNum={this.state.pageNum}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isAddVendorModalVisible ? (
                    <AddVendorModal
                        isVisible={this.state.isAddVendorModalVisible}
                        close={this.closeModal}
                        addItem={this.addItem}
                        locations={this.state.locations}
                    />
                ) : null}
                {this.state.isDetailsModalVisible ? (
                    <PackageDetailsModal
                        isVisible={this.state.isDetailsModalVisible}
                        selectedItemId={this.state.selectedItemId}
                        close={this.closeModal}
                        fetchApartments={this.fetchPackages}
                    />
                ) : null}
            </div>
        );
    }
}

export default ServicePackageAll;