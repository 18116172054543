import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';

class ListTableRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditModeVisible: false,
            saved: {},
        };
    }

    handleChange = (keyName, keyValue) => {
        let item = this.state.saved;
        item[keyName] = keyValue;
        this.setState({
            saved: item,
        });
    };

    deleteHandler = (id) => {
        
        this.props.deleteItem(id);
    }

    render() {
        return (
            <tr role="row" className="odd">
                <td>{this.props.index + 1 + (this.props.page - 1) * this.props.size}</td>
                <td>{this.props.item.agency_name}</td>
                <td>{this.props.item.service_type}</td>
                <td>{this.props.item.name}</td>
                <td>{this.props.item.vehicle_type}</td>
                {/* <td>{this.props.item.addons.length}</td> */}
                <td>{this.props.item.car_types.length}</td>
                <td>{this.props.item.apartmentIds.length}</td>

                <td>{this.props.item.isActive ? "Active" : "In Active"}</td>
                <td>
                    <button
                        onClick={() => this.props.handleDetails(this.props.item._id)}
                        className="btn btn-primary btn-rounded ml-3"
                    >
                        Details
                        </button>
                    <button
                        disabled={this.props.item.isDeletingItem}
                        onClick={() => this.deleteHandler(this.props.item._id)}
                        className="btn btn-danger btn-rounded ml-3"
                    >
                        {this.props.item.isDeletingItem ? "Deleting" : "Delete"}
                    </button>
                </td>
            </tr>
        );
    }
}

export default ListTableRow;
