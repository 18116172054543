import React, { Component } from 'react';

class ListTableHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSidebarEnabled: true
        };
    }


    toggleSidebar = () => {
        this.setState({
            isSidebarEnabled: !this.state.isSidebarEnabled
        });
        if (this.state.isSidebarEnabled) {
            document.body.className = 'vertical-collpsed';
        } else {
            document.body.className = 'sidebar-enable';
        }
    };

    render() {
        return (
            <thead className="thead-light">
                <tr role="row">
                    <th style={{ width: '28px' }} className="sorting_disabled" rowSpan={1}
                        colSpan={1} aria-label="&nbsp;">
                        S.No
                        {/* <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input"
                                id="ordercheck" />
                            <label className="custom-control-label"
                                htmlFor="ordercheck">&nbsp;</label>
                        </div> */}
                    </th>
                    <th className="sorting_disabled"
                        tabIndex={0}
                        aria-controls="DataTables_Table_0"
                        rowSpan={1}
                        colSpan={1}
                        style={{ width: '57px' }}
                        aria-sort="ascending"
                        aria-label="Order ID: activate to sort column descending">
                        Order Id
                    </th>
                    <th className="sorting_disabled"
                        tabIndex={0}
                        aria-controls="DataTables_Table_0"
                        rowSpan={1}
                        colSpan={1}
                        style={{ width: '69px' }}>Date
                    </th>
                    <th className="sorting_disabled"
                        tabIndex={0}
                        aria-controls="DataTables_Table_0"
                        rowSpan={1}
                        colSpan={1}
                        style={{ width: '83px' }}>Billing Name
                    </th>
                    <th className="sorting_disabled"
                        tabIndex={0}
                        aria-controls="DataTables_Table_0"
                        rowSpan={1}
                        colSpan={1}
                        style={{ width: '83px' }}>Community Name
                    </th>
                    {/* <th className="sorting_disabled"
                        tabIndex={0}
                        aria-controls="DataTables_Table_0"
                        rowSpan={1}
                        colSpan={1}
                        style={{ width: '83px' }}>Honc Cust Type
                </th> */}
                    <th className="sorting_disabled"
                        tabIndex={0}
                        aria-controls="DataTables_Table_0"
                        rowSpan={1}
                        colSpan={1}
                        style={{ width: '83px' }}>License Number
                    </th>
                    <th className="sorting_disabled"
                        tabIndex={0}
                        aria-controls="DataTables_Table_0"
                        rowSpan={1}
                        colSpan={1}
                        style={{ width: '83px' }}>Phone
                    </th>
                    <th className="sorting_disabled"
                        tabIndex={0}
                        aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1}
                        style={{ width: '83px' }}>Booking Type
                    </th>
                    <th className="sorting_disabled"
                        tabIndex={0}
                        aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1}
                        style={{ width: '83px' }}>Amount
                    </th>
                    <th className="sorting_disabled"
                        tabIndex={0}
                        aria-controls="DataTables_Table_0"
                        rowSpan={1}
                        colSpan={1}
                        style={{ width: '83px' }}>
                        Due Date
                    </th>
                    <th className="sorting_disabled"
                        tabIndex={0}
                        aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1}
                        style={{ width: '32px' }}>Total
                    </th>
                    {this.props.selectedTab == "cancelled" ?
                        <th className="sorting_disabled"
                            tabIndex={0}
                            aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1}
                            style={{ width: '32px' }}>Cancelled Date
                        </th>
                        : null
                    }
                    {/* <th className="sorting_disabled"
                        tabIndex={0}
                        aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1}
                        style={{ width: '104px' }}>Payment Status
                </th> */}
                    {/* <th className="sorting_disabled"
                        tabIndex={0}
                        aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1}
                        style={{ width: '80px' }}>Invoice
                </th> */}
                    <th style={{ width: '56px' }}
                        className="sorting_disabled" rowSpan={1} colSpan={1}
                        aria-label="Action">Action
                    </th>
                </tr>
            </thead>
        );
    }
}

export default ListTableHeader;
