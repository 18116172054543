import React, { Component, useState } from 'react';
import ListTable from './components/ListTable';
import UserDetailsModal from './components/UserDetailsModal';
import AddServiceBookingModal from './components/AddServiceBookingModal'
import _ from 'lodash';
import callApi from '../../util/apiCaller';
import downloadApi from '../../util/apiDownloader';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select';
import DateRange from '../../components/DateRangePicker';

const MODEL_NAME = 'payments';

export default class Payments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: 'all',
			query: '',
			isFetchingItems: false,
			items: [],
			pageNum: 1,
			pageSize: 25,
			totalCount: 0,
			filters: [
				{
					label: 'All',
					value: 'all',
				}
			],
			selectedItemId: '',
			paymentId: '',
			userId: '',
			isModalVisible: false,
			communities: [],
			agencies: [],
			filter_communityIds: [],
			filter_agencyIds: [],
			filter_vehicle_type: [],
			filter_date_range: {
				start: '',
				end: '',
			},
		};
		this.debounceSearch = _.debounce(this.handleSearch, 500);
	}

	handleQuery = (val) => {
		this.setState(
			{
				query: val,
			},
			() => {
				this.debounceSearch();
			}
		);
	};

	componentDidMount() {
		let filters = this.getFilters();
		this.fetchRefunds(filters);
		this.fetchCommunities();
		this.fetchAgencies();
		//this.fetchRefunds();
		//this.fetchItemsList();
	}

	handleSearch = () => {
		let filters = this.getFilters();
		this.fetchRefunds(filters);
	};

	getFilters = () => {
		let input = {};
		input.query = this.state.query;
		input.selected_filter = this.state.selectedTab;
		input.pageNum = this.state.pageNum;
		input.pageSize = this.state.pageSize;
		if (this.state.filter_communityIds && this.state.filter_communityIds.length > 0) {
			input.filter_communityIds = this.state.filter_communityIds.map(item => (item.value));
		}
		if (this.state.filter_agencyIds && this.state.filter_agencyIds.length > 0) {
			input.filter_agencyIds = this.state.filter_agencyIds.map(item => (item.value));
		}
		if (this.state.filter_vehicle_type) {
			input.filter_vehicle_type = this.state.filter_vehicle_type.map(item => (item.value));
		}
		if (this.state.filter_date_range && this.state.filter_date_range.start !== '' && this.state.filter_date_range.end !== '') {
			input.filter_date_range = { start: this.state.filter_date_range.start, end: this.state.filter_date_range.end }
		}
		return input;
	};

	fetchRefunds = () => {
		let filters = this.getFilters();
		console.log(filters);
		callApi(`admin/payments/list`, 'post', filters).then((res) => {
			if (res.status === 'Success') {


				this.setState({
					payments: res.data.payments["payments"],

					totalPaymentCount: res.data.payments.paymentCount,
					isFetchingItems: false,
				}, () => {


					console.log("fetched_payments", this.state.payments);
				})
			}
			else {
				console.log("error")
			}
		})
	};

	fetchItems = (filters) => {
		this.setState({
			isFetchingItems: true,
		});
		callApi(`admin/customer/list`, 'post', filters).then((res) => {
			console.log(res.data)
			if (res.status === 'Success') {
				this.setState({
					items: res.data[`${MODEL_NAME}s`],
					totalCount: res.data.count,
					isFetchingItems: false,
				});
			}
		});
	};

	// fetchItemsList = (paymentId) => {

	// 	callApi(`admin/customer/list`, 'post', {
	// 		query: paymentId,
	// 	}).then((res) => {

	// 		if (res.status === 'Success') {
	// 			this.setState({
	// 				servicebooking: res.data,
	// 				totalCount: res.data.count
	// 			});
	// 			console.log(this.state.servicebooking)
	// 		}
	// 	});
	// };

	deleteItem = (id) => {
		let items = this.state.items.map((item) => {
			if (item._id === id) {
				item.isDeletingItem = true;
			}
			return item;
		});
		this.setState(
			{
				items: items,
			},
			() => {
				callApi(`${MODEL_NAME}s/${id}/remove`, 'post', {}).then((res) => {
					if (res.status === 'Success') {
						let items = this.state.items.filter((item) => item._id !== id);
						this.setState({
							items: items,
							totalCount: this.state.totalCount - 1,
						});
					}
				});
			}
		);
	};

	addBookingHandler = () => {
		this.setState({
			isAddCarModalVisible: true
		})
	}

	closeAddCarModalVisible = () => {
		this.setState({
			isAddCarModalVisible: false
		})
	}

	changePageSize = (size) => {
		this.setState(
			{
				pageSize: size,
				pageNum: 1,
			},
			() => {
				let filters = this.getFilters();
				this.fetchItems(filters);
			}
		);
	};

	changePageNum = (num) => {
		this.setState(
			{
				pageNum: num,
			},
			() => {
				let filters = this.getFilters();
				this.fetchItems(filters);
			}
		);
	};

	handleTabChange = (val) => {
		this.setState(
			{
				selectedTab: val,
			},
			() => {
				this.handleSearch();
			}
		);
	};

	handleDetails = (id, paymentId, userId) => {
		this.setState({
			selectedItemId: id,
			paymentId: paymentId,
			userId: userId,
			isModalVisible: true,
		});
	};

	closeModal = () => {
		this.setState({
			selectedItemId: '',
			isModalVisible: false,
		});
		let filters = this.getFilters();
		this.fetchRefunds();
	};

	downloadData = () => {
		this.setState({
			isDownloadingData: true,
		});
		let filters = this.getFilters();
		downloadApi(`bookings-${moment().format('DD-MM-YYYY-HH:mm')}.csv`, `admin/customer/dump`, 'POST', filters).then(
			(blob) => {
				this.setState({
					isDownloadingData: false,
				});
			}
		);
	};


	fetchCommunities = () => {
		callApi(`apartments/list`, 'post', {
			pageNum: 1,
			pageSize: 100,
		}).then((res) => {
			if (res.status === 'Success') {
				this.setState({
					communities: res.data["apartments"]
				})
			}
			else {
				console.log("error")
			}
		})
	};

	fetchAgencies = () => {
		callApi(`agencys/list`, 'post', {
			pageNum: 1,
			pageSize: 100,
		}).then((res) => {
			if (res.status === 'Success') {

				this.setState({
					agencies: res.data["agencys"]
				})
			}
			else {
				console.log("error")
			}
		})
	};


	handleChange = (keyName, keyValue) => {
		let item = {};
		item[keyName] = keyValue;
		this.setState(item);
	};

	handleDateRange = (item) => {
		this.setState({
			filter_date_range: {
				start: item[0],
				end: item[1]
			}
		})
	}

	render() {
		let communities = this.state.communities.map(item => ({ value: item._id, label: item.name }));
		let agencies = this.state.agencies.map(item => ({ value: item._id, label: item.name }));
		let vehicle_types = [{ value: "car", label: "Car" }, { value: "bike", label: "Bike" }]
		return (
			<div className="container-fluid">
				{/* start page title */}
				<div className="row">
					<div className="col-12">
						<div className="page-title-box d-flex align-items-center justify-content-between">
							<h4 className="mb-0">Honc Cust Daily Transactions</h4>
							<div className="page-title-right">
								<Button className="mr-3" type="button" data-toggle="collapse" data-target="#filter_modal" aria-expanded="false" aria-controls="multiCollapseExample2"><i className="fa fa-filter"></i></Button>
								{/* <Button onClick={() => this.addBookingHandler()}>
									<i className="fa fa-plus"></i>
									Add
								</Button> */}
								&nbsp;
								&nbsp;
								<Button disabled={this.state.isDownloadingData} onClick={() => this.downloadData()}>
									{this.state.isDownloadingData ? 'Downloading' : 'Download Data'}
								</Button>
							</div>
						</div>
					</div>
				</div>
				{/* Filter  */}
				<div className="collapse multi-collapse" id="filter_modal">
					<div className="card card-body">
						<h5>Apply Filters</h5>
						<div className="container mt-4">
							<div className="row">
								<div className="col-md-6">
									<label>Date Range</label>
									<DateRange
										dateRangeValue={this.handleDateRange}
									/>
								</div>
								<div className="col-md-6">
									<label>Select Community</label>
									<Select
										closeMenuOnSelect={false}
										onChange={(value) => this.handleChange("filter_communityIds", value)}
										isMulti
										options={communities}
									/>
								</div>
								<div className="col-md-6">
									<label>Select Vendor</label>
									<Select
										closeMenuOnSelect={false}
										onChange={(value) => this.handleChange("filter_agencyIds", value)}
										isMulti
										options={agencies}
									/>
								</div>
								<div className="col-md-6">
									<label>Select Vehicle Type</label>
									<Select
										onChange={(value) => this.handleChange("filter_vehicle_type", value)}
										isMulti
										options={vehicle_types}
									/>
								</div>
							</div>
						</div>
						<Button className="w-25 mx-auto mt-3" onClick={() => this.handleSearch()}>
							Apply
                        </Button>
					</div>
				</div>
				{/* end page title */}
				<div className="row">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-body  pt-0">
								{/* <ul className="nav nav-tabs nav-tabs-custom mb-4">
									{this.state.filters.map((item) => (
										<li key={item.value} className="nav-item">
											<a
												onClick={() => this.handleTabChange(item.value)}
												className={
													this.state.selectedTab === item.value
														? 'nav-link font-weight-bold p-3 active'
														: 'nav-link font-weight-bold p-3'
												}
												href="#"
											>
												{item.label}
											</a>
										</li>
									))}
								</ul> */}
                                &nbsp;
								{
									<ListTable
										changePageNum={this.changePageNum}
										handleQuery={this.handleQuery}
										handleDetails={this.handleDetails}
										deleteItem={this.deleteItem}
										changePageSize={this.changePageSize}
										query={this.state.query}
										items={this.state.payments}
										pageSize={this.state.pageSize}
										totalCount={this.state.totalPaymentCount}
										pageNum={this.state.pageNum}
										isFetchingItems={this.state.isFetchingItems}
									/>
								}
							</div>
						</div>
					</div>
				</div>
				{this.state.isModalVisible ? (
					<UserDetailsModal
						isVisible={this.state.isModalVisible}
						selectedItemId={this.state.selectedItemId}
						paymentId={this.state.paymentId}
						userId={this.state.userId}
						close={this.closeModal}
					/>
				) : null}
				{this.state.isAddCarModalVisible ? (
					<AddServiceBookingModal
						isVisible={this.state.isAddCarModalVisible}
						close={this.closeAddCarModalVisible}
					/>
				) : null}
				{/* end row */}
			</div>
		);
	}
}
