import React, { Component } from 'react'
import callApi from '../../util/apiCaller';


export default class AddStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      profile_type: 'Select Staff Type',
      staff: ['Owner', 'Supervisor', 'Manager', 'Cleaner'],
      agency: 'Select Agency Type',
    }
  }

  componentDidMount() {
    this.fetchAgencys();
  }

  fetchAgencys = () => {
    callApi(`agencys/list`, 'post', {
    }).then((res) => {
      if (res.status === 'Success') {
        this.setState({
          agencys: res.data.agencys
        })
      }
    })

  }

  staffValidation = (staff) => {
    let isValid = false;
    let message = '';
    return {
      isValid,
      message
    }
  }

  addUser = (user) => {
    callApi(`users/new`, 'post', {
      user: user
    }).then((res) => {
      if (res.status === "Success") {
        console.log("ADDED")
      } else {
        console.log("ERROR")
      }
    })
  }

  addNewStaffMember = (staff) => {
    let check = this.staffValidation(staff);
    if (check.isValid) {
      this.addUser(staff);

    } else {
      console.log("ERROR")
    }

  }

  onSelectDropdown = (keyName, keyValue) => {
    this.setState({
      [keyName]: keyValue
    }, () => console.log(this.state))
  }

  handleChange = ( keyName, keyValue ) => {
    let tempUser = this.state.user;
    tempUser[`${keyName}`] = keyValue;
    this.setState({
      user:tempUser
    }, () => console.log(this.state.user))
  }

  render() {
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title mb-4">Add Staff</h4>
            <div id="basic-pills-wizard" className="twitter-bs-wizard">
              <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                <li className="nav-item">
                  <a href="#seller-details" className="nav-link active" data-toggle="tab">
                    <span className="step-number">01</span>
                    <span className="step-title">User Details</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#company-document" className="nav-link" data-toggle="tab">
                    <span className="step-number">02</span>
                    <span className="step-title">Company Details</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#bank-detail" className="nav-link" data-toggle="tab">
                    <span className="step-number">03</span>
                    <span className="step-title">Assignment Details</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#confirm-detail" className="nav-link" data-toggle="tab">
                    <span className="step-number">04</span>
                    <span className="step-title">Confirm Detail</span>
                  </a>
                </li>
              </ul>
              <div className="tab-content twitter-bs-wizard-tab-content">
                <div className="tab-pane active" id="seller-details">
                  <form>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label htmlFor="basicpill-firstname-input">Name</label>
                          <input type="text" className="form-control" id="basicpill-firstname-input" onChange={(value) => this.handleChange('name', value)} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label htmlFor="basicpill-lastname-input">Phone</label>
                          <input type="text" className="form-control" id="basicpill-lastname-input" onChange={(value) => this.handleChange('phone', value)} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label htmlFor="basicpill-lastname-input">Agency</label>
                          <div className="btn-group">
                            <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              {this.state.agency} <i className="mdi mdi-chevron-down" />
                            </button>
                            <select className="dropdown-menu dropdown-menu-right" onChange={(agency) => this.onSelectDropdown('profile_type', agency)} style={{}}>
                              {this.state.agencys && this.state.agencys.map((agency) => (
                                <option className="dropdown-item" href="" >{agency.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label htmlFor="basicpill-phoneno-input">Aadhar Number</label>
                          <input type="text" className="form-control" id="basicpill-phoneno-input" onChange={(value) => this.handleChange('aadhar_num', value)} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label htmlFor="basicpill-email-input">Email</label>
                          <input type="email" className="form-control" id="basicpill-email-input" onChange={(value) => this.handleChange('email', value)} />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label htmlFor="basicpill-email-input">Staff Type</label>
                          <div className="btn-group">
                            <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              {this.state.profile_type} <i className="mdi mdi-chevron-down" />
                            </button>
                            <div className="dropdown-menu dropdown-menu-right" style={{}}>
                              {this.state.staff && this.state.staff.map((type) => (
                                <a className="dropdown-item" onSelect={(type) => this.onSelectDropdown('profile_type', type)}>{type}</a>
                              ))}
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="basicpill-address-input">Address</label>
                          <textarea id="basicpill-address-input" className="form-control" rows={2} defaultValue={""} onChange={(value) => this.handleChange('permanent_address', value)} />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="tab-pane" id="company-document">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="basicpill-pancard-input">PAN Card</label>
                            <input type="text" className="form-control" id="basicpill-pancard-input" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="basicpill-vatno-input">VAT/TIN No.</label>
                            <input type="text" className="form-control" id="basicpill-vatno-input" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="basicpill-cstno-input">CST No.</label>
                            <input type="text" className="form-control" id="basicpill-cstno-input" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="basicpill-servicetax-input">Service Tax No.</label>
                            <input type="text" className="form-control" id="basicpill-servicetax-input" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="basicpill-companyuin-input">Company UIN</label>
                            <input type="text" className="form-control" id="basicpill-companyuin-input" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="basicpill-declaration-input">Declaration</label>
                            <input type="text" className="form-control" id="basicpill-declaration-input" />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="tab-pane" id="bank-detail">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="basicpill-namecard-input">Name on Card</label>
                            <input type="text" className="form-control" id="basicpill-namecard-input" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Credit Card Type</label>
                            <select className="custom-select">
                              <option selected>Select Card Type</option>
                              <option value="AE">American Express</option>
                              <option value="VI">Visa</option>
                              <option value="MC">MasterCard</option>
                              <option value="DI">Discover</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="basicpill-cardno-input">Credit Card Number</label>
                            <input type="text" className="form-control" id="basicpill-cardno-input" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="basicpill-card-verification-input">Card Verification Number</label>
                            <input type="text" className="form-control" id="basicpill-card-verification-input" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="basicpill-expiration-input">Expiration Date</label>
                            <input type="text" className="form-control" id="basicpill-expiration-input" />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="tab-pane" id="confirm-detail">
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      <div className="text-center">
                        <div className="mb-4">
                          <i className="mdi mdi-check-circle-outline text-success display-4" />
                        </div>
                        <div>
                          <h5>Confirm Detail</h5>
                          <p className="text-muted">If several languages coalesce, the grammar of the resulting</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul className="pager wizard twitter-bs-wizard-pager-link">
                <li className="previous disabled"><a href="#">Previous</a></li>
                <li className="next"><a href="#">Next</a></li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    )
  }
}
