import React, { Component } from 'react';
import moment from 'moment';

class ListTableRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSidebarEnabled: true
        };
    }


    toggleSidebar = () => {
        this.setState({
            isSidebarEnabled: !this.state.isSidebarEnabled
        });
        if (this.state.isSidebarEnabled) {
            document.body.className = 'vertical-collpsed';
        } else {
            document.body.className = 'sidebar-enable';
        }
    };

    render() {
        return (
            <tr role="row" className="odd">
                <td>
                    {this.props.index + 1 + (this.props.page - 1) * this.props.size}
                </td>
                <td className="sorting_1"><a href="javascript: void(0);" className="text-dark font-weight-bold">{this.props.item.booking_num}</a> </td>
                <td>
                    {this.props.item && this.props.item.service_booking_date ? moment(this.props.item.service_booking_date).format('DD/MM/YY') : ''}
                </td>
                <td>{this.props.item && this.props.item.userInfo && this.props.item.userInfo.name ? this.props.item.userInfo.name : ''}</td>
                <td>{this.props.item && this.props.item.addressInfo && this.props.item.addressInfo.communityInfo && this.props.item.addressInfo.communityInfo.length > 0 && this.props.item.addressInfo.communityInfo[0].name ? this.props.item.addressInfo.communityInfo[0].name : ''}</td>
                {/* <td>{(this.props.item.isPrepaidAllowed) ? 'Pre-paid' : 'Post-paid'}</td> */}
                <td>{this.props.item && this.props.item.vehicle_type === "car" ? this.props.item.carInfo && this.props.item.carInfo.license_number ? this.props.item.carInfo.license_number : '' : this.props.item.bikeInfo && this.props.item.bikeInfo.license_number ? this.props.item.bikeInfo.license_number : ''}</td>
                <td>{this.props.item && this.props.item.userInfo && this.props.item.userInfo.phone ? this.props.item.userInfo.phone : ''}</td>
                <td>{this.props.item && this.props.item.serviceType}</td>
                <td>{this.props.item.booking_amount}</td>
                <td>{moment(this.props.item.next_payment_date).format('DD/MM/YY')}</td>
                <td>
                    {this.props.item.payment_charged?parseFloat(this.props.item.payment_charged).toFixed(2):"--NA--"}
                </td>
                {this.props.selectedTab == "cancelled" ?
                <td>
                {this.props.item && this.props.item.cancelledOn?moment(this.props.item.cancelledOn).format('LLL'):""}
            </td>
    :null}
                {/* <td>
                    <div className="badge badge-soft-warning font-size-12">{this.props.item.isPaymentSuccess ? 'paid' : 'unpaid'}</div>
                </td> */}
                <td>
                    <button
                        onClick={() => this.props.handleDetails(this.props.item.customerId,this.props.item._id)}
                        className="btn btn-light btn-rounded"> Details </button>

                </td>
                {/* <td style={{ display: 'none' }}>
                    <a href="javascript:void(0);" className="mr-3 text-primary" data-toggle="tooltip" data-placement="top" title data-original-title="Edit"><i className="mdi mdi-pencil font-size-18" /></a>
                    <a
                        disable={this.props.item.isDeletingItem}
                        onClick={() => this.props.deleteItem(this.props.item._id)}
                        className="text-danger">
                        <i className="mdi mdi-trash-can font-size-18" />
                    </a>
                </td> */}
            </tr>
        );
    }
}

export default ListTableRow;
