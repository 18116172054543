import React, { Component } from 'react';
import Table from '../../components/Table';
import _ from 'lodash';
import callApi from '../../util/apiCaller';

class ShowPlaces extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spot: '',
            pageNum: 1,
            pageSize:100,
            locations:[],
            apartments:[],
            blocks:[],
        }
    }

    componentDidMount() {
        if (this.props.match) {
            this.setState({
                spot: this.props.match.params.spot
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.match.params, this.props.match.params)) {
            this.setState({
                spot: this.props.match.params.spot
            }, () => {
                this.fetchData();
            });
        }
    }

    fetchData = ( ) => {
        callApi(`${this.state.spot}/list`, 'post', {
            pageNum:this.state.pageNum,
            pageSize:this.state.pageSize,
        }).then((res) => {
            if(res.status === 'Success'){
                this.setState({
                    [this.state.spot]:res.data[`${this.state.spot}`]
                })
            }
            else{
                console.log("error")
            }
        })
    }

    render() {
        return (
            <div>
                <Table
                    name={this.state.spot}
                    headings={this.state.spot === 'blocks' ? ['S. No.', 'Name', 'Apartment']: ['S. No.', 'Name']}
                    rows={this.state[`${this.state.spot}`]}
                />
            </div>
        );
    }
}

export default ShowPlaces;