import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Card, Col, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import moment from 'moment';
import Datepicker from '../../../components/DatePicker';
import callApi from '../../../util/apiCaller';

class ModalTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEditable: false,
            item: {},
            time_slots: [],
        };
    }

    componentDidMount() {
        if (this.props.item) {
            this.setState({
                item: this.props.item
            })
        }
        this.getTimeSlotsByAgencyId(this.props.item.agencyId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.item, this.props.item)) {
            this.setState({
                item: this.props.item
            });
        }
    }

    toggleEdit = () => {
        this.setState({
            isEditable: !this.state.isEditable
        })
    };

    handleChange = (keyName, keyValue) => {
        let item = this.state.item;
        item[keyName] = keyValue;
        this.setState({
            item: item
        });

        if (keyName === "time_slot_id") {
            this.state.time_slots.map((item) => {
                if (item._id === keyValue) {
                    this.handleChange("time_slot_name", item.time_slot_name)
                    this.handleChange("time_slot_slug", item.time_slot_slug)
                    this.handleChange("time_slot_start", item.time_slot_start)
                    this.handleChange("time_slot_end", item.time_slot_end)
                }
            })
        }
    };

    saveDetails = () => {
        let item = this.state.item;
        this.props.updateServiceRequest(this.props.item._id, item);
        setTimeout(() => {
            this.toggleEdit()
        }, 1000)
        console.log("item", item);
    };

    handleDate = (date) => {
        console.log("Date", date);
    }

    getTimeSlotsByAgencyId = (id) => {
        callApi(`admin/agency/${this.props.item.agencyId}/time_slots`, 'get')
            .then((res) => {
                console.log(res);
                this.setState({
                    time_slots: res.data.time_slots,
                });
            });
    }

    render() {
        if (this.state.isEditable) {
            return (
                <Card>
                    <Card.Header>
                        <Row>
                            <Col xs={6}> {this.props.heading}</Col>
                            <Col xs={6}>
                                <div className={'text-right'}>
                                    <Button
                                        disabled={this.state.isUpdatingUser}
                                        onClick={() => this.saveDetails()}>
                                        {this.state.item.isUpdating ? 'saving' : 'save'}
                                    </Button>
                                    <Button onClick={() => this.toggleEdit()}>close</Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>
                                    Type
                                </Col>
                                <Col xs={7}>
                                    {this.state.item.service_name}
                                </Col>
                            </Row>
                        </ListGroupItem>

                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>
                                    Date
                                </Col>
                                <Col xs={7}>
                                    <Datepicker
                                        handleDateChange={(e) => {
                                            this.handleChange("servicing_date", moment(new Date(e)).format("MM DD YYYY"));
                                        }}
                                        value={this.state.item.servicing_date}
                                    />
                                </Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>
                                    Time Slot
                                </Col>
                                <Col xs={7}>
                                    {this.props.item.time_slot_name}
                                    <FormControl
                                        as="select"
                                        onChange={(e) => {
                                            this.handleChange("time_slot_id", e.target.value);
                                        }}
                                    >
                                        <option selected={true} value={this.state.item.time_slot_name} disabled >{this.state.item.time_slot_name}</option>
                                        {this.state.time_slots.map((item) => (
                                            <option value={item._id}>{item.time_slot_name}</option>
                                        ))}
                                    </FormControl>
                                </Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>Vehicle</Col>
                                <Col xs={7}>
                                    vehicle Model
                                </Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>
                                    Cleaner
                                </Col>
                                <Col xs={7}>
                                    Cleaner name
                                </Col>
                            </Row>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Row>
                                <Col xs={5}>
                                    status
                                </Col>
                                <Col xs={7}>
                                    {this.state.item.status}
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            )
        } else {
            return (
                <Card>
                    <Card.Header>
                        <Row>
                            <Col xs={6}>
                                {this.props.heading}
                            </Col>
                            <Col xs={6}>
                                <div className="text-right">
                                    <Button onClick={() => this.toggleEdit()}>edit</Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>
                    {this.props.item.isUpdating ? "Updating...." :
                        <ListGroup className="list-group-flush">
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Type
                            </Col>
                                    <Col xs={7}>
                                        {this.props.item.service_name}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Date
                            </Col>
                                    <Col xs={7}>
                                        {moment(new Date(this.props.item.servicing_date)).format('DD MMM YYYY')}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Time Slot
                            </Col>
                                    <Col xs={7}>
                                        {this.props.item.time_slot_name}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Vehicle
                            </Col>
                                    <Col xs={7}>
                                        {this.props.item.model_name} - {this.props.item.license_number}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Cleaner
                            </Col>
                                    <Col xs={7}>
                                        {this.props.item.assigned_cleaner_name}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col xs={5}>
                                        Status
                            </Col>
                                    <Col xs={7}>
                                        {this.props.item.status}
                                    </Col>
                                </Row>
                            </ListGroupItem>

                        </ListGroup>
                    }
                </Card>
            )
        }
    }
}


export default ModalTable;