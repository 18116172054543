import React, { Component } from 'react';
import moment from 'moment';

class ListTableRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSidebarEnabled: true
        };
    }


    toggleSidebar = () => {
        this.setState({
            isSidebarEnabled: !this.state.isSidebarEnabled
        });
        if (this.state.isSidebarEnabled) {
            document.body.className = 'vertical-collpsed';
        } else {
            document.body.className = 'sidebar-enable';
        }
    };

    render() {

        return (
            <tr role="row" className="odd">
                <td>
                    {this.props.index + 1 + (this.props.page - 1) * this.props.size}
                </td>

                <td>
                    {moment(this.props.item.bill_date).format('DD/MM/YY')}
                </td>
                <td>{((typeof this.props.item.usersInfo != 'undefined') && (this.props.item.usersInfo)) ? this.props.item.usersInfo.name : ''}</td>


                <td>{((typeof this.props.item.servicebookings != 'undefined') && (this.props.item.servicebookings)) ? this.props.item.servicebookings.agency_name : ''}</td>
                <td >{this.props.item.amount}</td>
                <td>{(this.props.item.isPrepaidAllowed) ? 'Post-paid' : 'Pre-paid'}</td>
                <td>
                    <div className="badge badge-soft-warning font-size-12">{this.props.item.isPaid ? 'paid' : 'unpaid'}</div>
                </td>
                <td>
                    <button
                        onClick={() => this.props.handleDetails(this.props.item._id, (((typeof this.props.item.servicebookings != 'undefined') && (this.props.item.servicebookings)) ? this.props.item.servicebookings._id : ''), this.props.item.usersInfo._id)}
                        className="btn btn-light btn-rounded"> Details </button>

                </td>
                <td style={{ display: 'none' }}>
                    <a href="javascript:void(0);" className="mr-3 text-primary" data-toggle="tooltip" data-placement="top" title data-original-title="Edit"><i className="mdi mdi-pencil font-size-18" /></a>
                    <a
                        disable={this.props.item.isDeletingItem}
                        onClick={() => this.props.deleteItem(this.props.item._id)}
                        className="text-danger">
                        <i className="mdi mdi-trash-can font-size-18" />
                    </a>
                </td>
            </tr>
        );
    }
}

export default ListTableRow;
